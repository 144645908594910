import { Layout } from 'react-grid-layout';
import { OmitDistributive } from 'shared/types';
import { MultipleChoiceOrganisationPlanWidgetType, SingleChoiceOrganisationPlanWidgetType } from './organisationPlanChoiceWidgetType';
import { OrganisationPlanDividerWidgetType } from './organisationPlanDividerWidgetType';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from './organisationPlanImageWidgetTypes';
import { OrganisationPlanInfoFromQuestionnaireWidgetType } from './organisationPlanInfoFromQuestionnaireWidgetType';
import { OrganisationPlanInputNumberWithReplacementType } from './organisationPlanInputNumberWithReplacementType';
import { OrganisationPlanInputTextWithReplacementType } from './organisationPlanInputTextWithReplacementType';
import { OrganisationPlanNotesWidgetType } from './organisationPlanNotesWidgetType';
import { OrganisationPlanTableWithReplacementWidgetType } from './organisationPlanTableWithReplacementWidgetType';
import { OrganisationPlanTextWidgetType } from './organisationPlanTextWidgetType';
import { OrganisationPlanTimePickerWithReplacementType } from './organisationPlanTimePickerWithReplacementType';
import { OrganisationPlanUploadAreaWithReplacementType } from './organisationPlanUploadAreaWithReplacementType';

export interface BaseWidget {
  widgetId: string;
  linkedWidgetId?: string;
  sectionId: string;
  isMainLabelHidden?: boolean;
  isHighlighted?: boolean;
  isBottomDivider?: boolean;
  isHidden?: boolean;
}

export enum ORGANISATION_PLAN_WIDGET_TYPES {
  inputOrganisationPlanText = 'inputOrganisationPlanText',
  notes = 'notes',
  infoFromQuestionnaire = 'infoFromQuestionnaire',
  inputText = 'inputText',
  inputInteger = 'inputInteger',
  time = 'time',
  singleChoice = 'singleChoice',
  multipleChoice = 'multipleChoice',
  singleImageChoice = 'singleImageChoice',
  multipleImageChoice = 'multipleImageChoice',
  table = 'table',
  uploadArea = 'uploadArea',
  divider = 'divider'
}
/* Widget types of Questionnaire/Event which available for Organisation plan */
export enum AVAILABLE_WIDGET_TYPES_OF_QUESTIONNAIRE {
  inputOrganisationPlanText = 'inputOrganisationPlanText',
  notes = 'notes',
  infoFromQuestionnaire = 'infoFromQuestionnaire',
  inputText = 'inputText',
  inputInteger = 'inputInteger',
  time = 'time',
  singleChoice = 'singleChoice',
  multipleChoice = 'multipleChoice',
  singleImageChoice = 'singleImageChoice',
  multipleImageChoice = 'multipleImageChoice',
  table = 'table',
  uploadArea = 'uploadArea'
}

export enum ListingOption {
  none = 'none',
  bullet = 'bullet',
  checkbox = 'checkbox',
  numeric = 'numeric'
}

export enum DisplayMode {
  inline = 'inline',
  column = 'column',
  row = 'row',
  auto = 'auto'
}

export enum TypographyType {
  headline = 'headline',
  body = 'body',
  questionLabel = 'question-label',
  answerLabel = 'answer-label',
  numericValues = 'numeric-values'
}

type WidgetsTypeWithoutId = OmitDistributive<OrganisationPlanWidgetsType, 'widgetId' | 'sectionId'>;

type LayoutForNewWidget = Omit<Layout, 'i' | 'x' | 'y'> & Partial<Pick<Layout, 'x' | 'y'>>;

export interface ISideBarWidget {
  imgUrl: string;
  customImageSize?: boolean;
  label: string;
  layout: LayoutForNewWidget;
  widget: WidgetsTypeWithoutId;
  formatId?: string;
}

export type OrganisationPlanWidgetsType =
  | OrganisationPlanTextWidgetType
  | OrganisationPlanNotesWidgetType
  | OrganisationPlanInfoFromQuestionnaireWidgetType
  | OrganisationPlanInputTextWithReplacementType
  | OrganisationPlanInputNumberWithReplacementType
  | OrganisationPlanTimePickerWithReplacementType
  | SingleChoiceOrganisationPlanWidgetType
  | MultipleChoiceOrganisationPlanWidgetType
  | SingleImageChoiceWithReplacementWidgetType
  | MultipleImageChoiceWithReplacementWidgetType
  | OrganisationPlanTableWithReplacementWidgetType
  | OrganisationPlanUploadAreaWithReplacementType
  | OrganisationPlanDividerWidgetType;
