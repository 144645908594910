import PreviewAnswerWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewAnswerWrapper';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { ListingOption } from 'modules/organisation-plan-templates/types';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import { Layout } from 'react-grid-layout';

interface IPreviewUploadWidgetProps {
  element: OrganisationPlanUploadAreaWithReplacementType;
  layoutItem: Layout;
}

const PreviewUploadWidget = ({ element, layoutItem }: IPreviewUploadWidgetProps) => {
  const {
    label,
    replacementLabel,
    data,
    isMainLabelHidden,
    isInlinePreview,
    widgetId,
    sectionId,
    isBottomDivider,
    isHighlighted,
    displayMode
  } = element;

  const renderContent = () => {
    if (data.uploadedFile?.length) {
      return data.uploadedFile.map((file, index) => {
        const fileUrl = `${import.meta.env.VITE_REACT_APP_STORAGE_URL}/${file?.url}`;
        const fileName = file?.fileName;
        return (
          <PreviewAnswerWrapper
            key={index}
            index={index}
            imageSrc={fileUrl}
            listingOption={ListingOption.none}
            displayMode={displayMode}
            answerLabel={''}
            linkLabel={fileName}
            isLabelHidden={isMainLabelHidden}
            isGrid={isInlinePreview}
            isLink
          />
        );
      });
    }
  };

  return (
    <PreviewWidgetWrapper
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={renderInputLabel(replacementLabel, label)}
      isQuestionLabelHidden={!!isMainLabelHidden}
      isGrid={true}
      gridGap="5px 10px"
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
    >
      {renderContent()}
    </PreviewWidgetWrapper>
  );
};

export default PreviewUploadWidget;
