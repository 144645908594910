import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  width: '100%',
  borderTop: theme.palette.mode === 'dark' ? `1px solid #ffffff20` : `1px solid #00000020`
}));

export const BoxEmptyInfoContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  h4: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#8C8C8C'
  }
}));

export const WidgetWrapperBox = styled(Box)(({ theme }) => ({
  border: theme.palette.mode === 'dark' ? `1px solid #ffffff20` : `1px solid #00000020`,
  borderRadius: '6px',
  backgroundColor: theme.palette.background.paper,
  maxWidth: '100%',
  '&:hover, &.widget-focused': {
    boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    borderColor: 'transparent'
  },
  '&:focus-within': {
    boxShadow: `0 0 0 2px ${theme.palette.secondary.light}`,
    borderColor: 'transparent'
  }
}));

export const AddButtonIcon = styled(IconButton)(() => ({
  span: {
    color: '#262626',

    svg: {
      width: '100%',
      height: '100%'
    }
  }
}));
