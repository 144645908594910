import { CheckmarkCircleRegular, Dismiss16Regular, ErrorCircle16Regular, Info16Regular, Warning16Regular } from '@fluentui/react-icons';
import { Alert, alpha, Button, Fade, Grow, IconButton, Slide, SlideProps, Typography, useTheme } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyedObject, RootStateProps } from 'shared/types/root';
import { closeSnackbar } from 'store/snackbar';

function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

const Snackbar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const snackbar = useSelector((state: RootStateProps) => state.snackbar);
  const { actionButton, anchorOrigin, alert, close, message, open, transition, variant, secondaryText } = snackbar;

  const handleClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  const backgroundColor = (() => {
    switch (alert.color) {
      case 'success':
        return alpha(theme.palette.success.main, 0.7);
      case 'error':
        return alpha(theme.palette.error.main, 0.7);
      case 'warning':
        return alpha(theme.palette.warning.main, 0.7);
      case 'info':
        return alpha(theme.palette.info.main, 0.7);
      default:
        return 'grey';
    }
  })();

  return (
    <>
      {variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message}
          TransitionComponent={animation[transition]}
          action={
            close !== false && (
              <IconButton aria-label="close" onClick={handleClose}>
                <Dismiss16Regular fontSize="inherit" color="white" />
              </IconButton>
            )
          }
        />
      )}

      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation[transition]}
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}
        >
          <Alert
            onClose={handleClose}
            variant={alert.variant}
            color={alert.color}
            severity={alert.color}
            iconMapping={{
              success: <CheckmarkCircleRegular fontSize="inherit" />,
              error: <ErrorCircle16Regular fontSize="inherit" />,
              warning: <Warning16Regular fontSize="inherit" />,
              info: <Info16Regular fontSize="inherit" />
            }}
            action={
              <>
                {actionButton !== false && (
                  <Button color={alert.color} size="small" onClick={handleClose}>
                    UNDO
                  </Button>
                )}
                {close !== false && (
                  <IconButton aria-label="close" onClick={handleClose}>
                    <Dismiss16Regular fontSize="inherit" color="white" />
                  </IconButton>
                )}
              </>
            }
            sx={{
              color: 'white',
              borderRadius: '10px',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px inset',
              backgroundColor: backgroundColor,
              backdropFilter: 'blur(10px)'
            }}
          >
            {message}
            {secondaryText && <Typography variant="h6">{secondaryText}</Typography>}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
