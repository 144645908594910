import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionWrapper = styled(Card)(({ theme }) => ({
  boxShadow: theme.palette.mode === 'dark' ? `0 0 0  1px  #ffffff20` : `0 0 0  1px  #00000020`,
  borderRadius: '10px',
  backgroundColor: theme.palette.primary.lighter,
  marginBottom: '10px'
}));

export const PreviewSectionWrapper = styled(Box)(({ theme }) => ({
  padding: '0px',
  backgroundColor: theme.palette.background.paper
}));

interface ISectionHeaderProps {
  isExpanded: boolean;
}

export const SectionHeader = styled(Box)<ISectionHeaderProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '4px 4px 4px 12px',
  minHeight: '30px',
  backgroundColor: theme.palette.background.paper,

  '& .MuiBox-root': {
    '& .MuiFormControl-root': {
      width: '100%'
    }
  }
}));

export const HeaderActionsContainer = styled('section')(() => ({
  display: 'flex',
  alignItems: 'right'
  // minWidth: '200px'
}));
