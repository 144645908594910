import { BUILDER_WIDGET_TYPES } from 'modules/builder/types';
import { ORGANISATION_PLAN_WIDGET_TYPES, OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { Layout } from 'react-grid-layout';

/**
 * Util function that filter org plan layout items by widget `isHidden` field
 * @param layout layout array
 * @param allWidgets org plan widgets array
 * @returns layout array
 */
export const filterLayoutArrayByIsHiddenField = (layout: Layout[], allWidgets: OrganisationPlanWidgetsType[]) => {
  return layout.filter((layout) => {
    const widget = allWidgets.find((widget) => widget.widgetId === layout.i);
    if (widget) {
      return widget.isHidden === undefined || !widget.isHidden;
    }
    return layout;
  });
};

interface IReturnFilterLayoutAndWidgetWithActiveLinkedWidgetId {
  layoutArray: Layout[];
  widgets: OrganisationPlanWidgetsType[];
}
/**
 * Util function that filter org plan layout and widgets by `linkedWidgetId` field.
 * If widget doesn't have it - widget will be removed from layout and widget list
 * @param layout layout array
 * @param allWidgets org plan widgets array
 * @returns object with layout and widgets
 */
export const filterLayoutAndWidgetWithActiveLinkedWidgetId = (
  layout: Layout[],
  allWidgets: OrganisationPlanWidgetsType[]
): IReturnFilterLayoutAndWidgetWithActiveLinkedWidgetId => {
  const filteredWidgetsWithActiveLinkedWidgetId = allWidgets.filter((w) => {
    const isWidgetOnlyForOrgPlan =
      w.type === ORGANISATION_PLAN_WIDGET_TYPES.divider ||
      w.type === ORGANISATION_PLAN_WIDGET_TYPES.notes ||
      w.type === ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText;
    if (isWidgetOnlyForOrgPlan) return true;
    return !!w.linkedWidgetId;
  });
  const filteredLayoutWithActiveWidgetId = layout.filter((l) => filteredWidgetsWithActiveLinkedWidgetId.some((w) => l.i === w.widgetId));
  return {
    layoutArray: filteredLayoutWithActiveWidgetId,
    widgets: filteredWidgetsWithActiveLinkedWidgetId
  };
};

/**
 * Method that checks whether to display the widget.
 * If widget doesn't have answers or selected options - widget will be removed from layout and widget list
 * If it is base widget (notes, divider,inputOrganisationPlanText), method returns true (it is displayed)
 * @param w - widget
 * @return boolean
 */
export const checkShowWidgetForSection = (w: OrganisationPlanWidgetsType): boolean => {
  const widgetsWithInputValue: Array<ORGANISATION_PLAN_WIDGET_TYPES> = [
    ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText,
    ORGANISATION_PLAN_WIDGET_TYPES.inputInteger,
    ORGANISATION_PLAN_WIDGET_TYPES.inputText
  ];

  const widgetsWithSingleOptions: Array<ORGANISATION_PLAN_WIDGET_TYPES> = [
    ORGANISATION_PLAN_WIDGET_TYPES.singleChoice,
    ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice
  ];

  const widgetsWithMultipleOptions: Array<ORGANISATION_PLAN_WIDGET_TYPES> = [
    ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice,
    ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice
  ];

  const conditionWithValue = widgetsWithInputValue.includes(w.type as ORGANISATION_PLAN_WIDGET_TYPES);
  const conditionSingleChoice = widgetsWithSingleOptions.includes(w.type as ORGANISATION_PLAN_WIDGET_TYPES);
  const conditionMultipleChoice = widgetsWithMultipleOptions.includes(w.type as ORGANISATION_PLAN_WIDGET_TYPES);

  if (conditionWithValue && 'value' in w) {
    return !!w.value;
  } else if (conditionSingleChoice && 'selectedOption' in w) {
    const replaceOption = w?.replacementOptionsLabels?.find((optionReplace) => optionReplace.id === w.selectedOption);
    return !!replaceOption ? !replaceOption?.isLabelHidden : !!w.selectedOption;
  } else if (conditionMultipleChoice && 'selectedOptions' in w) {
    const filterOptionIsLabelHidden: string[] = [];
    w.selectedOptions.map((optionID) => {
      const replaceOption = w?.replacementOptionsLabels?.find((optionReplace) => optionReplace.id === optionID);
      if (replaceOption?.isLabelHidden) {
        filterOptionIsLabelHidden.push(optionID);
      }
      return optionID;
    });
    return !(filterOptionIsLabelHidden?.length === w.selectedOptions?.length);
  } else if (w.type === ORGANISATION_PLAN_WIDGET_TYPES.uploadArea) {
    return !!w.data?.uploadedFile?.length;
  } else if (w.type === ORGANISATION_PLAN_WIDGET_TYPES.time) {
    return !!w?.date;
  } else if (w.type === BUILDER_WIDGET_TYPES.table) {
    return !!w?.dataContent?.length;
  } else if (w.type === ORGANISATION_PLAN_WIDGET_TYPES.inputInteger) {
    return !!w.value;
  }
  return true;
};

/**
 * Util function that filter org plan layout and widgets by customer's answers.
 * If widget doesn't have answers or selected options - widget will be removed from layout and widget list
 * @param layouts layout array
 * @param allWidgets org plan widgets array
 * @returns object with layout and widgets
 */
export const filteredTemplateByAnswers = (
  layouts: Layout[],
  allWidgets: OrganisationPlanWidgetsType[]
): IReturnFilterLayoutAndWidgetWithActiveLinkedWidgetId => {
  const filterWidgets: OrganisationPlanWidgetsType[] = allWidgets
    ? allWidgets.filter((widget) => !widget.isHidden && checkShowWidgetForSection(widget))
    : [];
  const filterLayouts = layouts.filter((layout) => filterWidgets.some((w) => layout.i === w.widgetId));

  return {
    widgets: filterWidgets,
    layoutArray: filterLayouts
  };
};

/**
 * Util function that filter org plan layout and widgets by customer's answers.
 * If widget doesn't have answers or selected options - widget will be removed from layout and widget list
 * @param sections sections of template
 * @param allWidgets org plan widgets array
 * @returns object with layout and widgets
 */
export const filteredTemplateSectionsIdByAnswers = (allWidgets: OrganisationPlanWidgetsType[], sections: IOrganisationPlanSection[]) => {
  const filterWidgetsWithAnswer: OrganisationPlanWidgetsType[] = allWidgets
    ? allWidgets.filter((widget) => !widget.isHidden && checkShowWidgetForSection(widget))
    : [];
  const sectionIdArray = filterWidgetsWithAnswer?.map((w) => w.sectionId);

  return [...new Set(sectionIdArray)]
    .map((sectionId) => sections.find((section) => section?.sectionId === sectionId))
    .filter((section) => !!section) as IOrganisationPlanSection[];
};

import { useMemo } from 'react';

/**
 * Hook to calculate the render order for CSS Grid.
 * @param {Array} layout - Array of react-grid-layout item definitions.
 * @returns {Array} - Array of objects with id and order for rendering widgets.
 */
export const useCssGridLayout = (layout: Layout[]) => {
  return useMemo(() => {
    if (!Array.isArray(layout)) {
      throw new Error('Layout must be an array.');
    }

    // Sort layout items by their row (y), height (h), and column (x) positions
    const sortedLayout = [...layout].sort((a, b) => {
      if (a.y === b.y) {
        if (a.h === b.h) {
          return a.x - b.x;
        }
        return b.h - a.h; // Larger height comes first within the same row
      }
      return a.y - b.y;
    });

    // Map sorted layout to order
    return sortedLayout.map((item, index) => ({
      ...sortedLayout,
      i: item.i, // Keep the widget id for rendering or debugging
      order: index + 1, // Render order (1-indexed for CSS Grid compatibility if needed)
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h
    }));
  }, [layout]);
};
