import { Box, Divider, useTheme } from '@mui/material';
import { extractContentFromHtml } from 'modules/organisation-plan-preview/utils/extractContentFromHtml';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { PropsWithChildren } from 'react';
import { Layout } from 'react-grid-layout';
import { PreviewLabel } from './PreviewLabel';

interface IPreviewWidgetWrapperProps {
  widgetId: string;
  sectionId: string;
  layoutItem: Layout;
  dependencies?: Array<unknown>;
  questionLabel?: string;
  isQuestionLabelHidden?: boolean;
  isGrid?: boolean;
  isBottomDivider?: boolean;
  isHighlighted?: boolean;
  gridGap?: string;
}

const PreviewWidgetWrapper = ({
  widgetId,
  sectionId,
  layoutItem,
  children,
  dependencies,
  isQuestionLabelHidden,
  questionLabel,
  isGrid = false,
  isBottomDivider = false,
  isHighlighted = false,
  gridGap = '25px 25px'
}: PropsWithChildren<IPreviewWidgetWrapperProps>) => {
  const theme = useTheme();
  const ref = useUpdateOrganisationPlanLayoutPartially({
    widgetId,
    sectionId,
    layoutItem,
    dependencies: dependencies || []
  });
  const labelText = questionLabel && extractContentFromHtml(questionLabel);
  const styles = isHighlighted
    ? { boxShadow: `0 0 0 5px${theme.palette.primary.lighter}`, borderRadius: '2px', backgroundColor: `${theme.palette.primary.lighter}` }
    : { border: 'none' };
  if (!children) {
    return null;
  }
  return (
    <Box ref={ref} sx={{ '@media print': { breakInside: isGrid ? 'auto' : 'avoid' }, ...styles }}>
      <PreviewLabel paddingBottom="5px" label={labelText} type={TypographyType.questionLabel} isHidden={isQuestionLabelHidden} />

      <Box sx={{ display: 'flex', flexDirection: isGrid ? 'row' : 'column', flexWrap: 'wrap', gap: isGrid ? gridGap : '5px' }}>
        {children}
      </Box>
      {isBottomDivider && <Divider sx={{ borderWidth: '2px', marginTop: '10px' }} />}
    </Box>
  );
};

export default PreviewWidgetWrapper;
