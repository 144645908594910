import axios from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { EEventQueryKeys } from 'shared/types/event';
import { API_OFFERS_ENDPOINTS, EOffersQueryKeys, IOfferBD, IOfferForSave } from 'shared/types/offer';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { API_REQUEST_ENDPOINTS, ERequestsQueryKeys } from 'shared/types/requests';
import { IPagination } from 'shared/types/table';

type MutationContext =
  | {
      previousOffer: IPaginatedResult<IOfferForSave> | undefined;
      newOffer: IOfferForSave;
    }
  | undefined;

const updateOffer = (offerInfo: IOfferForSave): Promise<IOfferBD> => {
  const res = axios.put(`${API_OFFERS_ENDPOINTS.GET_OFFER(offerInfo.id)}`, offerInfo).then((res) => res.data);
  let reqRes;
  if (!!offerInfo.request?.requestedEventDate) {
    reqRes = axios.put(`${API_REQUEST_ENDPOINTS.GET_REQUEST(offerInfo.request.id)}`, offerInfo.request).then((res) => res.data);
  }
  return res;
};

export function useEditOffer<TError>({
  options = {},
  onSuccess,
  id,
  paginationRequests,
  paginationEvents
}: {
  options?: Omit<UseMutationOptions<IOfferForSave, TError, IOfferForSave, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: IOfferBD, variables: IOfferForSave, context: MutationContext) => void | Promise<unknown>) | undefined;
  id?: number;
  paginationRequests: IPagination;
  paginationEvents?: IPagination;
}) {
  const queryClient = useQueryClient();
  return useMutation([{ id: `${id}` }], updateOffer, {
    ...options,
    // Update cache after success offer
    onSuccess: (data: IOfferBD, variables, context) => {
      queryClient.invalidateQueries([ERequestsQueryKeys.GetRequests, paginationRequests]);
      paginationEvents && queryClient.invalidateQueries([EEventQueryKeys.AllEvents, paginationEvents]);
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },
    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries([EOffersQueryKeys.GetOffers, paginationRequests]);
        paginationEvents && queryClient.invalidateQueries([EEventQueryKeys.AllEvents, paginationEvents]);
      }
    }
  });
}
