import { Button, Stack, Toolbar } from '@mui/material';
import SaveButtonContainer from 'modules/organisation-plan-templates/containers/SaveButtonContainer';
import { FormattedMessage } from 'react-intl';

interface IOrganisationPlanTemplateHeader {
  handleClose: () => void;
}

const OrganisationPlanTemplateHeader = ({ handleClose }: IOrganisationPlanTemplateHeader) => {
  return (
    <Toolbar disableGutters variant="dense" sx={{ position: 'fixed', top: '0', left: 0, width: '100vw', zIndex: '3' }}>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
        borderBottom="1px solid #00000020"
        bgcolor={'background.paper'}
      >
        <Button size="small" variant="outlined" onClick={handleClose}>
          <FormattedMessage id="builder.close" />
        </Button>

        <SaveButtonContainer />
      </Stack>
    </Toolbar>
  );
};

export default OrganisationPlanTemplateHeader;
