import { TextField } from '@mui/material';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { ChangeEventHandler } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import TemplateWidgetWrapper from '../Shared/TemplateWidgetWrapper';

interface INotesWidgetProps {
  element: OrganisationPlanNotesWidgetType;
  layoutItem: Layout;
}

const OrganisationPlanNotesWidget = ({ element, layoutItem }: INotesWidgetProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { type, sectionId, widgetId, isBottomDivider, isHighlighted } = element;
  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const newValue = e.target.value;
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: element.widgetId,
        widget: { type: type as OrganisationPlanNotesWidgetType['type'], noteText: newValue }
      })
    );
  };

  return (
    <TemplateWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      hasPadding
    >
      <TextField
        fullWidth={true}
        multiline={true}
        placeholder={intl.formatMessage({ id: 'organisation.notes' })}
        id="outlined-name"
        value={element.noteText}
        onChange={onChange}
        disabled={true}
      />
    </TemplateWidgetWrapper>
  );
};

export default OrganisationPlanNotesWidget;
