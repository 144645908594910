export const GRID_LAYOUT_SIZE_ORGANISATION_PLAN = {
  rowHeight: 2,
  rowGap: 10,
  widthGrid: 1050,
  col: 2,
  rowPreviewGap: 5,
  rowPreviewHeight: 1
};

export const ORGANISATION_PLAN_TEMPLATE_ROUTES = {
  ORGANISATION_PLAN_TEMPLATE: '/organisation-plan-templates',
  ORGANISATION_PLAN_TEMPLATE_BUILDER_LAYOUT_BY_ID: (organisationPlanTemplateId: number, questionnaireTemplateId: number) =>
    `/organisation-plan-templates/${organisationPlanTemplateId}/${questionnaireTemplateId}`
};
