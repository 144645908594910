import { useQuery } from 'react-query';
import axios from 'axios';
import { API_REQUEST_ENDPOINTS, ERequestsQueryKeys, IRequestDB } from 'shared/types/requests';

interface IuseGetRequestByIdProps {
  id?: string;
}

const fetchRequestById = (id: string): Promise<IRequestDB> => {
  return axios.get(`${API_REQUEST_ENDPOINTS.GET_REQUEST(id)}`).then((res) => res.data);
};

export function useGetRequestById({ id }: IuseGetRequestByIdProps) {
  return useQuery<IRequestDB>([ERequestsQueryKeys.GetSingleRequest, { id }], () => fetchRequestById(id ?? ''), {
    enabled: !!id,
    refetchOnMount: true
  });
}
