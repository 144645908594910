import { Box, Button, Divider, Stack } from '@mui/material';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import { BUILDER_WIDGET_TYPES, BuilderPageItemType, WidgetsType, WidgetsTypeOnlySupportedByOrganisationPlan } from 'modules/builder/types';
import { OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import {
  generateInputNumberWidget,
  generateInputTextWidget,
  generateMultipleChoiceForGuestAndRoomsWidget,
  generateMultipleChoiceWidget,
  generateMultipleImageChoiceWidget,
  generateSingleChoiceWidget,
  generateSingleImageChoiceWidget,
  generateTableWidget,
  generateTimePickerWidget,
  generateUploadAreaWidget
} from 'modules/organisation-plan-templates/utils/organisationPlanWidgetsGenerator';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { useDispatch, useSelector } from 'store';
import {
  editWidgetSettings,
  selectIsActiveInfoFromQuestionnaireSettings,
  selectIsActiveInfoFromQuestionnaireSettingsSectionId
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  setActiveInfoFromQuestionnaireSettings,
  setActiveInfoFromQuestionnaireSettingsSectionId,
  setEditWidgetSettings,
  setManyWidgets,
  updateOrganisationPlanWidget
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { IImportAllWidgetPayload } from 'store/organisation-plan-template/organization-plan-template.types';
import { openSnackbar } from 'store/snackbar';
import OrganisationPlanTabPanel from '../OrganisationPlanTabPanel';
import { WidgetBarTypography } from '../WidgetsBar/WidgetBar.styles';
import OrganisationPlanDynamicFields from './OrganisationPlanDynamicFields';

export interface IOrganisationPlanDynamicFields {
  id: string;
  label: string;
  isDisabled: boolean;
}

export interface IChosenSubpage {
  pageId: string;
  pageName: string;
  subpageId: string;
}

const OrganisationPlanTemplateQuestionnaireInfoSettings = () => {
  const intl = useIntl();
  const { questionnaireTemplateId } = useParams();

  const [chosenPage, setChosenPage] = useState('');
  const [chosenSubpage, setChosenSubpage] = useState<IChosenSubpage | undefined>(undefined);
  const [selectedWidgetId, setSelectedWidgetId] = useState('');
  const [chosenWidget, setChosenWidget] = useState('');
  const [chosenWidgetLayout, setChosenWidgetLayout] = useState<BuilderPageItemType | null>(null);
  const [filteredBuilderWidgetsByPageName, setFilteredBuilderWidgetsByPageName] = useState<WidgetsTypeOnlySupportedByOrganisationPlan[]>(
    []
  );
  const [tabValueIndex] = useState(0);

  const dispatch = useDispatch();
  const activeInfo = useSelector(selectIsActiveInfoFromQuestionnaireSettings);
  const activeSectionId = useSelector(selectIsActiveInfoFromQuestionnaireSettingsSectionId);
  const editedWidgetInfo = useSelector(editWidgetSettings);

  const { data: builderPages = [] } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });

  useEffect(() => {
    if (editedWidgetInfo) {
      setChosenPage(editedWidgetInfo.pageOfChosenWidget);
      // Check if the chosenWidget is one of the types that has a label property
      if ('label' in editedWidgetInfo.chosenWidget) {
        setChosenWidget(editedWidgetInfo.chosenWidget.label);
      } else {
        setChosenWidget('');
      }
    } else {
      setChosenPage('');
      setChosenWidget('');
    }
  }, [editedWidgetInfo]);

  useEffect(() => {
    if (chosenPage) {
      if (chosenSubpage) {
        const chosenPageWithSubpages = builderPages.filter(
          (page) => `${page.name}with_page_id${page.id}` === `${chosenSubpage.pageName}with_page_id${chosenSubpage.pageId}`
        );
        if (chosenPageWithSubpages.length) {
          const filteredSubpages = chosenPageWithSubpages[0].childPages?.filter((subPage) => subPage.id === chosenSubpage.subpageId);
          if (filteredSubpages?.length) {
            if (filteredSubpages[0]?.usedWidgets?.length) {
              setChosenWidgetLayout(filteredSubpages[0] as BuilderPageItemType);
              setFilteredBuilderWidgetsByPageName(filteredSubpages[0].usedWidgets as WidgetsTypeOnlySupportedByOrganisationPlan[]);
            } else {
              setFilteredBuilderWidgetsByPageName([]);
            }
          }
        }
        setChosenSubpage(undefined);
      } else {
        const chosenWidgets = builderPages.filter((page) => `${page.name}with_page_id${page.id}` === chosenPage);
        if (chosenWidgets.length) {
          setChosenWidgetLayout(chosenWidgets[0] as BuilderPageItemType);
          setFilteredBuilderWidgetsByPageName(chosenWidgets[0].usedWidgets as WidgetsTypeOnlySupportedByOrganisationPlan[]);
        }
      }
    }
  }, [builderPages, chosenPage, chosenSubpage]);

  const infoSettingsFields: IOrganisationPlanDynamicFields[] = [
    {
      id: 'select-page-section',
      label: intl.formatMessage({ id: 'organisation.select-page' }),
      isDisabled: false
    },
    {
      id: 'widget-field-section',
      label: intl.formatMessage({ id: 'organisation.widget-field' }),
      isDisabled: !chosenPage || filteredBuilderWidgetsByPageName.length === 0
    }
  ];

  const generateWidgetByType = (widget: WidgetsType, sectionId: IOrganisationPlanSection['sectionId']) => {
    let generatedWidget = null;
    switch (widget.type) {
      case BUILDER_WIDGET_TYPES.inputText:
        generatedWidget = generateInputTextWidget(widget);
        break;
      case BUILDER_WIDGET_TYPES.inputInteger:
        generatedWidget = generateInputNumberWidget(widget);
        break;
      case BUILDER_WIDGET_TYPES.time:
        generatedWidget = generateTimePickerWidget(widget);
        break;
      case BUILDER_WIDGET_TYPES.singleChoice: {
        generatedWidget = generateSingleChoiceWidget(widget);
        break;
      }
      case BUILDER_WIDGET_TYPES.multipleChoice: {
        if (chosenWidgetLayout?.type === QuestionnairePageType.GuestsAndRooms) {
          generatedWidget = generateMultipleChoiceForGuestAndRoomsWidget(widget);
          break;
        }
        generatedWidget = generateMultipleChoiceWidget(widget);
        break;
      }
      case BUILDER_WIDGET_TYPES.singleImageChoice: {
        generatedWidget = generateSingleImageChoiceWidget(widget);
        break;
      }
      case BUILDER_WIDGET_TYPES.multipleImageChoice: {
        generatedWidget = generateMultipleImageChoiceWidget(widget);
        break;
      }
      case BUILDER_WIDGET_TYPES.table:
        generatedWidget = generateTableWidget(widget);
        break;
      case BUILDER_WIDGET_TYPES.uploadArea:
        generatedWidget = generateUploadAreaWidget(widget);
        break;
      default:
        return null;
    }
    return { ...generatedWidget, sectionId };
  };

  const closeInfoFromQuestionnaireSettings = () => {
    dispatch(setActiveInfoFromQuestionnaireSettings(''));
    dispatch(setEditWidgetSettings(null));
  };

  const addWidgetToOrganisationPlanLayout = () => {
    if (editedWidgetInfo?.pageOfChosenWidget && selectedWidgetId) {
      const chosenWidgetFromPlayground = editedWidgetInfo?.chosenWidget;
      const chosenWidgetFromBuilder = filteredBuilderWidgetsByPageName.filter((widget) => widget.widgetId === selectedWidgetId)[0];
      const generatedWidget = generateWidgetByType(chosenWidgetFromBuilder, activeSectionId);
      if (generatedWidget && chosenWidgetFromPlayground) {
        dispatch(
          updateOrganisationPlanWidget({
            widgetId: chosenWidgetFromPlayground.widgetId,
            widget: {
              ...(generatedWidget.widget as OrganisationPlanWidgetsType),
              widgetId: chosenWidgetFromPlayground.widgetId,
              linkedWidgetId: generatedWidget.widget.widgetId
            }
          })
        );
        dispatch(setEditWidgetSettings(null));
      }
      return;
    }
    if (selectedWidgetId) {
      const chosenWidgetFromBuilder = filteredBuilderWidgetsByPageName.filter((widget) => widget.widgetId === selectedWidgetId)[0];
      const generatedWidget = generateWidgetByType(chosenWidgetFromBuilder, activeSectionId);
      if (generatedWidget) {
        dispatch(
          updateOrganisationPlanWidget({
            widgetId: activeInfo,
            widget: {
              ...(generatedWidget.widget as OrganisationPlanWidgetsType),
              widgetId: activeInfo,
              linkedWidgetId: generatedWidget.widget.widgetId
            }
          })
        );
      }

      dispatch(setActiveInfoFromQuestionnaireSettings(''));
      dispatch(setEditWidgetSettings(null));
    }
  };

  const addAllWidget = () => {
    const allWidgetsPage = filteredBuilderWidgetsByPageName.map((oldEl) => generateWidgetByType(oldEl, activeSectionId)).filter((el) => el);
    const allWithLink = allWidgetsPage.map((el) => ({
      ...el,
      widget: {
        ...el?.widget,
        sectionId: activeSectionId,
        linkedWidgetId: el?.widget.widgetId
      }
    }));

    if (!allWidgetsPage.length) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Nothing to import',
          variant: 'alert',
          alert: {
            color: 'warning'
          },
          close: true
        })
      );

      return;
    }
    dispatch(setManyWidgets({ widgets: allWithLink as IImportAllWidgetPayload['widgets'], sectionId: activeSectionId, activeInfo }));
    dispatch(setActiveInfoFromQuestionnaireSettings(''));
    dispatch(setActiveInfoFromQuestionnaireSettingsSectionId(''));
  };

  return (
    <Box>
      <WidgetBarTypography>
        <FormattedMessage id="organisation.info-from-answers" />
      </WidgetBarTypography>
      <Divider sx={{ borderWidth: '3px' }} />
      <OrganisationPlanTabPanel value={tabValueIndex} activeValue={0}>
        <Stack padding="10px" gap="20px" justifyContent="space-between" height="calc(100vh - 140px)">
          <Stack gap="20px">
            {infoSettingsFields.map((field) => {
              return (
                <OrganisationPlanDynamicFields
                  key={field.id}
                  infoSettingField={field}
                  builderPages={builderPages}
                  chosenPage={chosenPage}
                  setChosenPage={setChosenPage}
                  filteredBuilderWidgetsByPageName={filteredBuilderWidgetsByPageName}
                  chosenWidget={chosenWidget}
                  setChosenWidget={setChosenWidget}
                  setSelectedWidgetId={setSelectedWidgetId}
                  setChosenSubpage={setChosenSubpage}
                />
              );
            })}

            <Stack direction="column" gap="10px">
              <Button
                disabled={!chosenWidget}
                variant="contained"
                fullWidth
                className="info-save-button"
                onClick={addWidgetToOrganisationPlanLayout}
              >
                <FormattedMessage id="organization-plan.add-selected-widget" />
              </Button>
              {!editedWidgetInfo && (
                <Button disabled={!chosenPage.length || !!editedWidgetInfo} onClick={addAllWidget} variant="outlined" fullWidth>
                  <FormattedMessage id={'organization-plan.add-all-widgets-of-page'} />
                </Button>
              )}
            </Stack>
          </Stack>

          <Button variant="outlined" fullWidth onClick={closeInfoFromQuestionnaireSettings}>
            <FormattedMessage id="builder.close" />
          </Button>
        </Stack>
      </OrganisationPlanTabPanel>
    </Box>
  );
};

export default OrganisationPlanTemplateQuestionnaireInfoSettings;
