import { TextField } from '@mui/material';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { ChangeEventHandler } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface PreviewNoteWidgetProps {
  layoutItem: Layout;
  element: OrganisationPlanNotesWidgetType;
}

const PreviewNoteWidget = ({ layoutItem, element }: PreviewNoteWidgetProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { widgetId, type, sectionId, noteText, isBottomDivider, isHighlighted } = element;

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const newValue = e.target.value;
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanNotesWidgetType['type'], noteText: newValue }
      })
    );
  };

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
    >
      <TextField
        fullWidth={true}
        multiline={true}
        minRows={1}
        maxRows={100}
        placeholder={intl.formatMessage({ id: 'organisation.notes' })}
        id="outlined-name"
        value={noteText}
        onChange={(e) => onChange(e)}
      />
    </PreviewWidgetWrapper>
  );
};

export default PreviewNoteWidget;
