import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

interface IStyleImage {
  customImageSize?: boolean;
}

export const BoxWrapper = styled(Box)<IStyleImage>(({ customImageSize }) => ({
  img: {
    maxWidth: '106px',
    maxHeight: '82px',
    width: customImageSize ? '72px' : 'auto',
    height: customImageSize ? '20px' : 'auto'
  }
}));

export const WidgetItem = styled(Card)(({ theme }) => ({
  cursor: '-webkit-grab',
  borderRadius: '6px',
  gap: '20px',
  maxWidth: '1000px',
  minHeight: '170px',
  aspectRatio: '1 / 1.1',
  background: theme.palette.secondary.lighter,
  '&:hover': {
    background: theme.palette.primary.lighter
  },
  boxShadow: theme.palette.mode === 'dark' ? `0 0 0 1px #ffffff20 inset` : `0 0 0 1px #00000020 inset`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}));
