import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrganisersAndGuestsInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'wrap',
  justifyContent: 'center',
  padding: '10px ',
  backgroundColor: theme.palette.background.paper,
  gap: '10px 30px'
}));
