import { Card, styled } from '@mui/material';

export const SectionContainer = styled(Card)(({ theme }) => ({
  width: '100%',
  boxShadow: `${theme.palette.divider} 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 6px 24px 0px`,
  borderRadius: '10px',
  backgroundColor: theme.palette.secondary.lighter,
  '@media print': {
    borderRadius: '0px',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`
  }
}));
