import { Box, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BODY_FONT_SIZE, BODY_FONT_WEIGHT } from 'modules/organisation-plan-preview/constants';

interface IStyleExpandableMenuBox {
  isNumberOfGuestsMenuOpen: boolean;
}

export const BoxWrapper = styled(Box)(() => ({
  paddingTop: '18px',
  paddingLeft: '10px',
  paddingRight: '10px'
}));

export const FormControlLabelNoExpandableWrapper = styled(FormControlLabel)(() => ({
  marginRight: '22px',

  '& .MuiFormControlLabel-label': { fontWeight: BODY_FONT_WEIGHT, fontSize: BODY_FONT_SIZE }
}));

export const BoxExpandableSectionWrapper = styled(Box)(() => ({
  marginRight: '20px',
  position: 'relative'
}));

export const BoxExpandableMenuWrapper = styled(Box)<IStyleExpandableMenuBox>(({ isNumberOfGuestsMenuOpen, theme }) => ({
  position: 'absolute',
  width: '205px',
  display: isNumberOfGuestsMenuOpen ? 'block' : 'none',
  padding: '0px 0 0px 12px',
  boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '6px',
  backgroundColor: theme.palette.background.default,
  zIndex: 9999
}));

export const FormControlLabelOuterWrapper = styled(FormControlLabel)(({ theme }) => ({
  marginRight: '2px',

  '& .MuiFormControlLabel-label': {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    fontWeight: BODY_FONT_WEIGHT,
    fontSize: BODY_FONT_SIZE,
    marginRight: '4px',
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      fontWeight: BODY_FONT_WEIGHT,
      fontSize: BODY_FONT_SIZE
    }
  }
}));

export const FormControlLabelInnerWrapper = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.primary,
    fontWeight: BODY_FONT_WEIGHT,
    fontSize: BODY_FONT_SIZE,
    marginRight: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '152px'
  }
}));

export const NoDataOptionsContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  h4: {
    fontWeight: 500,
    fontSize: '12px',
    color: 'orange',
    opacity: '0.6'
  }
}));
