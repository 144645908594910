import { Button, CircularProgress } from '@mui/material';
import { useUpdateOrganisationPlanTemplate } from 'api/hooks/organisation-plan-templates/useCreateOrganisationPlanTemplate';
import { useUpdateOrganizationTemplateBuilderById } from 'api/hooks/organisation-plan-templates/useCreateOrganizationPlanTemplateBuilder';
import { preparedBuilderData, preparedCommonTemplateData } from 'modules/organisation-plan-templates/utils/handleSubmitButtonContainer';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import { snackbarAlert } from 'shared/utils/infoText';
import { useDispatch, useSelector } from 'store';
import {
  organizationPlanTemplateBuilderUsedWidgets,
  selectAllOrgPlanLinks,
  selectHeaderOptions,
  selectOrganizationPlanAllSections,
  selectOrganizationPlanAllWidgets
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { restartPrimaryState } from 'store/organisation-plan-template/organisation-plan-template.slice';

const SaveButtonContainer = (): React.ReactElement => {
  const usedWidgets = useSelector(organizationPlanTemplateBuilderUsedWidgets);
  const allWidgetsOrgPlan = useSelector(selectOrganizationPlanAllWidgets);
  const usedSections = useSelector(selectOrganizationPlanAllSections);
  const links = useSelector(selectAllOrgPlanLinks);
  const headerOptions = useSelector(selectHeaderOptions);

  const dispatch = useDispatch();
  const intl = useIntl();
  const { organisationPlanId, questionnaireTemplateId } = useParams();

  const { isLoading: isLoadingOrganisationPlan, mutateAsync: updateOrganisationPlanTemplate } = useUpdateOrganisationPlanTemplate({});
  const { isLoading: isLoadingQuestionnaireTemplate, mutateAsync: updateQuestionnaireTemplateBuilderById } =
    useUpdateOrganizationTemplateBuilderById({});

  const handleSubmit = async () => {
    const { commonData, templateData } = preparedCommonTemplateData({ organisationPlanId, questionnaireTemplateId, headerOptions });
    const builderData = preparedBuilderData({ usedWidgets, commonData, headerOptions, usedSections, links });
    try {
      await Promise.all([updateOrganisationPlanTemplate(templateData), updateQuestionnaireTemplateBuilderById(builderData)]);
      dispatch(snackbarAlert(intl.formatMessage({ id: 'organisation-plan-templates.saved-successfully' }), AlertMessagesColor.Success));
      dispatch(restartPrimaryState({ usedWidgets: allWidgetsOrgPlan, links, sections: builderData.data?.sections, headerOptions }));
    } catch {
      dispatch(snackbarAlert(intl.formatMessage({ id: 'organisation-plan-templates.something-went-wrong' }), AlertMessagesColor.Error));
    }
  };

  const isLoading = isLoadingOrganisationPlan || isLoadingQuestionnaireTemplate;

  return (
    <Button
      size="small"
      startIcon={isLoading ? <CircularProgress size={'20px'} /> : ''}
      variant="contained"
      onClick={handleSubmit}
      disabled={isLoading}
    >
      <FormattedMessage id="users.save" />
    </Button>
  );
};

export default SaveButtonContainer;
