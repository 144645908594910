import { Edit20Regular } from '@fluentui/react-icons';
import { Box, IconButton } from '@mui/material';
import { KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { TextEdit, TextEditProps } from 'shared/components/TextEdit';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import * as Styles from './EditableLabel.styles';

interface EditableLabelProps extends Omit<TextEditProps, 'onValueSave'> {
  labelTextMaxWidth?: number;
  onSubmit?: (title: string) => void;
  unitLabel?: JSX.Element;
}

const EditableLabel = ({ value, labelTextMaxWidth, onChange, onSubmit, unitLabel, ...props }: EditableLabelProps): ReactElement => {
  const [labelValue, setLabelValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLabelValue(value);
  }, [value]);

  const handleClickEdit = (): void => {
    setEditMode(true);
  };

  const handleInputChange = (newValue: string): void => {
    setLabelValue(newValue);
  };

  const handleSave = (): void => {
    onChange && onChange(labelValue);
    onSubmit && onSubmit(labelValue);
    setEditMode(false);
  };

  useOnClickOutside(ref, () => {
    handleSave();
  });

  const submitOnEnterKeyHandler = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const unitLabelBrackets = () => {
    if (unitLabel) {
      return <span className="pricing-labels">{unitLabel}</span>;
    } else return null;
  };

  return editMode ? (
    <Box width="100%" ref={ref}>
      <TextEdit
        {...props}
        onChange={handleInputChange}
        value={labelValue}
        onValueSave={handleSave}
        submitOnEnter={submitOnEnterKeyHandler}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="center">
      <Styles.LabelStyled sx={{ maxWidth: labelTextMaxWidth }}>{labelValue}</Styles.LabelStyled>
      <IconButton size="small" sx={{ marginLeft: '4px' }} onClick={handleClickEdit}>
        <Edit20Regular />
      </IconButton>
      {unitLabelBrackets()}
    </Box>
  );
};

export default EditableLabel;
