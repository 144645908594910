import { CheckboxUncheckedRegular, CircleSmallFilled } from '@fluentui/react-icons';
import { Box } from '@mui/material';
import useFontSettings from 'modules/organisation-plan-preview/hooks/useFontSettings';
import { ListingOption, TypographyType } from 'modules/organisation-plan-templates/types';

interface IPreviewListingOptionProps {
  listingOption: ListingOption;
  isHidden?: boolean;
  index?: number;
}

const PreviewListingOption = ({ listingOption, isHidden, index }: IPreviewListingOptionProps) => {
  const { fontSettingsState, fontSettings } = useFontSettings();
  const fontSize =
    ((fontSettingsState[TypographyType.answerLabel]?.fontSize ||
      fontSettings.find((f) => f.id === TypographyType.answerLabel)?.defaultValues.fontSize) ??
      0) * 1.2;

  if (!listingOption || listingOption === ListingOption.none || isHidden) return null;

  if (listingOption === ListingOption.bullet)
    return (
      <Box width={`${fontSize}px`} height={`${fontSize}px`} justifyContent="center" alignItems="center" display="flex">
        <CircleSmallFilled fontSize={`${fontSize}px`} />
      </Box>
    );
  if (listingOption === ListingOption.checkbox)
    return (
      <Box width={`${fontSize}px`} height={`${fontSize}px`} justifyContent="center" alignItems="center" display="flex">
        <CheckboxUncheckedRegular fontSize={`${fontSize}px`} />
      </Box>
    );
  if (listingOption === ListingOption.numeric && index !== undefined)
    return (
      <Box width={`${fontSize}px`} height={`${fontSize}px`} justifyContent="center" alignItems="center" display="flex">
        {`${index + 1}.`}
      </Box>
    );
};

export default PreviewListingOption;
