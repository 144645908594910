import { fetchBuilderPages } from 'modules/builder/builder-service';
import { BuilderPageItemType } from 'modules/builder/types';
import { UseQueryResult, useQuery } from 'react-query';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { useDefaultBuilderPagesTitle } from './useDefaultBuilderPagesTitle';

export enum QuestionnairesQueryKeys {
  event = 'questionnaires-event-pages',
  template = 'questionnaires-template-pages',
  request = 'questionnaires-request-pages'
}

export interface IHookParams {
  id?: number;
  tenantId?: number;
  entitySource: EQuestionnairesSource;
  querySkip?: boolean;
  isPreview?: boolean;
}

/**
 * Query to load questionnaires pages and save to store
 * @param obj.id - questionnaire template id
 * @param obj.entitySource - can be one value from enum: event, template or request.
 * @param obj.tenantId - tenant id. Optional.
 * @param obj.querySkip - boolean flag to skip query execution. Optional. Default = false
 * @example { data, isLoading, error } = useGetQuestionnairesPagesById()
 */
export const useGetQuestionnairesPagesById = <T,>({
  id,
  entitySource,
  tenantId: _tenantId,
  querySkip = false
}: IHookParams): UseQueryResult<BuilderPageItemType<T>[]> => {
  const formatStrings = useDefaultBuilderPagesTitle();

  const queryKey = [QuestionnairesQueryKeys[entitySource], id];
  return useQuery(queryKey, async () => (id ? fetchBuilderPages({ id, _tenantId, entitySource, formatStrings }) : undefined), {
    retry: 1,
    enabled: !!id && !querySkip
  });
};
