import { Box, Button, Collapse, Divider, Stack, useTheme } from '@mui/material';
import OrganisationPlanPreviewTemplatePlaygroundSection from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplatePlaygroundSection';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PreviewLabel } from '../Shared/PreviewLabel';

interface IOrganisationPlanTemplateSection {
  section: IOrganisationPlanSection;
  sectionIndex: number;
}

const OrganisationPlanPreviewTemplateSection = ({ section, sectionIndex }: IOrganisationPlanTemplateSection) => {
  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Box key={sectionIndex} bgcolor={'background.paper'}>
      <Stack
        sx={{ '@media print': { breakAfter: 'avoid' } }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p="5px 20px"
        bgcolor={'background.default'}
      >
        <PreviewLabel color={primaryMain} type={TypographyType.headline} label={section.name} />

        <Button onClick={handleExpandClick} size="small" sx={{ '@media print': { display: 'none' } }}>
          {expanded ? (
            <FormattedMessage id="organisation-plan.collapse-section-btn" />
          ) : (
            <FormattedMessage id="organisation-plan.uncollapse-section-btn" />
          )}
        </Button>
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ borderWidth: '2px', '@media print': { breakAfter: 'avoid' } }} />
        <OrganisationPlanPreviewTemplatePlaygroundSection layout={section.layout} sectionId={section.sectionId} />
      </Collapse>
    </Box>
  );
};

export default OrganisationPlanPreviewTemplateSection;
