import { Box } from '@mui/material';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';

interface IPreviewDraftText {
  element: OrganisationPlanTextWidgetType;
}

const PreviewDraftText = ({ element }: IPreviewDraftText) => {
  const { content } = element;
  function createMarkup(text: string) {
    return { __html: text.trim() };
  }

  return (
    <Box
      className="custom-text-widget-appearance-settings"
      sx={{ height: '100%', overflow: 'auto' }}
      dangerouslySetInnerHTML={createMarkup(content)}
    />
  );
};

export default PreviewDraftText;
