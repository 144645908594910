import GridLayout from 'react-grid-layout';
import { useSelector } from 'store';
import { selectIsEvent, selectOrganizationPlanAllWidgets } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { PrintGridWrapper } from './PrintGridWrapper';
import {
  filterLayoutAndWidgetWithActiveLinkedWidgetId,
  filterLayoutArrayByIsHiddenField,
  filteredTemplateByAnswers,
  useCssGridLayout
} from './utils';

interface OrganisationPlanTemplatePlaygroundSectionProps {
  layout: GridLayout.Layout[];
  sectionId: string;
}

const OrganisationPlanPreviewTemplatePlaygroundSection = ({ layout }: OrganisationPlanTemplatePlaygroundSectionProps) => {
  const isEvent = useSelector(selectIsEvent);

  const allWidgets = useSelector(selectOrganizationPlanAllWidgets);
  const widgets = isEvent ? allWidgets : allWidgets.map((w) => ({ ...w, showOnlySelectedAnswer: false }));
  const filteredByLinkedWidgetId = filterLayoutAndWidgetWithActiveLinkedWidgetId(layout, widgets);
  const filteredByAnswers = filteredTemplateByAnswers(filteredByLinkedWidgetId.layoutArray, filteredByLinkedWidgetId.widgets);
  const filterData = isEvent ? filteredByAnswers : filteredByLinkedWidgetId;
  const filteredLayout = filterLayoutArrayByIsHiddenField(filterData.layoutArray, filterData.widgets);
  const filteredLayoutWithoutResizable = filteredLayout.map((item) => ({
    ...item,
    isResizable: false,
    resizeHandles: []
  }));
  const layoutOrder = useCssGridLayout(filteredLayoutWithoutResizable);
  return <PrintGridWrapper layout={layoutOrder} />;
};

export default OrganisationPlanPreviewTemplatePlaygroundSection;
