import { Bookmark20Filled } from '@fluentui/react-icons';
import { Box, Divider, useTheme } from '@mui/material';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';
import { PropsWithChildren } from 'react';
import { Layout } from 'react-grid-layout';

interface ITemplateWidgetWrapper {
  widgetId: string;
  sectionId: string;
  layoutItem: Layout;
  dependencies?: Array<unknown>;
  isBottomDivider?: boolean;
  isHighlighted?: boolean;
  hasPadding?: boolean;
}

const TemplateWidgetWrapper = ({
  widgetId,
  sectionId,
  layoutItem,
  children,
  dependencies,
  isBottomDivider,
  isHighlighted,
  hasPadding = false
}: PropsWithChildren<ITemplateWidgetWrapper>) => {
  const ref = useUpdateOrganisationPlanLayoutPartially({
    widgetId,
    sectionId,
    layoutItem,
    dependencies: dependencies || []
  });
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;
  const lightColor = theme.palette.primary.light;

  return (
    <Box ref={ref}>
      <Bookmark20Filled
        style={{ color: isHighlighted ? mainColor : 'transparent', position: 'absolute', top: -4, right: '78px', zIndex: 99 }}
      />
      <Box padding={hasPadding ? '5px 15px 5px 5px' : 'none'}>{children}</Box>
      {isBottomDivider && <Divider sx={{ borderWidth: '2px', marginTop: '0px', backgroundColor: lightColor }} />}
    </Box>
  );
};

export default TemplateWidgetWrapper;
