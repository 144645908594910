import { Dismiss20Regular, Info20Regular } from '@fluentui/react-icons';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useGetOfferSettings } from 'api/hooks/offersSettings/useGetOfferSettings';
import { de } from 'date-fns/locale';
import { Form, FormikProvider, useFormik } from 'formik';
import CreateUserModal from 'modules/events/modals/CreateUserModal';
import OfferModalDateFields from 'modules/offers/components/OfferModalDateFields';
import OfferModalNumberAndState from 'modules/offers/components/OfferModalNumberAndState';
import OfferModalRecipientAndVenue from 'modules/offers/components/OfferModalRecipientAndVenue';
import OfferModalRequestAndValidDate from 'modules/offers/components/OfferModalRequestAndValidDate';
import OfferModalTemplatesAndValue from 'modules/offers/components/OfferModalTemplatesAndValue';
import OfferModalUserActions from 'modules/offers/components/OfferModalUserActions';
import { prepareOfferDataForForm } from 'modules/offers/utils/prepareData';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PageTitle from 'shared/components/PageTitle';
import { useAuth } from 'shared/contexts/AuthContext';
import { IOfferBD, IOfferDialogForm } from 'shared/types/offer';
import { IUser } from 'shared/types/user';
import * as Yup from 'yup';
import * as Styles from './OfferFormModal.styles';

interface IUserDialogState {
  isOpen: boolean;
  isNew: boolean | null;
}

interface IOfferFormModalProps {
  offer?: IOfferBD;
  offerPresetFromEvent?: IOfferDialogForm;
  isOpenModal: boolean;
  isSaving?: boolean;
  hasEvent?: boolean;
  hasRequest?: boolean;
  eventDate?: Date;
  closeModal: () => void;
  onSubmit: (data: IOfferDialogForm) => void;
}

const OfferFormModal = ({
  offer,
  isOpenModal,
  onSubmit,
  closeModal,
  isSaving,
  offerPresetFromEvent,
  hasEvent = false,
  hasRequest = false,
  eventDate
}: IOfferFormModalProps) => {
  const intl = useIntl();
  const [userDialogState, setUserDialogState] = useState<IUserDialogState>({ isNew: null, isOpen: false });

  const { tokenInfo } = useAuth();
  const { data: offerSettings } = useGetOfferSettings({ id: tokenInfo?.tenant });

  const openCreateUserModel = (isNew: boolean): void => {
    setUserDialogState({ isNew, isOpen: true });
  };

  const closeCreateUserModel = (): void => {
    setUserDialogState({ isNew: null, isOpen: false });
  };

  const offerSchema = Yup.object().shape({
    state: Yup.string().required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    taxType: Yup.string().required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    offerDate: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    validUntil: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    requestedEventDate: hasEvent
      ? Yup.string().nullable()
      : Yup.string()
          .nullable()
          .required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    offerTemplate: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      .shape({
        id: Yup.number().required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      }),
    recipient: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      .shape({
        id: Yup.number().required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      }),
    venue: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      .shape({
        id: Yup.number().required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      })
  });

  const formik = useFormik<IOfferDialogForm>({
    initialValues: prepareOfferDataForForm(offer ?? null, offerSettings ?? null),
    validationSchema: offerSchema,
    onSubmit: async (values) => {
      await onSubmit(values);
    }
  });

  const { handleSubmit, values, setValues, resetForm } = formik;

  const updateRecipientValue = (userResponse: IUser) => {
    const newUser = { ...userResponse, name: `${userResponse.name}`.trim() };
    setValues({
      ...values,
      recipient: newUser
    });
  };

  useEffect(() => {
    if (!offer || !offerSettings) return;
    resetForm({ values: prepareOfferDataForForm(offer, offerSettings), touched: {} });
  }, [offer, resetForm, offerSettings]);

  useEffect(() => {
    if (!offerPresetFromEvent) return;
    resetForm({ values: offerPresetFromEvent, touched: {} });
  }, [offerPresetFromEvent, resetForm]);

  const titlePage = !!offer?.offerDetails ? offer?.offerNumber : intl.formatMessage({ id: 'requests.table-action-create-offer' });
  const titleDialog = !!offer?.offerDetails ? 'requests.offer-title-edit' : 'requests.table-action-create-offer';

  return (
    <Dialog maxWidth="sm" onClose={closeModal} open={isOpenModal}>
      <Box sx={{ display: userDialogState.isOpen ? 'none' : 'block' }}>
        <PageTitle title={titlePage} />
        <FormikProvider value={formik}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogTitle>
                <FormattedMessage id={titleDialog} />
                <IconButton disabled={isSaving} onClick={closeModal} size="small">
                  {isSaving ? <CircularProgress size={20} /> : <Dismiss20Regular />}
                </IconButton>
              </DialogTitle>
              <Divider />
              {hasEvent && (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems={'center'}
                  padding={'5px 20px'}
                  bgcolor={'primary.lighter'}
                  // boxShadow={'  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px inset'}
                >
                  <Info20Regular style={{ minWidth: '30px' }} />
                  <Typography>
                    <FormattedMessage id="offers.modal-event-exists-info" />
                  </Typography>
                </Stack>
              )}
              <Styles.DialogContent dividers>
                <Grid container spacing={3}>
                  <OfferModalNumberAndState />
                  <OfferModalRequestAndValidDate />
                  <OfferModalDateFields hasEvent={hasEvent} hasRequest={hasRequest} eventDate={eventDate} />
                  <OfferModalTemplatesAndValue />
                  <OfferModalRecipientAndVenue />
                  <OfferModalUserActions openUserModal={openCreateUserModel} />
                  <Grid item xs={12}></Grid>
                </Grid>
              </Styles.DialogContent>

              <DialogActions>
                <Styles.BoxAction>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Button color="error" onClick={closeModal} disabled={isSaving}>
                          <FormattedMessage id="users.cancel" />
                        </Button>
                        <Button
                          startIcon={isSaving ? <CircularProgress size={'20px'} /> : ''}
                          type="submit"
                          variant="contained"
                          disabled={isSaving}
                        >
                          {!!offer?.offerDetails ? <FormattedMessage id="users.save" /> : <FormattedMessage id="events.create" />}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Styles.BoxAction>
              </DialogActions>
            </Form>
          </LocalizationProvider>
        </FormikProvider>
      </Box>
      <CreateUserModal
        open={userDialogState.isOpen}
        closeCreateUserModel={closeCreateUserModel}
        userId={userDialogState.isNew ? undefined : values?.recipient?.id}
        updateUserValue={updateRecipientValue}
      />
    </Dialog>
  );
};

export default OfferFormModal;
