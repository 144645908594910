import { MoreVertical20Regular } from '@fluentui/react-icons';
import { Box, Dialog, Divider, IconButton, Menu, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import FeatureRoleGuard from 'modules/auth/components/FeatureRoleGuard';
import { Roles } from 'modules/auth/types';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { replaceAllSpace } from 'shared/utils/editText';
import OptionOfMenu from './components/OptionOfMenu';

export interface IListOptionContextMenu {
  onClick?: (isCloseMenu?: boolean) => void;
  icon?: ReactNode;
  disabled?: boolean;
  disabledIcon?: ReactNode;
  isDisabledIcon?: boolean;
  messageId: string;
  roles?: Roles[];
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  disabledMenu?: boolean;
  className?: string;
  isCloseMenu?: boolean;
  isBorderTop?: boolean;
  isBorderBottom?: boolean;
  isHidden?: boolean;
  switchValue?: boolean;
  isQuickAction?: boolean;
  submenu?: IListOptionContextMenu[];
}

interface IContextMenu {
  listOptions: IListOptionContextMenu[];
  isShowMenu?: boolean;
  sxContainer?: SxProps<Theme>;
  sxMenu?: SxProps<Theme>;
  sxIconButton?: SxProps<Theme>;
  icon?: ReactNode;
  origin?: 'top' | 'bottom';
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
  isRightClick?: boolean;
  noIcon?: boolean;
  isExternal?: boolean;
  externalAnchor?: { mouseX: number; mouseY: number } | null;
  isSubmenu?: boolean;
  isWidget?: boolean;
}

/**
 * Component to generate context menu from listOptions
 * @param listOptions - option in context menu
 * @param isShowMenu -  context menu is opened or closed
 * @param sxContainer - additional styles of context
 * @param sxMenu - additional styles of menu
 * @param sxIconButton - additional styles of icon button
 * @param icon - icon of context menu
 * @param origin - origin of context menu: "top" | "bottom"
 * @param size - size of context menu button: "small" | "medium" | "large"
 * @param children - children of context menu
 * @param isRightClick - context menu is opened by right click
 * @param noIcon - context menu has no icon
 * @param onContextMenu - function to show context menu
 * @param isExternal - context menu is external
 * @param externalAnchor - external anchor of context menu
 * @param isSubmenu - context menu is submenu
 * @param isWidget - context menu is used in builder for widgets
 */

const ContextMenu = ({
  listOptions,
  isShowMenu = true,
  sxContainer,
  sxMenu,
  sxIconButton,
  icon,
  origin,
  size,
  children,
  isRightClick,
  noIcon = false,
  isExternal = false,
  externalAnchor = null,
  isSubmenu = false,
  isWidget = false
}: IContextMenu) => {
  const intl = useIntl();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (!event) return;
    event.stopPropagation();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY
    });
  };

  useEffect(() => {
    if (isExternal && externalAnchor) {
      setContextMenu(externalAnchor);
    }
  }, [externalAnchor, isExternal]);

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const handleOptionClick = (isCloseMenu: boolean) => {
    isCloseMenu && handleCloseMenu();
  };

  const baseStyles: SxProps<Theme> = {
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: ' center'
  };

  const isOpen = Boolean(contextMenu);

  const handleContextMenuPropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleRightClickOnChildren = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isRightClick) return;
    setContextMenu({
      mouseX: e.clientX,
      mouseY: e.clientY
    });
  };

  if (isSubmenu) {
    <>
      {isShowMenu && contextMenu && !isMobile && (
        <Menu
          id="simple-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
          keepMounted
          open={isOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: origin ? (origin === 'top' ? 'top' : 'bottom') : 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: origin ? (origin === 'top' ? 'top' : 'bottom') : 'top',
            horizontal: 'right'
          }}
          sx={sxMenu}
        >
          {listOptions
            .filter((option) => !option.isHidden)
            .map((option, index) => {
              if (!!option?.roles) {
                return (
                  <FeatureRoleGuard key={replaceAllSpace(option.messageId)} roles={option.roles}>
                    <OptionOfMenu isIcons option={option} setIsCloseMenu={handleOptionClick} />
                  </FeatureRoleGuard>
                );
              }
              return <OptionOfMenu isIcons key={index} option={option} setIsCloseMenu={handleOptionClick} />;
            })}
        </Menu>
      )}
    </>;
  }

  return (
    <Stack
      onContextMenu={handleContextMenuPropagation}
      direction="row"
      width="100%"
      height="100%"
      gap={!!children ? '0px' : 0}
      sx={sxContainer ?? baseStyles}
      position="relative"
      display="flex"
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box height="100%" width="100%" onContextMenu={handleRightClickOnChildren}>
        {children}
      </Box>
      {!noIcon && !isSubmenu && !isWidget && (
        <IconButton className="not-draggable" size={size ? size : 'small'} onClick={handleClickMenu} sx={sxIconButton}>
          {icon ? icon : <MoreVertical20Regular className="dots-active" />}
        </IconButton>
      )}
      {isWidget && (
        <Stack direction="row" gap={0} alignItems="center">
          {listOptions
            .filter((option) => !option.isHidden)
            .filter((option) => option.isQuickAction)
            .map((option, index) => {
              if (!!option?.roles) {
                return (
                  <FeatureRoleGuard key={index} roles={option.roles}>
                    <>
                      {option.isBorderTop && <Divider sx={{ borderWidth: '2px' }} orientation="vertical" flexItem />}
                      <Tooltip enterDelay={1200} placement="top" key={index} title={intl.formatMessage({ id: option.messageId })} arrow>
                        <IconButton sx={{ borderRadius: '0' }} key={index} size="small" onClick={() => option.onClick && option.onClick()}>
                          {option.icon}
                        </IconButton>
                      </Tooltip>
                      {option.isBorderBottom && <Divider sx={{ borderWidth: '2px' }} orientation="vertical" flexItem />}
                    </>
                  </FeatureRoleGuard>
                );
              }
              return (
                <>
                  {option.isBorderTop && <Divider sx={{ borderWidth: '2px' }} orientation="vertical" flexItem />}
                  <Tooltip
                    sx={{ borderRadius: '0' }}
                    enterDelay={1200}
                    placement="top"
                    key={index}
                    title={intl.formatMessage({ id: option.messageId })}
                    arrow
                  >
                    <IconButton key={index} size="small" onClick={() => option.onClick && option.onClick()}>
                      {option.icon}
                    </IconButton>
                  </Tooltip>
                  {option.isBorderBottom && <Divider sx={{ borderWidth: '2px' }} orientation="vertical" flexItem />}
                </>
              );
            })}
          {!noIcon && (
            <>
              <Divider sx={{ borderWidth: '2px' }} orientation="vertical" flexItem />
              <Tooltip enterDelay={1200} placement="top" title={intl.formatMessage({ id: 'organization-plan.all-options' })} arrow>
                <IconButton
                  className="not-draggable"
                  size={size ? size : 'small'}
                  onClick={handleClickMenu}
                  sx={{ borderRadius: '0', ...sxIconButton }}
                >
                  {icon ? icon : <MoreVertical20Regular className="dots-active" />}
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      )}

      {isShowMenu && contextMenu && !isMobile && (
        <Menu
          id="simple-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
          keepMounted
          open={isOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: origin ? (origin === 'top' ? 'top' : 'bottom') : 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: origin ? (origin === 'top' ? 'top' : 'bottom') : 'top',
            horizontal: 'right'
          }}
          sx={sxMenu}
        >
          {listOptions
            .filter((option) => !option.isHidden)
            .map((option, index) => {
              if (!!option?.roles) {
                return (
                  <FeatureRoleGuard key={replaceAllSpace(option.messageId)} roles={option.roles}>
                    <OptionOfMenu isIcons option={option} setIsCloseMenu={handleOptionClick} />
                  </FeatureRoleGuard>
                );
              }
              return <OptionOfMenu isIcons key={index} option={option} setIsCloseMenu={handleOptionClick} />;
            })}
        </Menu>
      )}
      {isShowMenu && contextMenu && isMobile && (
        <Dialog id="simple-menu" keepMounted open={isOpen} onClose={handleCloseMenu}>
          {listOptions
            .filter((option) => !option.isHidden)
            .map((option, index) => {
              if (!!option?.roles) {
                return (
                  <FeatureRoleGuard key={replaceAllSpace(option.messageId)} roles={option.roles}>
                    <OptionOfMenu option={option} setIsCloseMenu={handleOptionClick} />
                  </FeatureRoleGuard>
                );
              }
              return <OptionOfMenu key={index} option={option} setIsCloseMenu={handleOptionClick} />;
            })}
        </Dialog>
      )}
    </Stack>
  );
};

export default ContextMenu;
