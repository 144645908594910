import { Person20Regular } from '@fluentui/react-icons';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { useIntl } from 'react-intl';
import { IGuestDisplayOption, IOrgPlanGuestOptions } from 'shared/types/organisation-plan-preview';
import { PreviewLabel } from '../Shared/PreviewLabel';

interface IOrganisationPlanPreviewNumberOfGuestsInfoOptionProps {
  option: IGuestDisplayOption;
  guestOptionsValues: IOrgPlanGuestOptions[];
}
const OrganisationPlanPreviewNumberOfGuestsInfoOption = ({
  option,
  guestOptionsValues
}: IOrganisationPlanPreviewNumberOfGuestsInfoOptionProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const countOfGuest = guestOptionsValues?.find((guest) => guest?.id === option?.id)?.count;
  const guestInfo = intl.formatMessage({ id: 'organisation-plan.number-of-guests' });

  return countOfGuest ? (
    <Stack direction="row" gap="10px" alignItems="center">
      <Person20Regular color={theme.palette.primary.main} />
      <PreviewLabel type={TypographyType.answerLabel} label={`${option?.label}: ${countOfGuest} ${guestInfo}`} />
    </Stack>
  ) : null;
};

export default OrganisationPlanPreviewNumberOfGuestsInfoOption;
