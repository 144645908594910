import { useSubgrids } from 'modules/organisation-plan-preview/hooks/useSubgrids';
import { Layout } from 'react-grid-layout';
import PreviewWidget from '../OrganisationPlanPreviewWidgetSection/preview-widgets/PreviewWidget';

export const PrintGridWrapper = ({ layout }: { layout: Layout[] }) => {
  const GAP = 10;

  const subgrids = useSubgrids(layout);

  const parentGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'auto',
    gap: `0 ${GAP * 2}px`,
    margin: `${GAP * 1.5}px`,
    marginBottom: `${GAP * 0.5}px`
  };

  return (
    <div style={parentGridStyle}>
      {subgrids.map((subgrid, index) => {
        const firstItem = subgrid[0];

        // Determine subgrid boundaries
        const minY = Math.min(...subgrid.map((it) => it.y));

        // The subgrid width is based on the w of the first item.
        const subgridWidth = firstItem.w; // 1 or 2

        // Position the subgrid in the parent grid
        const subgridStyle: React.CSSProperties = {
          position: 'relative',
          gridColumnStart: firstItem.x + 1,
          gridColumnEnd: firstItem.x + 1 + subgridWidth
        };

        return (
          <div className="subgrid" style={subgridStyle} key={index}>
            {subgrid.map((item) => {
              const itemStyle = {
                marginBottom: GAP,
                gridColumnStart: item.x - firstItem.x + 1,
                gridColumnEnd: item.x - firstItem.x + 1 + item.w,
                gridRowStart: item.y - minY + 1,
                gridRowEnd: item.y - minY + 1 + 1 // assuming h=1 for simplicity
              };

              return (
                <div className="subgrid-item" style={itemStyle} key={item.i}>
                  <PreviewWidget layoutItem={item} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
