import { AddCircle16Regular, Delete16Regular, TextFont20Regular } from '@fluentui/react-icons';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { useCreateUserPreferences } from 'api/hooks/user/usePostUserPreferences';
import { useUpdateUserPreferences } from 'api/hooks/user/usePutUserPreferences';
import useFontSettings from 'modules/organisation-plan-preview/hooks/useFontSettings';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { textError } from 'shared/utils/infoText';
import { openSnackbar } from 'store/snackbar';

const CustomFontSetting = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const borderColor = theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900]!, 0.08) : alpha(theme.palette.grey[900]!, 0.1);

  const { mutateAsync: createUserPreferences } = useCreateUserPreferences({
    options: {
      onError(error: any) {
        let message = textError(error);
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
      }
    }
  });

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences({
    options: {
      onError(error: any) {
        let message = textError(error);
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
      }
    }
  });

  const { fontSettings, fontSettingsState, setFontSettingsState, isLoading, userPreferences } = useFontSettings();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const savePreferences = async () => {
    if (userPreferences) {
      const promises = fontSettings.map(async (fontSetting) => {
        const currentState = fontSettingsState[fontSetting.id];
        const existingPref = userPreferences.find((pref) => pref.name === `organizationplan-fontsetting-${fontSetting.id}`);

        if (currentState) {
          if (existingPref) {
            await updateUserPreferences({
              id: existingPref.id,
              value: [currentState.fontSize.toString(), currentState.fontWeight.toString()]
            });
          } else {
            await createUserPreferences({
              name: `organizationplan-fontsetting-${fontSetting.id}`,
              value: [currentState.fontSize.toString(), currentState.fontWeight.toString()]
            });
          }
        } else if (existingPref) {
          await updateUserPreferences({ id: existingPref.id, value: [] });
        }
      });

      await Promise.all(promises);
    }
  };

  const handleClose = () => {
    savePreferences();
    setAnchorEl(null);
  };

  const handleAddFontSetting = async (id: string) => {
    const fontSetting = fontSettings.find((fs) => fs.id === id);
    const exists = userPreferences?.find((pref) => pref.name === `organizationplan-fontsetting-${id}`);
    if (fontSetting && !exists) {
      await createUserPreferences(
        {
          name: `organizationplan-fontsetting-${id}`,
          value: [fontSetting.defaultValues.fontSize.toString(), fontSetting.defaultValues.fontWeight.toString()]
        },
        {
          onSuccess: () => {
            setFontSettingsState((prev) => ({
              ...prev,
              [id]: {
                fontSize: fontSetting.defaultValues.fontSize,
                fontWeight: fontSetting.defaultValues.fontWeight
              }
            }));
          }
        }
      );
    } else if (fontSetting) {
      setFontSettingsState((prev) => ({
        ...prev,
        [id]: {
          fontSize: fontSetting.defaultValues.fontSize,
          fontWeight: fontSetting.defaultValues.fontWeight
        }
      }));
    }
  };

  const handleDeleteFontSetting = async (id: string) => {
    const pref = userPreferences?.find((pref) => pref.name === `organizationplan-fontsetting-${id}`);
    if (pref) {
      await updateUserPreferences(
        { id: pref.id, value: ['', ''] },
        {
          onSuccess: () => {
            setFontSettingsState((prev) => {
              const updated = { ...prev };
              delete updated[id];
              return updated;
            });
          }
        }
      );
    }
  };

  const handleFontSizeChange = (id: string, value: number) => {
    setFontSettingsState((prev) => ({
      ...prev,
      [id]: { ...prev[id], fontSize: value }
    }));
  };

  const handleFontWeightChange = (id: string, value: number) => {
    setFontSettingsState((prev) => ({
      ...prev,
      [id]: { ...prev[id], fontWeight: value }
    }));
  };

  const fontSizes = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
  const fontWeights = [400, 600, 800];

  return (
    <>
      <IconButton sx={{ border: `1px solid ${borderColor}` }} color={'secondary'} onClick={handleClick}>
        <TextFont20Regular />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box width="400px">
          <Stack p="10px" direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">
              <FormattedMessage id="organization-plan.custom-user-font-settings" />
            </Typography>
            {isLoading && <CircularProgress size={20} />}
          </Stack>
          <Divider sx={{ borderWidth: '2px' }} />
          {fontSettings.map((fontSetting) => {
            const setting = fontSettingsState[fontSetting.id];

            if (setting && (setting.fontSize || setting.fontWeight)) {
              return (
                <React.Fragment key={fontSetting.id}>
                  <Stack gap="10px" p="5px 10px 10px">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="subtitle1">
                        <FormattedMessage id={fontSetting.intlId} />
                      </Typography>
                      <IconButton size="small" color="error" onClick={() => handleDeleteFontSetting(fontSetting.id)}>
                        <Delete16Regular />
                      </IconButton>
                    </Stack>
                    <Stack direction="row" gap="10px" width="100%" alignItems="center">
                      <FormControl fullWidth>
                        <InputLabel size="small" id={`${fontSetting.id}-fontSize`}>
                          <FormattedMessage id="organization-plan.font-size" />
                        </InputLabel>
                        <Select
                          size="small"
                          value={setting.fontSize}
                          onChange={(e) => handleFontSizeChange(fontSetting.id, Number(e.target.value))}
                        >
                          {fontSizes.map((size) => (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <ToggleButtonGroup
                          exclusive
                          size="small"
                          value={setting.fontWeight}
                          onChange={(_, newWeight) => {
                            if (newWeight != null) {
                              handleFontWeightChange(fontSetting.id, newWeight);
                            }
                          }}
                          aria-label="font weight"
                        >
                          {fontWeights.map((weight) => (
                            <ToggleButton
                              key={weight}
                              value={weight}
                              aria-label={weight === 400 ? 'regular' : weight === 600 ? 'medium' : 'bold'}
                            >
                              <Typography fontWeight={weight}>{weight === 400 ? 'Regular' : weight === 600 ? 'Medium' : 'Bold'}</Typography>
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </FormControl>
                    </Stack>
                  </Stack>
                  <Divider sx={{ borderWidth: '2px' }} />
                </React.Fragment>
              );
            } else return null;
          })}
          <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '5px' }}>
            {fontSettings.map((fontSetting) => {
              const setting = fontSettingsState[fontSetting.id];

              if (setting && !setting.fontSize && !setting.fontWeight) {
                return (
                  <Button
                    key={fontSetting.id}
                    size="small"
                    startIcon={<AddCircle16Regular />}
                    variant="outlined"
                    sx={{ borderRadius: '60px' }}
                    onClick={() => handleAddFontSetting(fontSetting.id)}
                  >
                    <FormattedMessage
                      id={`organization-plan.add-${fontSetting.id}-font-settings`}
                      defaultMessage={`Add ${fontSetting.id} font settings`}
                    />
                  </Button>
                );
              } else return null;
            })}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default CustomFontSetting;
