import axios from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { UserPreference } from './useGetUserPreferences';

interface ICreateUserPreferences {
  name: string;
  value: string[];
}

/**
 * Creates a user preference.
 * @function
 * @param {Object} options - The options for creating the user preference.
 * @param {string} options.name - The name of the preference.
 * @param {string[]} options.value - The value of the preference.
 * @returns {Promise<UserPreference>} A promise that resolves with the created user preference.
 */
const createUserPreferences = ({ name, value }: ICreateUserPreferences): Promise<UserPreference> => {
  return axios
    .post('/user-preferences', {
      name,
      value
    })
    .then((res) => {
      return res.data;
    });
};

/**
 * Custom hook that creates a user preference.
 * @function
 * @param {Object} options - The options for the mutation.
 * @param {Object} options.options - The options to pass to the useMutation hook.
 * @param {Function} options.onSuccess - A function to execute after the mutation is successful.
 * @returns {Object} The result of the mutation, including the created user preference.
 */
export function useCreateUserPreferences<TError, TContext>({
  options = {},
  onSuccess
}: {
  options?: Omit<UseMutationOptions<UserPreference, TError, ICreateUserPreferences, TContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(createUserPreferences, {
    ...options,
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['user-preferences']);
    }
  });
}
