import { Typography } from '@mui/material';
import { ITableWidgetColumnRules, ITableWidgetRowRules, TABLE_COLUMN_TYPES } from 'modules/builder/types/tableWidgetType';
import { PreviewLabel } from 'modules/organisation-plan-preview/components/Shared/PreviewLabel';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { useCurrentDateFormatter, useCurrentDateTimeFormatter, useCurrentTimeFormatter } from 'shared/hooks/regionalSettings';

interface IOrganisationPlanTableEditableCellProps {
  value: any;
  row: number;
  column: number;
  settings?: ITableWidgetColumnRules;
  settingsRow?: ITableWidgetRowRules;
  isRowSettingsAvailable?: boolean;
}

const OrganisationPlanTableEditableCell = ({ value: initialValue, settings }: IOrganisationPlanTableEditableCellProps) => {
  const dateValue = initialValue ? new Date(initialValue) : null;
  const placeholder = <Typography variant="body1">-</Typography>;
  if (settings?.type === TABLE_COLUMN_TYPES.time) {
    const time = useCurrentTimeFormatter(dateValue?.toISOString());
    if (!time) {
      return placeholder;
    }
    return <PreviewLabel label={time ?? ' '} type={TypographyType.body} />;
  }

  if (settings?.type === TABLE_COLUMN_TYPES.date) {
    const date = useCurrentDateFormatter(dateValue?.toISOString());
    if (!date) {
      return placeholder;
    }
    return <PreviewLabel label={date} type={TypographyType.body} />;
  }

  if (settings?.type === TABLE_COLUMN_TYPES.dateTime) {
    const dateTime = useCurrentDateTimeFormatter(dateValue?.toISOString());
    if (!dateTime) {
      return placeholder;
    }
    return <PreviewLabel label={dateTime} type={TypographyType.body} />;
  }

  if (settings?.type === TABLE_COLUMN_TYPES.number) {
    if (!initialValue) {
      return placeholder;
    }
    return <PreviewLabel label={initialValue} type={TypographyType.body} />;
  }
  if (!initialValue) {
    return placeholder;
  }
  return <PreviewLabel label={initialValue} type={TypographyType.body} />;
};

export default OrganisationPlanTableEditableCell;
