import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  position: 'fixed',
  width: '100%',
  background: theme.palette.background.paper,
  top: 0,
  left: 0,
  zIndex: 1000
}));
