import { FC, useState } from 'react';
// import Attachment from '../../../../../assets/images/organisation-plan-template/attachment.svg';
// import AttachmentActive from '../../../../../assets/images/organisation-plan-template/attachment_active.svg';
import { Image16Regular, Link16Regular } from '@fluentui/react-icons';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import { Layout } from 'react-grid-layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleActionButton } from 'shared/components/ReplacementText/Buttons/Button.styles';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import TemplateWidgetWrapper from '../Shared/TemplateWidgetWrapper';
import { UploadWrapper } from './OrganisationPlanUploadAreaWithReplacementWidget.styles';

interface IOrganisationPlanUploadAreaWithReplacementWidgetProps {
  widget: OrganisationPlanUploadAreaWithReplacementType;
  layoutItem: Layout;
}

const OrganisationPlanUploadAreaWithReplacementWidget: FC<IOrganisationPlanUploadAreaWithReplacementWidgetProps> = ({
  widget,
  layoutItem
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    type,
    label,
    widgetId,
    isMainLabelHidden,
    replacementLabel,
    isInlinePreview,
    isInlineLink,
    sectionId,
    isBottomDivider,
    isHighlighted
  } = widget;
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);
  const [inlinePreview, setIsInlinePreview] = useState(!!isInlinePreview);
  const inlinePreviewCallback = () => {
    setIsInlinePreview(!inlinePreview);

    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], isInlinePreview: !inlinePreview }
      })
    );
  };

  const [inlineLink, setIsInlineLink] = useState(!!isInlineLink);
  const inlineLinkCallback = () => {
    setIsInlineLink(!inlineLink);
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], isInlineLink: !inlineLink }
      })
    );
  };

  // const [isAttachment, setIsAttachment] = useState(false);
  // const attachmentCallback = () => setIsAttachment(!isAttachment);

  const buttons = [
    {
      icon: <Image16Regular />,
      tooltipMessage: intl.formatMessage({ id: 'organisation.inline-preview' }),
      callback: inlinePreviewCallback,
      isActive: inlinePreview
    },
    {
      icon: <Link16Regular />,
      tooltipMessage: intl.formatMessage({ id: 'organisation.inline-link' }),
      callback: inlineLinkCallback,
      isActive: inlineLink
    }
    /*
     TODO: uncomment when attachment will be disacussed with client
    {
      image: isAttachment ? AttachmentActive : Attachment,
      tooltipMessage: intl.formatMessage({ id: 'organisation.attachment' }),
      callback: attachmentCallback,
      isActive: isAttachment
    }*/
  ];

  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], isMainLabelHidden }
      })
    );
  };

  return (
    <TemplateWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      dependencies={[isHeadingOpen]}
      hasPadding
    >
      <ReplacementTextHeading
        isQuestion
        onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
        value={replacementLabel}
        content={label || intl.formatMessage({ id: 'organisation.no-label-provided' })}
        isLabelHiddenValue={isMainLabelHidden}
        onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
        onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
        isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
      />
      <UploadWrapper>
        <Typography variant="body1" color="textSecondary" align="center">
          <FormattedMessage id="organisation.upload-placeholder" />
        </Typography>
        <Stack direction="row" spacing={1} justifyContent="center">
          {buttons.map((i) => (
            <Box key={i.tooltipMessage}>
              <Tooltip enterDelay={1200} arrow placement="top" title={i.tooltipMessage}>
                <SingleActionButton isFixed={false} onClick={i.callback} isActive={i.isActive}>
                  {i.icon}
                </SingleActionButton>
              </Tooltip>
            </Box>
          ))}
        </Stack>
      </UploadWrapper>
    </TemplateWidgetWrapper>
  );
};

export default OrganisationPlanUploadAreaWithReplacementWidget;
