import { applyFormatToDateTimePickerValue } from 'modules/organisation-plan-preview/utils/applyFormatToDateTimePickerValue';
import { DateSyntaxFormat } from 'modules/settings/constants';
import { EventCreationStatusType, IEvent } from 'shared/types/event';
import { IInvoiceByEventId } from 'shared/types/invoice';
import { ILanguage } from 'shared/types/language';

export const prepareEventDataToForm = (event?: IEvent) => {
  const idData = event?.id ? { id: event.id } : {};
  const GERMAN: ILanguage = { id: 2, name: 'German (Germany)', code: 'de', locale: 'de-DE' };

  return {
    ...idData,
    questionnaire: event?.questionnaire,
    venue: event?.venue,
    eventName: event?.name,
    eventType: event?.eventType,
    eventDate: event?.eventDate ?? new Date(),
    deadLine: event?.deadLine ?? new Date(),
    eventManager: event?.eventManager,
    contactPeople: event?.contactPeople ?? [],
    language: event?.language ?? GERMAN,
    eventState: event?.eventState ?? EventCreationStatusType.Pending
  };
};

export const dateCreateInvoiceOfEvent = (
  currentFormat: DateSyntaxFormat['format'],
  isTwelveHoursFormat: boolean,
  invoiceCreateDate?: IInvoiceByEventId['createDate']
): string => {
  const dateFormat = currentFormat || 'dd.MM.yyyy';
  return (
    applyFormatToDateTimePickerValue({
      type: 'dateTime',
      date: invoiceCreateDate ?? null,
      dateFormat: dateFormat,
      isTwelveHoursFormat
    }) ?? ''
  );
};
