import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IBoxMenuItem {
  isBorderTop?: boolean;
  isBorderBottom?: boolean;
}

export const BoxMenuItem = styled(MenuItem)<IBoxMenuItem>(({ isBorderTop, isBorderBottom, theme }) => ({
  borderTop: isBorderTop ? `3px solid ${theme.palette.divider}` : 'none',
  borderBottom: isBorderBottom ? `3px solid ${theme.palette.divider}` : `1px solid ${theme.palette.divider}`,
  padding: '8px 12px !important',
  gap: '15px'
}));
