export const ORGANISATION_PLAN_PREVIEW_ROUTES = {
  ROOT_LINK: 'organization-plan',
  LINK_BY_TOKEN: ':token',
  GET_LINK_BY_TOKEN: (token: string) => `/organization-plan/${token}`
};

export enum FileExtensionByType {
  pdf = 'pdf',
  docs = 'docs',
  docx = 'docx',
  pptx = 'pptx',
  txt = 'txt',
  csv = 'csv',
  xlsx = 'xlsx',
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  svg = 'svg'
}

export const BODY_FONT_SIZE = '1rem';
export const HEADER_FONT_SIZE = '1rem';
export const ANSWER_FONT_SIZE = '1rem';
export const QUESTION_FONT_SIZE = '1rem';
export const ANSWER_VALUE_FONT_SIZE = '0.9rem';

export const BODY_FONT_WEIGHT = 400;
export const HEADER_FONT_WEIGHT = 600;
export const ANSWER_FONT_WEIGHT = 400;
export const QUESTION_FONT_WEIGHT = 600;
export const ANSWER_VALUE_FONT_WEIGHT = 500;
