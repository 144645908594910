import OrganisationPlanPreviewTemplateSectionsContainer from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplateSectionsContainer';
import useDispatchOrganisationPlanData from 'modules/organisation-plan-preview/hooks/useDispatchOrganisationPlanData';
import Loader from 'shared/components/Loader';

const OrganisationPlanPreviewWidgetSection = () => {
  const { isLoading, organisationPlanInfo } = useDispatchOrganisationPlanData();

  if (isLoading) {
    return <Loader />;
  }
  return <OrganisationPlanPreviewTemplateSectionsContainer orgPlanData={organisationPlanInfo} />;
};

export default OrganisationPlanPreviewWidgetSection;
