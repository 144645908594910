import { Calendar24Regular } from '@fluentui/react-icons';
import { FormHelperText, Grid, InputLabel, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useCurrentDateFormat } from 'shared/hooks/regionalSettings';
import { IOfferDialogForm } from 'shared/types/offer';

const OfferModalRequestAndValidDate = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<IOfferDialogForm>();
  const dateFormat = useCurrentDateFormat();

  const handleChangeValidUntilDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('validUntil', value ? value.toISOString() : null);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel>
            <FormattedMessage id="offer.modal-field-offer-valid-until" />
          </InputLabel>
          <DatePicker
            value={values.validUntil ? new Date(values.validUntil.toString()) : null}
            format={dateFormat?.format}
            onChange={handleChangeValidUntilDate}
            slots={{ openPickerIcon: Calendar24Regular }}
          />
          {Boolean(touched['validUntil'] && errors['validUntil']) && (
            <FormHelperText error={Boolean(touched['validUntil'] && errors['validUntil'])}>{errors['validUntil']}</FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default OfferModalRequestAndValidDate;
