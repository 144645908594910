import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ILabelRowStyles {
  isHidden?: boolean;
}

interface IOriginalTextWrapperStyles {
  isQuestion?: boolean;
}

export const LabelRow = styled(Box)<ILabelRowStyles>(({ theme, isHidden }) => ({
  minHeight: '26px',
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: isHidden ? theme.palette.action.disabled : 'transparent',
  '& p': {
    color: theme.palette.text.primary
  },
  background: isHidden
    ? theme.palette.mode === 'dark'
      ? `repeating-linear-gradient(-45deg, #ffffff15, #ffffff15 10px, #ffffff07 10px, #ffffff07 20px)`
      : `repeating-linear-gradient(-45deg, #00000015, #00000015 10px, #00000007 10px, #00000007 20px)`
    : ''
}));

export const OriginalTextWrapper = styled(Box)<IOriginalTextWrapperStyles>(({ isQuestion = false }) => ({
  width: '100%',
  fontWeight: isQuestion ? 600 : 400
}));
