import { ArrowRoutingRectangleMultiple20Regular } from '@fluentui/react-icons';
import { Button } from '@mui/material';
import { OrganisationPlanInfoFromQuestionnaireWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanInfoFromQuestionnaireWidgetType';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'store';
import {
  setActiveInfoFromQuestionnaireSettings,
  setActiveInfoFromQuestionnaireSettingsSectionId
} from 'store/organisation-plan-template/organisation-plan-template.slice';

interface IInfoFromAnswerProps {
  element: OrganisationPlanInfoFromQuestionnaireWidgetType;
}

const OrganisationPlanInfoFromAnswer = ({ element }: IInfoFromAnswerProps) => {
  const dispatch = useDispatch();
  const { widgetId, sectionId } = element;

  const openInfoFromQuestionnaireSettings = () => {
    dispatch(setActiveInfoFromQuestionnaireSettings(widgetId));
    dispatch(setActiveInfoFromQuestionnaireSettingsSectionId(sectionId));
  };
  return (
    <Button
      sx={{ height: '100%' }}
      startIcon={<ArrowRoutingRectangleMultiple20Regular />}
      color="secondary"
      fullWidth
      onClick={openInfoFromQuestionnaireSettings}
    >
      <FormattedMessage id="organisation.add-info-from-questionnaire" />
    </Button>
  );
};

export default OrganisationPlanInfoFromAnswer;
