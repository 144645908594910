import { IQuestionnaire } from 'modules/questionnaires/types';
import { EventCreationStatusType, IContactPeople, IEventManager, IEventType } from './event';
import { ILanguage } from './language';
import { IOfferDetails } from './offer';
import { IVenue } from './venue';

export enum ERequestsQueryKeys {
  GetRequests = 'requests',
  GetSingleRequest = 'request'
}

export enum ERequestStatus {
  NewCreated = 'NewCreated',
  ReadyToSend = 'ReadyToSend',
  Clarification = 'Clarification',
  SentPerEmail = 'SentPerEmail',
  SentPerMail = 'SentPerMail',
  Accepted = 'Accepted',
  OnHold = 'OnHold',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  CreationFailed = 'CreationFailed'
}
export const RequestStatusPending = 'Pending';

const ROOT = 'requests';

export const API_REQUEST_ENDPOINTS = {
  CREATE_REQUEST: ROOT,
  ALL_REQUEST: ROOT,
  GET_REQUEST: (id?: number | string) => `${ROOT}/${id}`,
  UPDATE_REQUEST_STATE: (id: number) => `${ROOT}/${id}/state`,
  DELETE_REQUEST: (id: number) => `${ROOT}/${id}`
};

interface IOfferDetailsInsideRequestListItem extends Omit<IOfferDetails, 'articles' | 'appearance'> {}

export interface IOfferInsideRequest {
  id: number;
  requestId: number;
  eventId: number;
  offerNumber: string;
  state: ERequestStatus | typeof RequestStatusPending;
  offerDetails: IOfferDetailsInsideRequestListItem;
}

export interface IRequestListItem {
  id: number;
  name: string;
  anonymousLinkPrefix: string;
  requestManager: IEventManager;
  questionnaire: IQuestionnaire;
  contactPeople: IContactPeople[];
  eventType: IEventType;
  requestedEventDate: Date;
  venue: IVenue;
  offer?: IOfferInsideRequest;
  language?: ILanguage;
}

interface IRequestBase {
  name: string;
  state: ERequestStatus;
  isSentEmail?: boolean;
  eventType?: IEventType;
  questionnaire?: IQuestionnaire;
  venue?: IVenue;
  language?: ILanguage;
  requestedEventDate?: Date | null;
  deadlineDate?: Date | null;
  eventManager?: IEventManager;
  requestManager?: IEventManager;
  offer?: IOfferInsideRequest;
}

export interface IRequestDB extends IRequestBase {
  id: number;
  contactPeople?: IContactPeople[];
}
export interface IRequest extends IRequestBase {
  id?: number;
  contactPeople?: IContactPeople;
}

export interface IRequestModalFormToDB {
  id?: number;
  name: string;
  questionnaireId?: string | number;
  languageId?: ILanguage['id'];
  venueId?: IVenue['id'];
  eventType?: IEventType;
  contactPeopleIds?: IContactPeople['id'][];
  requestedEventDate?: Date;
  offerState: ERequestStatus;
  deadlineDate?: Date;
  eventManager?: IEventManager;
  requestManagerId?: IEventManager['id'];
}

export type RequestPostPayload = Omit<IRequestModalFormToDB, 'id'>;

export interface IRequestConvertToEvent {
  name: string;
  eventState: EventCreationStatusType;
  questionnaire?: IQuestionnaire;
  venue?: IVenue;
  eventType?: IEventType;
  eventDate: Date | null;
  deadlineDate: Date | null;
  eventManager?: IEventManager;
  contactPeople?: IContactPeople[];
  language?: ILanguage;
}

export interface IRequestConvertToEventPayload extends Pick<IRequestConvertToEvent, 'eventType' | 'eventDate'> {
  eventName: string;
  requestId: number;
  deadline: Date | null | string;
  venueId: number;
  eventManagerId?: number;
  languageId: number;
  contactPeopleIds: number[];
}
