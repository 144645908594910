import { AddSquare48Regular } from '@fluentui/react-icons';
import { Box, Typography } from '@mui/material';
import { DROPPING_ELEMENT } from 'modules/builder/constants';
import OrganisationPlanTemplateWidgetWrapper from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateWidgetWrapper';
import { GRID_LAYOUT_SIZE_ORGANISATION_PLAN } from 'modules/organisation-plan-templates/constants/organisationPlanTemplateBuilder';
import { ISideBarWidget } from 'modules/organisation-plan-templates/types';
import { useCallback } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'store';
import {
  addNewOrganisationPlanElement,
  updateOrganisationPlanBuilderLayout
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import * as Styles from './OrganisationPlanTemplatePlaygroundSection.styles';

interface OrganisationPlanTemplatePlaygroundSectionProps {
  itemWidget: ISideBarWidget | null;
  layout: Layout[];
  sectionId: string;
  isPaddingSection: boolean;
}

const OrganisationPlanTemplatePlaygroundSection: React.FC<OrganisationPlanTemplatePlaygroundSectionProps> = ({
  itemWidget,
  layout,
  sectionId,
  isPaddingSection
}) => {
  const dispatch = useDispatch();

  const getPlaceholderSize = () => {
    if (itemWidget) {
      return { i: DROPPING_ELEMENT, w: itemWidget.layout.w, h: itemWidget.layout.h };
    }
    return { i: DROPPING_ELEMENT, w: 10, h: 10 };
  };

  const handleDropElement = useCallback(
    (_layout: Layout[], layoutItem: Layout, _event: MouseEvent) => {
      if (!itemWidget) return;

      const newElement = {
        layout: {
          ...layoutItem,
          ...itemWidget.layout
        },
        widget: itemWidget.widget,
        sectionId
      };

      dispatch(addNewOrganisationPlanElement(newElement));
    },
    [dispatch, itemWidget, sectionId]
  );

  const updateLayout = useCallback(
    (newLayout: Layout[]) => {
      let finalLayout = newLayout.map((layoutItem) => ({ ...layoutItem }));

      const droppingItem = finalLayout.find((layout) => layout.i === DROPPING_ELEMENT);
      if (droppingItem && itemWidget?.layout?.h && itemWidget?.layout?.w) {
        finalLayout = finalLayout.map((el) => (el.i === DROPPING_ELEMENT ? { ...el, w: itemWidget.layout.w, h: itemWidget.layout.h } : el));
      }

      finalLayout = finalLayout.filter((el) => el.i !== DROPPING_ELEMENT);

      dispatch(updateOrganisationPlanBuilderLayout({ layout: finalLayout, sectionId }));
    },
    [dispatch, itemWidget?.layout?.h, itemWidget?.layout?.w, sectionId]
  );

  const layoutWithoutDroppingElement = layout.filter((el) => el.i !== DROPPING_ELEMENT);

  return (
    <Styles.BoxWrapper
      sx={{
        marginBottom: isPaddingSection ? '60px' : '0px',
        minHeight: layoutWithoutDroppingElement.length ? '0' : '20vh'
      }}
    >
      <GridLayout
        margin={[GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowGap, GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowGap]}
        containerPadding={[10, 10]}
        className="organisation-plan-layout"
        layout={layout}
        cols={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.col}
        rowHeight={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowHeight}
        width={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.widthGrid}
        onDrop={handleDropElement}
        isDroppable={true}
        compactType="vertical"
        onLayoutChange={updateLayout}
        isResizable
        resizeHandles={['e']}
        droppingItem={getPlaceholderSize()}
        draggableHandle=".on-draggable"
        onDrag={(_layout, _oldItem, _newItem, _placeholder, event: MouseEvent, _element) => event.preventDefault()}
      >
        {layout.map((item) => (
          <Box key={item.i}>
            <OrganisationPlanTemplateWidgetWrapper layoutItem={item} />
          </Box>
        ))}
      </GridLayout>
      {itemWidget === null && layout.length === 0 && (
        <Styles.BoxEmptyInfoContainer>
          <Styles.AddButtonIcon disabled>
            <AddSquare48Regular />
          </Styles.AddButtonIcon>
          <Typography variant="h4">
            <FormattedMessage id="organisation.page-is-empty" />
          </Typography>
        </Styles.BoxEmptyInfoContainer>
      )}
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanTemplatePlaygroundSection;
