import { Box } from '@mui/system';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';

interface IPreviewTextInputWidgetProps {
  element: OrganisationPlanTextWidgetType;
  layoutItem: Layout;
}

const PreviewTextInputWidget = ({ element, layoutItem }: IPreviewTextInputWidgetProps) => {
  const { content, sectionId, widgetId, isBottomDivider, isHighlighted } = element;
  const intl = useIntl();
  function createMarkup(text: string) {
    return { __html: text };
  }

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
    >
      {content ? <Box dangerouslySetInnerHTML={createMarkup(content)} /> : intl.formatMessage({ id: 'organisation.no-content-provided' })}
    </PreviewWidgetWrapper>
  );
};

export default PreviewTextInputWidget;
