import { useGetOrganisationPlanWidgetsForPreview } from 'api/hooks/organisation-plan-preview/useGetOrganisationPlanPreviewWidgets';
import { isOrganisationPlanWidgetOnly } from 'modules/organisation-plan-templates/utils/isOrganisationPlanWidgetOnly';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'store';
import { setOrganisationPlanStructure } from 'store/organisation-plan-template/organisation-plan-template.slice';

const useDispatchOrganisationPlanData = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { isLoading: organisationPlanWidgetsLoading, data: organisationPlanWidgetsData } = useGetOrganisationPlanWidgetsForPreview(
    token ? token : undefined
  );

  useEffect(() => {
    const sections = organisationPlanWidgetsData?.builder?.sections || [];
    const links = organisationPlanWidgetsData?.builder?.links || [];

    const widgets = (organisationPlanWidgetsData?.builder?.items || []).map((w) => {
      if (isOrganisationPlanWidgetOnly(w.type)) {
        return {
          ...w,
          widgetId: w.widgetKey
        };
      }

      return {
        ...w.widget?.layout,
        ...w.alternativeLabels,
        ...w.displaySettings,
        widgetKey: w.widgetKey,
        showOnlySelectedAnswer: w.showOnlySelectedAnswer,
        linkedWidgetId: w.widget?.widgetId,
        widgetId: w.widgetKey,
        articles: w.widget?.articles
      };
    });

    dispatch(setOrganisationPlanStructure({ sections, links, usedWidgets: widgets }));
  }, [organisationPlanWidgetsData, dispatch]);

  return {
    isLoading: organisationPlanWidgetsLoading,
    organisationPlanInfo: organisationPlanWidgetsData
  };
};

export default useDispatchOrganisationPlanData;
