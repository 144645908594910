import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ImageChoiceWidgetBox = styled(Box)(() => ({
  marginTop: '10px',

  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(205px, 1fr))',
  gap: '10px 25px',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}));

export const ShadowBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  background: `repeating-linear-gradient(-45deg, #00000030, #00000030 10px, #00000015 10px, #00000015 20px)`,
  position: 'absolute',
  borderRadius: '6px',
  zIndex: 1
}));
