import { ContentState, EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

/**
 * Method to get text from html tags (DraftTextEditor)
 * @param label - label of widget
 */
export const extractContentFromHtml = (label: string) => {
  let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  const isHtmlTags = regexForHTML.test(label);
  if (!isHtmlTags) return label;
  const html = htmlToDraft(label);
  const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap));
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  return blocks.map((block) => block.text).join(' ');
};
