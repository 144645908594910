import { useEditOffer } from 'api/hooks/offers/useEditOffer';
import { useGetOfferById } from 'api/hooks/offers/useGetOfferById';
import { useGetRequestById } from 'api/hooks/requests/useGetRequestById';
import OfferFormModal from 'modules/offers/modals/OfferFormModal';
import { prepareOfferDataForSave } from 'modules/offers/utils/prepareData';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { IOfferDialogForm, IOfferForSave } from 'shared/types/offer';
import { textError } from 'shared/utils/infoText';
import { selectPaginationEvents, selectPaginationRequests } from 'store/pagination/pagination.selectors';
import { OFFERS_ROUTES } from '../paths';

interface IModalEditOffer {
  offerId: number;
  requestId?: number;
  isOpenModal: boolean;
  hasEvent?: boolean;
  hasRequest?: boolean;
  eventDate?: Date;
  closeModal: () => void;
  onRefetch?: () => void;
  isCreating?: boolean;
}

const ModalEditOffer = ({ offerId, requestId, isOpenModal, closeModal, hasEvent, hasRequest, isCreating, eventDate }: IModalEditOffer) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { successMessage, errorMessage } = useInfoMessage();
  const paginationRequests = useSelector(selectPaginationRequests);
  const paginationEvents = useSelector(selectPaginationEvents);

  const { isLoading: isLoadingGetOffer, data: offerById, refetch: refetchOffer } = useGetOfferById({ offerId: offerId });
  const { data: request, refetch: refetchRequest } = useGetRequestById({ id: requestId?.toString() ?? offerById?.request?.id?.toString() });
  const { mutateAsync: mutateEditOffer, isLoading: isLoadingEdit } = useEditOffer({
    id: offerId,
    paginationRequests,
    paginationEvents,
    options: {
      onError(error) {
        errorMessage(textError(error), closeModal);
      }
    }
  });

  const handleEditSubmit = useCallback(
    async (data: IOfferDialogForm) => {
      if (!data?.id) {
        return;
      }
      if (!request) {
        console.log('Request not found and therefor not updated in database');
      }
      const dataForSave: IOfferForSave = prepareOfferDataForSave(data, request);
      return await mutateEditOffer(dataForSave, {
        onSuccess: (response) => {
          refetchRequest();
          refetchOffer();
          successMessage(intl.formatMessage({ id: 'requests.offer-updated-successfully' }), closeModal);
          isCreating && navigate(OFFERS_ROUTES.GET_LINK_BY_OFFER_ID(response.id?.toString() || ''));
        }
      });
    },
    [successMessage, closeModal, intl, mutateEditOffer]
  );

  return (
    <OfferFormModal
      offer={offerById}
      isOpenModal={isOpenModal}
      isSaving={isLoadingEdit || isLoadingGetOffer}
      onSubmit={handleEditSubmit}
      closeModal={closeModal}
      hasEvent={hasEvent}
      hasRequest={hasRequest}
      eventDate={eventDate}
    />
  );
};

export default ModalEditOffer;
