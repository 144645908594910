import 'cropperjs/dist/cropper.css';
import OrganisationPlanImageChoiceOption from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanImageChoiceOption';
import TemplateWidgetWrapper from 'modules/organisation-plan-templates/components/widgets/Shared/TemplateWidgetWrapper';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface IOrganisationPlanImageChoiceWidgetProps {
  widget: SingleImageChoiceWithReplacementWidgetType | MultipleImageChoiceWithReplacementWidgetType;
  isSingleChoice: boolean;
  layoutItem: Layout;
}

const OrganisationPlanImageChoiceWidget = ({ widget, isSingleChoice, layoutItem }: IOrganisationPlanImageChoiceWidgetProps) => {
  const dispatch = useDispatch();
  const {
    widgetId,
    dataImage,
    typeImage,
    type,
    label,
    sectionId,
    replacementLabel,
    replacementOptionsLabels,
    isMainLabelHidden,
    isHighlighted,
    isBottomDivider
  } = widget;
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);
  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as SingleImageChoiceWithReplacementWidgetType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as SingleImageChoiceWithReplacementWidgetType['type'], isMainLabelHidden }
      })
    );
  };

  return (
    <TemplateWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      dependencies={[isHeadingOpen, replacementOptionsLabels]}
      hasPadding
    >
      <ReplacementTextHeading
        isQuestion
        onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
        value={replacementLabel}
        content={label}
        isLabelHiddenValue={isMainLabelHidden}
        onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
        onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
        isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
      />
      <OrganisationPlanImageChoiceOption
        typeImage={typeImage}
        id={widgetId}
        data={dataImage}
        multiple={false}
        isSingleChoice={isSingleChoice}
        typeWidget={type}
        replacementOptionsLabels={replacementOptionsLabels}
      />
    </TemplateWidgetWrapper>
  );
};

export default OrganisationPlanImageChoiceWidget;
