import { Checkmark20Regular, ChevronRight20Regular } from '@fluentui/react-icons';
import { Box, ClickAwayListener, ListItemText, Popper, useTheme } from '@mui/material';
import FeatureRoleGuard from 'modules/auth/components/FeatureRoleGuard';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from 'shared/components/ContextMenu/ContextMenu.styles';
import { IListOptionContextMenu } from 'shared/components/ContextMenu/index';

interface IOptionOfMenu {
  option: IListOptionContextMenu;
  setIsCloseMenu: (data: boolean) => void;
  isIcons?: boolean;
}
const OptionOfMenu = ({ option, isIcons, setIsCloseMenu }: IOptionOfMenu) => {
  const intl = useIntl();
  const theme = useTheme();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);
  const timeoutRef = useRef<number | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>, isSubmenu: boolean) => {
    if (option.submenu) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      !isSubmenu && setSubmenuAnchorEl(event.currentTarget);
      setSubmenuOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (option.submenu) {
      timeoutRef.current = window.setTimeout(() => {
        setSubmenuOpen(false);
        timeoutRef.current = null;
      }, 100);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleCloseSubmenu = () => {
    setSubmenuOpen(false);
  };

  const handleOptionClick = (optionOnClick: () => void, isCloseMenu?: boolean) => {
    optionOnClick();
    if (option.switchValue !== undefined || option.submenu !== undefined) {
      return;
    }
    if (typeof isCloseMenu === 'undefined' || isCloseMenu) {
      setIsCloseMenu(true);
    }
  };

  return (
    <>
      <Styles.BoxMenuItem
        disabled={option.disabledMenu ?? false}
        isBorderTop={option.isBorderTop}
        isBorderBottom={option.isBorderBottom}
        onMouseEnter={(e) => handleMouseEnter(e, false)}
        onMouseLeave={handleMouseLeave}
        onClick={() => option.onClick && handleOptionClick(option.onClick, option.isCloseMenu)}
      >
        {!!option.icon && (
          <Box justifyContent="center" alignItems="center" display="flex" className={option.className}>
            {option.icon}
          </Box>
        )}
        <ListItemText inset={isIcons && !option.icon} className={option.className} primary={intl.formatMessage({ id: option.messageId })} />
        <Checkmark20Regular
          style={{ color: theme.palette.primary.main, opacity: option.switchValue !== undefined && option.switchValue ? 1 : 0 }}
        />
        {option.submenu && <ChevronRight20Regular style={{ color: theme.palette.secondary.main }} />}
      </Styles.BoxMenuItem>
      {option.submenu && (
        <ClickAwayListener
          onClickAway={() => {
            handleCloseSubmenu();
            setIsCloseMenu(true);
          }}
        >
          <Popper
            open={submenuOpen}
            anchorEl={submenuAnchorEl}
            placement={'auto-end'}
            style={{ zIndex: 1300 }}
            onMouseEnter={(e) => handleMouseEnter(e, true)}
            onMouseLeave={handleMouseLeave}
          >
            <Box
              sx={{
                borderRadius: '6px',
                bgcolor: 'background.paper',
                boxShadow: ' rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px inset'
              }}
            >
              {option.submenu
                .filter((subOption) => !subOption.isHidden)
                .map((subOption, index) => {
                  if (!!subOption?.roles) {
                    return (
                      <FeatureRoleGuard key={index} roles={subOption.roles}>
                        <OptionOfMenu isIcons option={subOption} setIsCloseMenu={setIsCloseMenu} />
                      </FeatureRoleGuard>
                    );
                  }
                  return <OptionOfMenu isIcons key={index} option={subOption} setIsCloseMenu={setIsCloseMenu} />;
                })}
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default OptionOfMenu;
