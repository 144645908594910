import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(({ theme }) => ({
  boxShadow: theme.palette.mode === 'dark' ? `0 0 0  1px  #ffffff20` : `0 0 0  1px  #00000020`,
  borderRadius: '10px',
  backgroundColor: theme.palette.background.paper,
  height: 'auto',
  padding: '10px 0'
}));

export const BoxOptionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  p: {
    fontWeight: 400,
    fontSize: '16px',
    color: theme.palette.text.primary
  }
}));
