import axios from 'axios';
import { useQuery } from 'react-query';

export interface UserPreference {
  id: number;
  userId: number;
  name: string;
  value: string[];
}

/**
 * Fetches user preferences from the backend.
 * @function
 * @returns {Promise<UserPreference[]>} A promise that resolves with an array of user preferences.
 */
const fetchUserPreferences = (): Promise<UserPreference[]> => {
  return axios.get('/user-preferences').then((res) => {
    return res.data;
  });
};

/**
 * Custom hook that fetches user preferences.
 * @function
 * @returns {UserPreference[]} The result of the query, including the user preferences.
 */
export const useGetUserPreferences = () => {
  const queryKey = ['user-preferences'];
  return useQuery(queryKey, () => fetchUserPreferences(), { refetchOnMount: false });
};
