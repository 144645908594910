import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  flexGrow: 1,
  overflowY: 'scroll'
}));

export const ChildrenBoxContainer = styled(Box)(() => ({
  marginTop: '10px'
}));
