import { Grid, InputBase, InputLabel, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import OfferStatusSelect from 'modules/offers/containers/OfferStatusSelect';
import { FormattedMessage } from 'react-intl';
import { IOfferDialogForm } from 'shared/types/offer';

const OfferModalNumberAndState = () => {
  const { values, handleChange } = useFormikContext<IOfferDialogForm>();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25} height="100%">
          <InputLabel htmlFor="offer-number">
            <FormattedMessage id="offer.modal-field-serial-number" />
          </InputLabel>

          <InputBase value={values.offerNumber} sx={{ pointerEvents: 'none', fontWeight: '600', height: '36px' }} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="state">
            <FormattedMessage id="invoices.modal-field-state" />
          </InputLabel>
          <OfferStatusSelect value={values.state} handleChange={handleChange} />
        </Stack>
      </Grid>
    </>
  );
};

export default OfferModalNumberAndState;
