/// <reference types="vite-plugin-svgr/client" />
import { ArrowMaximizeVertical20Regular } from '@fluentui/react-icons';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';
import * as Styles from './OrganisationPlanDividerWidget.styles';

interface IOrganisationPlanDividerWidgetProps {
  element: OrganisationPlanDividerWidgetType;
  isPreview: boolean;
}

const OrganisationPlanDividerWidget = ({ isPreview }: IOrganisationPlanDividerWidgetProps) => {
  return (
    <Styles.BoxWrapper isPreview={isPreview}>
      <ArrowMaximizeVertical20Regular />
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanDividerWidget;
