import { Button, Divider, Stack } from '@mui/material';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import DynamicFields from 'modules/builder/components/SettingsBar/LinkingTab/DynamicFields';
import { ILinkingPageNav } from 'modules/builder/components/SettingsBar/LinkingTab/types';
import { transformPageForSelect } from 'modules/builder/components/SettingsBar/LinkingTab/utils';
import OrganisationPlanTabPanel from 'modules/organisation-plan-templates/components/OrganisationPlanTabPanel';
import { filterOrgPlanLinksByTarget } from 'modules/organisation-plan-templates/utils/filterLinks';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { LinkingAction } from 'shared/types/questioner-template-link';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'store';
import { selectAllOrgPlanLinks } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  addOrgPlanLink,
  removeOrgPlanLink,
  updateActiveLinkingSettings,
  updateOrgPlanLink
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { ActiveLinkingSettingsType } from 'store/organisation-plan-template/organization-plan-template.types';
import { WidgetBarTypography } from '../WidgetsBar/WidgetBar.styles';
import { IOrgPlanLinkObjectForSelect } from './types';

const INIT_ORG_PLAN_LINK: Omit<IOrgPlanLinkObjectForSelect, 'linkId'> = {
  action: LinkingAction.hide,
  source: { pageId: undefined, subPageId: null, widgetId: undefined },
  target: {}
};

interface IOrganisationPlanTemplateLinkingTabProps {
  target: ActiveLinkingSettingsType;
}

const OrganisationPlanTemplateLinkingTab = ({ target }: IOrganisationPlanTemplateLinkingTabProps) => {
  const [answerOptions, setAnswerOptions] = useState<ILinkingPageNav[]>([]);

  const dispatch = useDispatch();
  const { questionnaireTemplateId } = useParams();

  const { data: builderPages } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });

  const allLinks = useSelector(selectAllOrgPlanLinks);
  const links = filterOrgPlanLinksByTarget(target, allLinks);

  useEffect(() => {
    if (!builderPages) return;

    const options = transformPageForSelect(builderPages, null, undefined, []);
    setAnswerOptions(options);
  }, [builderPages]);

  const closeSettings = () => {
    dispatch(updateActiveLinkingSettings(null));
  };

  const addNewLinkHandler = useCallback(() => {
    dispatch(addOrgPlanLink({ ...INIT_ORG_PLAN_LINK, target }));
  }, [dispatch, target]);

  useLayoutEffect(() => {
    if (!links.length) {
      addNewLinkHandler();
    }
  }, [links.length, addNewLinkHandler]);

  const removeLinkHanlder = (linkId: IOrgPlanLinkObjectForSelect['linkId']) => {
    dispatch(removeOrgPlanLink(linkId));
  };

  const updateLinkHandler = ({
    link,
    linkId
  }: {
    link: Partial<IOrgPlanLinkObjectForSelect>;
    linkId: IOrgPlanLinkObjectForSelect['linkId'];
  }) => {
    dispatch(updateOrgPlanLink({ linkId, link }));
  };
  return (
    <Stack>
      <WidgetBarTypography>
        <FormattedMessage id="builder.linking" />
      </WidgetBarTypography>
      <Divider sx={{ borderWidth: '3px' }} />

      <OrganisationPlanTabPanel
        value={0}
        activeValue={0}
        role="linking-tab-panel"
        id={`linking-tab-panel`}
        aria-labelledby={`linking-tab-section`}
      >
        <Stack p="10px 5px 10px 10px" justifyContent="space-between">
          <SimpleBar style={{ height: 'calc(100vh - 200px)', paddingBottom: '10px' }}>
            <DynamicFields
              links={links}
              options={answerOptions}
              addNewLinkHandler={addNewLinkHandler}
              removeLinkHanlder={removeLinkHanlder}
              updateLinkHandler={updateLinkHandler}
            />
          </SimpleBar>

          <Button variant="outlined" fullWidth onClick={closeSettings}>
            <FormattedMessage id="builder.close" />
          </Button>
        </Stack>
      </OrganisationPlanTabPanel>
    </Stack>
  );
};

export default OrganisationPlanTemplateLinkingTab;
