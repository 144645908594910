import { Box, TableContainer } from '@mui/material';
import { PreviewLabel } from 'modules/organisation-plan-preview/components/Shared/PreviewLabel';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import OrganisationPlanTableEditableCell from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanTableWithReplacementWidget/OrganisationPlanTableEditableCell';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import { Layout } from 'react-grid-layout';
import * as Styles from './PreviewTableWidget.styles';

interface IPreviewTableWidgetProps {
  element: OrganisationPlanTableWithReplacementWidgetType;
  layoutItem: Layout;
}

const PreviewTableWidget = ({ element, layoutItem }: IPreviewTableWidgetProps) => {
  const {
    _activeRow,
    label,
    replacementLabel,
    dataTitles,
    dataContent,
    isMainLabelHidden,
    widgetId,
    sectionId,
    columnSettings,
    rowSettings,
    isBottomDivider,
    isHighlighted
  } = element;
  const isRowSettingsAvailable = typeof _activeRow === 'number';

  const hasRowWithContent = dataContent?.some((row) => {
    return row && Object.values(row.data || {}).some((cell) => !!cell.value);
  });
  if (!hasRowWithContent) return null;

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={renderInputLabel(replacementLabel, label)}
      isQuestionLabelHidden={!!isMainLabelHidden}
    >
      <Styles.BoxTableContainerWrapper>
        <TableContainer>
          <Styles.TableWrapper>
            <Styles.TableHeadWrapper>
              <Styles.TableHeadRowWrapper>
                {dataTitles &&
                  dataTitles.length &&
                  dataTitles.map((title, key: number) => (
                    <Styles.TableHeadCellWrapper key={`th_${key}`}>
                      <PreviewLabel isBolder type={TypographyType.body} label={title.value} />
                    </Styles.TableHeadCellWrapper>
                  ))}
              </Styles.TableHeadRowWrapper>
            </Styles.TableHeadWrapper>
            <Styles.TableBodyWrapper>
              {dataContent &&
                dataContent.length &&
                dataContent.map((row, key: number) => {
                  const rowHasContent = row && Object.values(row.data || {}).some((cell) => !!cell.value);
                  if (!rowHasContent) return null;
                  return (
                    <Styles.TableBodyRowWrapper key={`row_${key}`}>
                      {row &&
                        Object.values(row.data).map((value, key: number) => (
                          <td key={`row_th_${key}`}>
                            <Box position="relative">
                              <OrganisationPlanTableEditableCell
                                value={value.value}
                                row={+row.rowId}
                                column={+value.columnId}
                                settings={columnSettings.find((settings) => settings.columnId === value.columnId)}
                                settingsRow={rowSettings.find((settings) => settings.rowId === row.rowId)}
                                isRowSettingsAvailable={isRowSettingsAvailable}
                              />
                            </Box>
                          </td>
                        ))}
                    </Styles.TableBodyRowWrapper>
                  );
                })}
            </Styles.TableBodyWrapper>
          </Styles.TableWrapper>
        </TableContainer>
      </Styles.BoxTableContainerWrapper>
    </PreviewWidgetWrapper>
  );
};

export default PreviewTableWidget;
