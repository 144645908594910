import {
  ArrowRoutingRectangleMultiple20Regular,
  ColumnTriple20Regular,
  Delete20Regular,
  Edit20Regular,
  FlashAuto20Regular,
  LineStyle20Regular,
  LinkSquare20Regular,
  RowTriple20Regular,
  StarEmphasis20Regular,
  TableStackBelow20Regular,
  TagDismiss20Regular,
  TaskListLtr20Regular,
  TextBulletList20Regular,
  TextNumberListLtr20Regular
} from '@fluentui/react-icons';
import { Box, Card, Popper, useTheme } from '@mui/material';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import DragHandle from 'modules/builder/components/WidgetWrapper/DragHandle';
import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import OrganisationPlanDividerWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanDividerWidget';
import OrganisationPlanImageChoiceWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget';
import OrganisationPlanInfoFromAnswer from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInfoFromAnswer';
import OrganisationPlanInputNumberWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputNumberWithReplacementWidget';
import OrganisationPlanTextWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputTextWidget';
import OrganisationPlanInputTextWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputTextWithReplacementWidget';
import OrganisationPlanNotesWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanNotesWidget';
import OrganisationPlanTableWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanTableWithReplacementWidget';
import OrganisationPlanTimePickerWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanTimePickerWithReplacementWidget';
import OrganisationPlanUploadAreaWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanUploadAreaWithReplacementWidget';
import { DisplayMode, ListingOption, ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import {
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { OrganisationPlanInfoFromQuestionnaireWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanInfoFromQuestionnaireWidgetType';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';
import { OrganisationPlanInputTextWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputTextWithReplacementType';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { useParams } from 'react-router-dom';
import ContextMenu, { IListOptionContextMenu } from 'shared/components/ContextMenu';
import { IOrganisationPlanPreviewWidgetDisplaySettings } from 'shared/types/organisation-plan-preview';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { useDispatch, useSelector } from 'store';
import {
  organizationPlanTemplateBuilderActiveLinkingSettings,
  selectAllOrgPlanLinks,
  selectOrganizationPlanWidgetById
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  deleteOrganisationPlanWidget,
  removeOrgPlanLinks,
  setEditWidgetSettings,
  updateActiveLinkingSettings,
  updateOrganisationPlanWidget
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { WidgetWrapperBox } from '../OrganisationPlanTemplatePlaygroundSection/OrganisationPlanTemplatePlaygroundSection.styles';
import OrganisationPlanChoiceWidget from '../widgets/OrganisationPlanChoiceWidget';

interface IOrganisationPlanWidgetWrapperProps {
  layoutItem: Layout;
}

const OrganisationPlanTemplateWidgetWrapper: React.FC<IOrganisationPlanWidgetWrapperProps> = ({ layoutItem }) => {
  const [isTextEditorOpen, setIsTextEditorOpen] = React.useState(false);
  const [isWidgetFocused, setIsWidgetFocused] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { questionnaireTemplateId } = useParams();

  const activeLinkingSettings = useSelector(organizationPlanTemplateBuilderActiveLinkingSettings);
  const element = useSelector((state) => selectOrganizationPlanWidgetById(state, layoutItem.i));
  const links: IOrgPlanLinkObjectForSelect[] = useSelector(selectAllOrgPlanLinks);

  const { data: builderPages = [] } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });

  const handleEditText = () => {
    setIsTextEditorOpen(!isTextEditorOpen);
  };

  const handleDoubleClick = (element: ORGANISATION_PLAN_WIDGET_TYPES) => {
    if (element === ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText) {
      handleEditText();
    }
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setIsWidgetFocused(true);
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setIsWidgetFocused(false);
      setAnchorEl(null);
    }
  };

  const editWidgetElement = () => {
    if (!element) return;
    let chosenPage = '';
    builderPages.forEach((builderPage) => {
      if (builderPage.hasOwnProperty('usedWidgets')) {
        const foundWidgetById = builderPage.usedWidgets?.find((widget) => widget.widgetId === element.linkedWidgetId);
        if (foundWidgetById) {
          chosenPage = `${builderPage.name}with_page_id${builderPage.id}`;
        } else {
          if (builderPage.hasOwnProperty('childPages')) {
            builderPage.childPages?.forEach((builderSubPage) => {
              if (builderSubPage.hasOwnProperty('usedWidgets')) {
                const foundWidgetById = builderSubPage.usedWidgets?.find((widget) => widget.widgetId === element.linkedWidgetId);
                if (foundWidgetById) {
                  chosenPage = `${builderSubPage.name}with_page_id${builderSubPage.id}`;
                }
              }
            });
          }
        }
      }
    });

    dispatch(
      setEditWidgetSettings({
        pageOfChosenWidget: chosenPage,
        chosenWidget: element
      })
    );
  };

  const deleteWidgetElement = () => {
    if (!element) return;
    dispatch(deleteOrganisationPlanWidget({ widgetId: element.widgetId, sectionId: element.sectionId }));
    const linksForThisWidget = links.filter((link) => link?.target?.widgetId === element.widgetId);
    if (linksForThisWidget.length) {
      dispatch(removeOrgPlanLinks(links.map(({ linkId }) => linkId)));
    }
    if (element.widgetId === activeLinkingSettings?.widgetId) {
      dispatch(updateActiveLinkingSettings(null));
    }
  };

  const openWidgetLinkSettingsSection = () => {
    if (!element) return;

    dispatch(updateActiveLinkingSettings({ sectionId: null, widgetId: element.widgetId }));
  };
  const displaySettingsHandler = (option: Partial<IOrganisationPlanPreviewWidgetDisplaySettings>) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: element.widgetId,
        widget: { type: element.type, ...option }
      })
    );
  };

  if (!element) {
    return null;
  }

  const widgetComponentByType: Record<ORGANISATION_PLAN_WIDGET_TYPES, ReactNode> = {
    [ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText]: (
      <OrganisationPlanTextWidget
        isTextEditorOpen={isTextEditorOpen}
        onHandleTextEditorClose={handleEditText}
        onHandleTextEditorOpen={handleEditText}
        layoutItem={layoutItem}
        element={element as OrganisationPlanTextWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.notes]: (
      <OrganisationPlanNotesWidget layoutItem={layoutItem} element={element as OrganisationPlanNotesWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire]: (
      <OrganisationPlanInfoFromAnswer element={element as OrganisationPlanInfoFromQuestionnaireWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.divider]: (
      <OrganisationPlanDividerWidget isPreview={false} element={element as OrganisationPlanDividerWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.inputText]: (
      <OrganisationPlanInputTextWithReplacementWidget
        layoutItem={layoutItem}
        element={element as OrganisationPlanInputTextWithReplacementType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.inputInteger]: (
      <OrganisationPlanInputNumberWithReplacementWidget
        layoutItem={layoutItem}
        element={element as OrganisationPlanInputNumberWithReplacementType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.time]: (
      <OrganisationPlanTimePickerWithReplacementWidget
        layoutItem={layoutItem}
        element={element as OrganisationPlanTimePickerWithReplacementType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.singleChoice]: (
      <OrganisationPlanChoiceWidget layoutItem={layoutItem} element={element as SingleChoiceOrganisationPlanWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice]: (
      <OrganisationPlanChoiceWidget layoutItem={layoutItem} element={element as MultipleChoiceOrganisationPlanWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice]: (
      <OrganisationPlanImageChoiceWidget
        layoutItem={layoutItem}
        isSingleChoice={true}
        widget={element as SingleImageChoiceWithReplacementWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice]: (
      <OrganisationPlanImageChoiceWidget
        layoutItem={layoutItem}
        isSingleChoice={false}
        widget={element as MultipleImageChoiceWithReplacementWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.table]: (
      <OrganisationPlanTableWithReplacementWidget
        layoutItem={layoutItem}
        widget={element as OrganisationPlanTableWithReplacementWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.uploadArea]: (
      <OrganisationPlanUploadAreaWithReplacementWidget
        layoutItem={layoutItem}
        widget={element as OrganisationPlanUploadAreaWithReplacementType}
      />
    )
  };

  const hasUnitLabel = (type: ORGANISATION_PLAN_WIDGET_TYPES) =>
    type === ORGANISATION_PLAN_WIDGET_TYPES.inputInteger ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice;

  const hasDisplayMode = (type: ORGANISATION_PLAN_WIDGET_TYPES) =>
    type === ORGANISATION_PLAN_WIDGET_TYPES.inputInteger ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.time ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.uploadArea ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.inputText;

  const hasListingOption = (type: ORGANISATION_PLAN_WIDGET_TYPES) =>
    type === ORGANISATION_PLAN_WIDGET_TYPES.inputInteger ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.inputText ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.time ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.uploadArea;

  const isWidgetEditable = (type: ORGANISATION_PLAN_WIDGET_TYPES) => {
    return !(
      type === ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.notes ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.divider
    );
  };

  const isHelperWidget = (type: ORGANISATION_PLAN_WIDGET_TYPES) => {
    return type === ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire || type === ORGANISATION_PLAN_WIDGET_TYPES.divider;
  };

  const isNoListingOption = (type: ORGANISATION_PLAN_WIDGET_TYPES) => {
    return (
      type === ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.divider ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.notes ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText
    );
  };

  const submenuOptions = useMemo<IListOptionContextMenu[]>(
    () => [
      {
        messageId: 'organization.listing-none',
        onClick: () => displaySettingsHandler({ listingOption: 'listingOption' in element ? ListingOption.none : ListingOption.none }),
        switchValue: 'listingOption' in element && element.listingOption === ListingOption.none,
        isHidden: !hasListingOption(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.listing-bullet',
        onClick: () => displaySettingsHandler({ listingOption: 'listingOption' in element ? ListingOption.bullet : ListingOption.none }),
        icon: <TextBulletList20Regular />,
        switchValue: 'listingOption' in element && element.listingOption === ListingOption.bullet,
        isHidden: !hasListingOption(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.listing-checkbox',
        onClick: () => displaySettingsHandler({ listingOption: 'listingOption' in element ? ListingOption.checkbox : ListingOption.none }),
        disabled: false,
        switchValue: 'listingOption' in element && element.listingOption === ListingOption.checkbox,
        icon: <TaskListLtr20Regular />,
        isHidden: !hasListingOption(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.listing-numeric',
        onClick: () => displaySettingsHandler({ listingOption: 'listingOption' in element ? ListingOption.numeric : ListingOption.none }),
        disabled: false,
        switchValue: 'listingOption' in element && element.listingOption === ListingOption.numeric,
        icon: <TextNumberListLtr20Regular />,
        isHidden: !hasListingOption(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.auto-mode',
        onClick: () => displaySettingsHandler({ displayMode: 'displayMode' in element ? DisplayMode.auto : DisplayMode.auto }),
        disabled: false,
        switchValue: 'displayMode' in element && element.displayMode === DisplayMode.auto,
        icon: <FlashAuto20Regular />,
        isBorderTop: true,
        isHidden: !hasDisplayMode(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.column-mode',
        onClick: () => displaySettingsHandler({ displayMode: 'displayMode' in element ? DisplayMode.column : DisplayMode.row }),
        disabled: false,
        switchValue: 'displayMode' in element && element.displayMode === DisplayMode.column,
        icon: <RowTriple20Regular />,
        isHidden: !hasDisplayMode(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.row-mode',
        onClick: () => displaySettingsHandler({ displayMode: 'displayMode' in element ? DisplayMode.row : DisplayMode.row }),
        disabled: false,
        switchValue: 'displayMode' in element && element.displayMode === DisplayMode.row,
        icon: <ColumnTriple20Regular />,
        isHidden: !hasDisplayMode(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.inline-mode',
        onClick: () => displaySettingsHandler({ displayMode: 'displayMode' in element ? DisplayMode.inline : DisplayMode.row }),
        disabled: false,
        switchValue: 'displayMode' in element && element.displayMode === DisplayMode.inline,
        icon: <LineStyle20Regular />,
        isHidden: !hasDisplayMode(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      }
    ],
    [element]
  );

  const listOptions = useMemo<IListOptionContextMenu[]>(
    () => [
      {
        messageId: 'organisation.edit-you-text',
        disabled: false,
        isQuickAction: true,
        onClick: handleEditText,
        icon: <Edit20Regular />,

        isHidden: (element.type as ORGANISATION_PLAN_WIDGET_TYPES) !== ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText
      },
      {
        messageId: 'organization.change-widget-source',
        onClick: editWidgetElement,
        isHidden: !isWidgetEditable(element.type as ORGANISATION_PLAN_WIDGET_TYPES),
        isQuickAction: true,
        icon: <ArrowRoutingRectangleMultiple20Regular />
      },
      {
        messageId: 'organisation.settings-link',
        onClick: openWidgetLinkSettingsSection,
        icon: <LinkSquare20Regular />,
        isQuickAction: true,
        isHidden: isHelperWidget(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.bottom-divider',
        onClick: () => displaySettingsHandler({ isBottomDivider: !element?.isBottomDivider }),
        disabled: false,
        switchValue: element?.isBottomDivider,
        icon: <TableStackBelow20Regular />,
        isBorderTop: true,
        isQuickAction: true,
        isHidden: isHelperWidget(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.highlight',
        onClick: () => displaySettingsHandler({ isHighlighted: !element?.isHighlighted }),
        disabled: false,
        switchValue: element?.isHighlighted,
        icon: <StarEmphasis20Regular />,
        isQuickAction: true,
        isHidden: isHelperWidget(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.hide-unit-label',
        onClick: () => displaySettingsHandler({ isUnitLabelHidden: 'isUnitLabelHidden' in element ? !element?.isUnitLabelHidden : false }),
        disabled: false,
        switchValue: 'isUnitLabelHidden' in element ? element?.isUnitLabelHidden : false,
        icon: <TagDismiss20Regular />,
        isHidden: !hasUnitLabel(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organization.listing-options',
        disabled: false,
        submenu: submenuOptions,
        isHidden: isNoListingOption(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },

      {
        messageId: 'users.delete',
        onClick: deleteWidgetElement,
        isQuickAction: true,
        isBorderTop: isHelperWidget(element.type as ORGANISATION_PLAN_WIDGET_TYPES) ? false : true,
        icon: <Delete20Regular />
      }
    ],
    [element]
  );
  const menuComp = () => (
    <Popper
      className="menu-comp"
      sx={{ zIndex: 99 }}
      open={isWidgetFocused}
      anchorEl={anchorEl}
      placement="top-end"
      onMouseEnter={() => setIsWidgetFocused(true)}
    >
      <Card
        sx={{
          margin: '-2px',
          borderRadius: '6px',
          boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
          borderColor: theme.palette.primary.light,
          marginRight: '10px'
        }}
      >
        <ContextMenu isWidget={true} listOptions={listOptions} />
      </Card>
    </Popper>
  );

  return (
    <Box
      height="100%"
      id="widgetWrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDoubleClick={() => handleDoubleClick(element.type as ORGANISATION_PLAN_WIDGET_TYPES)}
    >
      <ContextMenu isRightClick noIcon listOptions={listOptions} sxIconButton={{ marginRight: '10px' }}>
        <DragHandle isDraggable={true} />
        <WidgetWrapperBox height="100%" className={isWidgetFocused ? 'widget-focused' : ''}>
          {widgetComponentByType[element.type] || null}
        </WidgetWrapperBox>
      </ContextMenu>
      {menuComp()}
    </Box>
  );
};

export default React.memo(OrganisationPlanTemplateWidgetWrapper);
