import TemplateWidgetWrapper from 'modules/organisation-plan-templates/components/widgets/Shared/TemplateWidgetWrapper';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import { useState } from 'react';
import { Layout } from 'react-grid-layout';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface ITimePickerWithReplacementWidgetProps {
  element: OrganisationPlanTimePickerWithReplacementType;
  layoutItem: Layout;
}

const OrganisationPlanTimePickerWithReplacementWidget = ({ element, layoutItem }: ITimePickerWithReplacementWidgetProps) => {
  const dispatch = useDispatch();
  const { widgetId, type, replacementLabel, isMainLabelHidden, sectionId, isBottomDivider, isHighlighted, listingOption } = element;
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);

  const replaceLabel = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanTimePickerWithReplacementType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanTimePickerWithReplacementType['type'], isMainLabelHidden }
      })
    );
  };

  return (
    <TemplateWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      dependencies={[isHeadingOpen]}
      hasPadding
    >
      <ReplacementTextHeading
        listingOption={listingOption}
        index={0}
        isQuestion
        onChange={(e) => replaceLabel(e.target.value)}
        value={replacementLabel}
        content={element.label}
        isLabelHiddenValue={isMainLabelHidden}
        onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
        onReplacementLabelHiddenChange={() => replaceLabel('')}
        isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
      />
    </TemplateWidgetWrapper>
  );
};

export default OrganisationPlanTimePickerWithReplacementWidget;
