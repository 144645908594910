import {
  Delete16Regular,
  EyeTracking24Regular,
  EyeTrackingOff24Regular,
  RadioButton20Filled,
  RadioButton20Regular
} from '@fluentui/react-icons';
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LINKING_ACTION_OPTIONS } from 'modules/builder/constants/builderLinkingSettings';
import SelectSourceDialog from './SelectSourceDialog';
import { ILinkObjectForSelect, ILinkingPageNav, LinkingAction, RemoveLinkHandlerType, UpdateLinkHandlerType } from './types';

interface ILinkItemProps {
  linkObj: ILinkObjectForSelect;
  index: number;
  totalLinks: number;
  options: ILinkingPageNav[];
  updateLinkHandler: UpdateLinkHandlerType<ILinkObjectForSelect>;
  removeLinkHanlder: RemoveLinkHandlerType;
}

const LinkItem = ({ linkObj, index, totalLinks, options, updateLinkHandler, removeLinkHanlder }: ILinkItemProps): ReactElement => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const intl = useIntl();
  const theme = useTheme();

  const selectActionOption = (linkId: ILinkObjectForSelect['linkId'], action?: LinkingAction) => {
    updateLinkHandler({ linkId, link: { action } });
  };

  const getSelectedOptionLabel = (selectedOption: Partial<ILinkObjectForSelect['source']>) => {
    const page = options.find((pageItem) => pageItem.id === selectedOption.pageSystemId);
    let widget;
    if (selectedOption.subPageSystemId) {
      const subPage = page?.subPages?.find((subPageItem) => subPageItem.id === selectedOption.subPageSystemId);
      widget = subPage?.widgets?.find((widgetItem) => widgetItem.id === selectedOption.widgetId);
    } else {
      widget = page?.widgets?.find((widgetItem) => widgetItem.id === selectedOption.widgetId);
    }
    const option = widget?.options?.find((optionItem) => optionItem.id === selectedOption.optionId);

    if (widget?.label && option?.label) {
      return (
        <>
          <Typography color="secondary.main" variant="subtitle2">
            {widget.label}
          </Typography>
          <Typography variant="body1">{option.label}</Typography>
        </>
      );
    } else {
      return intl.formatMessage({ id: 'builder.please-select-trigger' });
    }
  };

  const tooltipDescription = [
    <Stack key={'tooltip-show-element'} direction="row" gap="20px" ml="10px" justifyContent="flex-start" alignItems="center">
      <ListItemIcon>
        <EyeTracking24Regular />
      </ListItemIcon>
      <ListItemText
        primary={
          LINKING_ACTION_OPTIONS[0].formatId ? (
            <FormattedMessage id={LINKING_ACTION_OPTIONS[0].formatId} />
          ) : (
            LINKING_ACTION_OPTIONS[0].label
          )
        }
        secondary={intl.formatMessage({ id: 'builder.show-field-description' })}
      />
    </Stack>,
    <Stack key={'tooltip-hide-element'} direction="row" gap="20px" ml="10px" justifyContent="flex-start" alignItems="center">
      <ListItemIcon>
        <EyeTrackingOff24Regular />
      </ListItemIcon>
      <ListItemText
        primary={
          LINKING_ACTION_OPTIONS[1].formatId ? (
            <FormattedMessage id={LINKING_ACTION_OPTIONS[1].formatId} />
          ) : (
            LINKING_ACTION_OPTIONS[1].label
          )
        }
        secondary={intl.formatMessage({ id: 'builder.hide-field-description' })}
      />
    </Stack>
  ];

  return (
    <Box
      marginTop={index !== 0 ? '10px' : undefined}
      padding={'5px 10px 10px'}
      alignItems={'center'}
      boxShadow={`${theme.palette.divider} 0px 0px 0px 1px inset`}
      borderRadius="10px"
    >
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack direction="row" gap="5px" justifyContent="start" alignItems="center">
            <Typography color="secondary.light"># {index + 1} </Typography>
            <Typography color="secondary.main">
              <FormattedMessage id="builder.linking-rule" />
            </Typography>
          </Stack>
          <IconButton
            disabled={totalLinks === 1 && !linkObj.source.optionId}
            onClick={() => removeLinkHanlder(linkObj.linkId)}
            color="error"
            size="small"
          >
            <Delete16Regular />
          </IconButton>
        </Box>
        <Divider sx={{ borderWidth: '1px' }} />

        <ToggleButtonGroup
          fullWidth
          sx={{
            padding: '0px',
            mb: '10px',
            mt: '10px',
            borderRadius: '0px',
            '& .MuiToggleButton-root': {
              borderRadius: '10px',
              border: 'none',
              padding: '5px 8px'
            }
          }}
          size="small"
          color="primary"
          value={linkObj.action}
          exclusive
          onChange={(_e, value) => {
            if (value !== null) {
              selectActionOption(linkObj.linkId, value);
            }
          }}
          aria-label="linking type"
        >
          {LINKING_ACTION_OPTIONS.map((option, index) => (
            <Tooltip key={index} arrow placement="top" enterDelay={1700} title={tooltipDescription[index]}>
              <ToggleButton key={option.value} value={option.value}>
                <Stack display="flex" direction="row" justifyContent="center" alignItems="center" gap="10px">
                  {linkObj.action === option.value ? <RadioButton20Filled /> : <RadioButton20Regular />}
                  <Typography sx={{ whiteSpace: 'wrap', overflow: 'hidden' }} lineHeight={1.2} textAlign="left" maxWidth="100px">
                    {option.formatId ? intl.formatMessage({ id: option.formatId }) : option.label}
                  </Typography>
                </Stack>
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Stack>

      <Stack spacing={1.25}>
        <Button variant="outlined" fullWidth onClick={() => setIsSelectOpen(true)}>
          <div style={{ whiteSpace: 'pre-wrap' }}>{getSelectedOptionLabel(linkObj.source)}</div>
        </Button>
        <SelectSourceDialog
          open={isSelectOpen}
          onClose={() => setIsSelectOpen(false)}
          options={options}
          linkObj={linkObj}
          updateLinkHandler={updateLinkHandler}
        />
      </Stack>
    </Box>
  );
};

export default LinkItem;
