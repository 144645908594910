import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Card)(({ theme }) => ({
  boxShadow: theme.palette.mode === 'dark' ? `0 0 0  1px  #ffffff20` : `0 0 0  1px  #00000020`,

  borderRadius: '10px',
  height: 'calc(100dvh - 70px)',
  width: '350px',
  position: 'sticky',
  top: '10px'
}));
