import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UploadWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.lighter,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: 2,
  padding: '18px 0',
  textAlign: 'center',
  marginTop: '10px'
}));
