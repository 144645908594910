import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IReplacementInputField {
  isQuestion?: boolean;
}

export const ReplacementInputField = styled(TextField)<IReplacementInputField>(({ theme, isQuestion }) => ({
  backgroundColor: theme.palette.secondary.lighter,
  width: '100%',
  padding: 0,
  marginTop: '0px',
  marginBottom: 0,

  '& input': {
    padding: '2px 6px 4px 6px',
    fontWeight: isQuestion ? 600 : 400,
    lineHeight: 0.8
  }
}));
