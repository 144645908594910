import { ArrowDown20Regular, ImageProhibitedFilled } from '@fluentui/react-icons';
import { Box, Button, Card, Stack } from '@mui/material';
import { DisplayMode, ListingOption, TypographyType } from 'modules/organisation-plan-templates/types';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { PreviewLabel } from './PreviewLabel';
import PreviewListingOption from './PreviewListingOption';

interface IPreviewAnswerWrapperProps {
  imageSrc?: string;
  listingOption?: ListingOption;
  questionLabel?: string;
  answerLabel?: string;
  numericValueLabel?: string | null;
  linkLabel?: string;
  isLabelHidden?: boolean;
  isQuestionLabelHidden?: boolean;
  isImageHidden?: boolean;
  isSelected?: boolean;
  isGrid?: boolean;
  isLink?: boolean;
  displayMode?: DisplayMode;
  index?: number;
}

const PreviewAnswerWrapper = ({
  imageSrc,
  listingOption = ListingOption.none,
  questionLabel,
  answerLabel,
  numericValueLabel,
  linkLabel,
  isLabelHidden = false,
  isQuestionLabelHidden = false,
  isImageHidden = false,
  isSelected,
  isGrid = false,
  isLink = false,
  displayMode,
  index
}: IPreviewAnswerWrapperProps) => {
  const isInlineMode = displayMode === DisplayMode.inline;
  const isRowMode = displayMode === DisplayMode.row;
  const isColumnMode = displayMode === DisplayMode.column;
  const isImageFile = () => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    const fileExtension = linkLabel?.split('.').pop()?.toLowerCase();
    return !linkLabel ? true : imageExtensions.includes(fileExtension!);
  };

  const InlineWrapper = (children: ReactNode | ReactElement) => {
    if (isInlineMode) {
      return <span>{children}</span>;
    } else if (isRowMode) {
      return (
        <Stack direction="row" gap="10px" justifyContent="flex-start" alignItems="center">
          {children}
        </Stack>
      );
    } else
      return <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '2px 10px', alignItems: 'center' }}>{children}</Box>;
  };

  if (isLabelHidden && isImageHidden) {
    return null;
  }

  if (isLabelHidden && !imageSrc) {
    return null;
  }

  if (isGrid) {
    return (
      <Stack minWidth="230px" width="calc(24% - 10px)" gap="2px" sx={{ '@media print': { breakInside: 'avoid' } }}>
        {imageSrc && !isImageHidden && (
          <Card sx={{ height: '190px', width: '100%', borderRadius: '10px', boxShadow: '0 0 0 1px #00000020' }}>
            {isImageFile() ? (
              <img style={{ objectFit: 'cover', height: '190px', width: '100%' }} src={imageSrc} alt={answerLabel} />
            ) : (
              <Stack width="100%" height="100%" justifyContent="center" alignItems="center" textAlign="center" p="10px">
                <ImageProhibitedFilled fontSize="3rem" opacity="0.2" />
                <FormattedMessage id="organization-plan-preview.no-file-preview" />
              </Stack>
            )}
          </Card>
        )}
        <Stack gap="2px">
          <PreviewLabel type={TypographyType.answerLabel} label={answerLabel} isHidden={isLabelHidden} isSelected={isSelected} />
          <PreviewLabel
            type={TypographyType.numericValues}
            label={numericValueLabel}
            isHidden={isLabelHidden && isImageHidden}
            isSelected={isSelected}
          />
        </Stack>
        {isLink && !!imageSrc && (
          <Button size="small" variant="outlined" endIcon={<ArrowDown20Regular />} href={imageSrc}>
            {linkLabel}
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="10px" justifyContent="flex-start" alignItems="center">
      <PreviewListingOption index={index} listingOption={listingOption} isHidden={isLabelHidden} />
      {InlineWrapper(
        <>
          <PreviewLabel
            isInline={isInlineMode}
            type={TypographyType.questionLabel}
            label={questionLabel}
            isHidden={isColumnMode || isLabelHidden || isQuestionLabelHidden}
            isSelected={isSelected}
          />
          {isInlineMode && <span>&#8194;</span>}
          <PreviewLabel
            isInline={isInlineMode}
            type={TypographyType.answerLabel}
            label={answerLabel}
            isHidden={isLabelHidden}
            isSelected={isSelected}
          />
          {isInlineMode && <span>&#8194;</span>}
          <PreviewLabel
            isInline={isInlineMode}
            type={TypographyType.numericValues}
            label={numericValueLabel}
            isHidden={isLabelHidden}
            isSelected={isSelected}
          />
          {isInlineMode && <span>&#8194;</span>}
          {isLink && !!imageSrc && (
            <Button size="small" variant="outlined" endIcon={<ArrowDown20Regular />} href={imageSrc}>
              {linkLabel}
            </Button>
          )}
        </>
      )}
    </Stack>
  );
};

export default PreviewAnswerWrapper;
