import { TextAdd20Regular } from '@fluentui/react-icons';
import { Button } from '@mui/material';
import PreviewDraftText from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputTextWidget/PreviewDraftText';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { getOrganisationPlanWidgetRowsAmount } from 'modules/organisation-plan-templates/utils/getOrganisationPlanWidgetRowsAmount';
import { useEffect, useRef } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { useDispatch } from 'store';
import {
  updateOrganisationPlanLayoutPartially,
  updateOrganisationPlanWidget
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import TemplateWidgetWrapper from '../Shared/TemplateWidgetWrapper';
import OrgPlanModalEditText from './OrgPlanModalEditText';

interface ITextWidgetProps {
  element: OrganisationPlanTextWidgetType;
  layoutItem: Layout;
  isTextEditorOpen: boolean;
  onHandleTextEditorClose: () => void;
  onHandleTextEditorOpen: () => void;
}

const OrganisationPlanTextWidget = ({
  element,
  layoutItem,
  isTextEditorOpen,
  onHandleTextEditorClose,
  onHandleTextEditorOpen
}: ITextWidgetProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { content, widgetId, type, sectionId, isHighlighted, isBottomDivider } = element;
  const updateContent = ({ pureText, editedText }: { pureText: string; editedText: string }) => {
    dispatch(updateOrganisationPlanWidget({ widgetId, widget: { type, content: editedText, layout: editedText, value: pureText } }));
    onHandleTextEditorClose();
  };
  const refBlock = useRef<HTMLDivElement>(null);
  const refText2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof refBlock?.current?.offsetHeight !== 'number' || typeof refText2?.current?.offsetHeight !== 'number') return;
    const textHeight = getOrganisationPlanWidgetRowsAmount(refText2?.current?.offsetHeight);
    if (textHeight > 1) {
      dispatch(
        updateOrganisationPlanLayoutPartially({
          layout: {
            h: textHeight,
            i: widgetId
          },
          sectionId: sectionId || ''
        })
      );
    }
  }, [refBlock?.current?.offsetHeight, refText2?.current?.offsetHeight, content, layoutItem?.w, dispatch, widgetId, sectionId]);

  return (
    <TemplateWidgetWrapper
      isHighlighted={isHighlighted}
      isBottomDivider={isBottomDivider}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      hasPadding
    >
      {content.trim() === '' && (
        <Button
          fullWidth
          startIcon={<TextAdd20Regular />}
          onClick={onHandleTextEditorOpen}
          color="secondary"
          aria-label="upload picture"
          component="label"
        >
          {intl.formatMessage({ id: 'organisation.add-text' })}
        </Button>
      )}
      <section ref={refText2}>
        {isTextEditorOpen && (
          <OrgPlanModalEditText
            handleClose={onHandleTextEditorClose}
            value={content}
            onChange={updateContent}
            isOpenModal={isTextEditorOpen}
          />
        )}
        <PreviewDraftText element={element} />
      </section>
    </TemplateWidgetWrapper>
  );
};

export default OrganisationPlanTextWidget;
