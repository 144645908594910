import { Box } from '@mui/material';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';
import { useEffect } from 'react';
import { Layout } from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import { updateOrganisationPlanLayoutPartially } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface IPreviewDividerOrganisationPlan {
  element: OrganisationPlanDividerWidgetType;
  layoutItem: Layout;
}

const PreviewDividerOrganisationPlan = ({ layoutItem, element }: IPreviewDividerOrganisationPlan) => {
  const dispatch = useDispatch();
  const { widgetId, sectionId } = element;
  useEffect(() => {
    dispatch(updateOrganisationPlanLayoutPartially({ layout: { i: widgetId, isResizable: false }, sectionId: sectionId ? sectionId : '' }));
    return () => {
      dispatch(
        updateOrganisationPlanLayoutPartially({ layout: { i: widgetId, isResizable: true }, sectionId: sectionId ? sectionId : '' })
      );
    };
  }, [dispatch, sectionId, widgetId]);

  if (layoutItem.h === 1) return <Box display="block" height="0px" />;

  return <Box display="block" height={`${(layoutItem.h - 1) * 10}px`} />;
};

export default PreviewDividerOrganisationPlan;
