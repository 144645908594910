import { Box, Divider, Stack, useTheme } from '@mui/material';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { SectionContainer } from 'shared/components/SectionContainer';
import { IOrganisationPlanPreview } from 'shared/types/organisation-plan-preview';
import { PreviewLabel } from '../Shared/PreviewLabel';
import OrganisationPlanPreviewGuestsSectionOptionInfo from './OrganisationPlanPreviewGuestsSectionOptionInfo';
import OrganisationPlanPreviewNumberOfGuestsInfo from './OrganisationPlanPreviewNumberOfGuestsInfo';
import { OrganisersAndGuestsInfo } from './OrganisationPlanPreviewOrganisersAndGuestsSection.styles';

// local interface
export interface IOrganisationPlanPreviewHeaderItem {
  icon: JSX.Element;
  text: string;
}

interface IOrganisationPlanPreviewHeader {
  title?: string;
  organisationPlanInfo?: IOrganisationPlanPreview;
  items?: IOrganisationPlanPreviewHeaderItem[];
}

const OrganisationPlanPreviewMainInfoSection = ({ title, organisationPlanInfo, items }: IOrganisationPlanPreviewHeader) => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;
  const showEventName = organisationPlanInfo?.builder?.organizationPlanSettings?.showEventName && organisationPlanInfo?.event?.name;
  const showEventDate = organisationPlanInfo?.builder?.organizationPlanSettings?.showEventDate && organisationPlanInfo?.event?.eventDate;
  const showContactPerson =
    organisationPlanInfo?.builder?.organizationPlanSettings?.showContactPerson && organisationPlanInfo?.event?.contactPeople?.length;
  const showEventManager =
    organisationPlanInfo?.builder?.organizationPlanSettings?.showEventManager && organisationPlanInfo?.event?.eventManager?.name;
  const showNumberOfGuests =
    organisationPlanInfo?.builder?.organizationPlanSettings?.showNumberOfGuests &&
    organisationPlanInfo?.builder?.organizationPlanSettings?.guestDisplayOptions?.length;

  return (
    <SectionContainer className="header">
      <Box width="100%" textAlign="center" padding="10px">
        <PreviewLabel isBigger color={mainColor} type={TypographyType.headline} label={title ? title : organisationPlanInfo?.name} />
      </Box>
      <Divider sx={{ borderWidth: '2px' }} />
      <OrganisersAndGuestsInfo>
        {showEventName ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo type="eventName" content={organisationPlanInfo.event.name} />
        ) : null}
        {showEventDate ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo type="eventDate" content={`${organisationPlanInfo.event.eventDate}`} />
        ) : null}
        {showContactPerson ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo
            type="contactPerson"
            content={`${organisationPlanInfo.event.contactPeople[0].name} ${organisationPlanInfo.event.contactPeople[0].surname}`}
          />
        ) : null}
        {showEventManager ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo
            type="manager"
            content={`${organisationPlanInfo.event.eventManager.name} ${organisationPlanInfo.event.eventManager.surname}`}
          />
        ) : null}
        {showNumberOfGuests ? (
          <OrganisationPlanPreviewNumberOfGuestsInfo
            guestNumberOptions={organisationPlanInfo?.builder?.organizationPlanSettings?.guestDisplayOptions}
            guestOptionsValues={organisationPlanInfo?.guestCategories}
          />
        ) : null}
        {items && items.length
          ? items.map((i, index) => (
              <Stack key={`person_${index}`} direction="row" alignItems="10px" gap="10px">
                <Box sx={{ color: mainColor }}>{i.icon}</Box>
                <PreviewLabel type={TypographyType.body} label={i.text} />
              </Stack>
            ))
          : null}
      </OrganisersAndGuestsInfo>
    </SectionContainer>
  );
};

export default OrganisationPlanPreviewMainInfoSection;
