import { Box, Button, Dialog, DialogActions, useTheme } from '@mui/material';
import TextEditor from 'modules/builder/components/widgets/TextWidget/TextEditor';
import { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormattedMessage } from 'react-intl';
interface ModalEditTextProps {
  value: string;
  onChange: ({ pureText, editedText }: { pureText: string; editedText: string }) => void;
  disableToolbarOnFocusLost?: boolean;
  isOpenModal: boolean;
  handleClose: () => void;
}

const OrgPlanModalEdit = ({ value, onChange, isOpenModal, handleClose }: ModalEditTextProps) => {
  const [valueState, setValueState] = useState<{ pureText: string; editedText: string }>({ pureText: '', editedText: '' });
  const theme = useTheme();

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: '1010px',
            height: '85vh',
            backgroundColor: theme.palette.background.paper,
            overflow: 'hidden'
          }
        }}
        fullWidth
        open={isOpenModal}
        onClose={handleClose}
        aria-labelledby="edit-text-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialogContent-root': { padding: 'none !important' } }}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <Box height="100%">
          <TextEditor value={value} onChange={(newValue) => setValueState(newValue)} />
        </Box>
        <DialogActions sx={{ backgroundColor: theme.palette.secondary.lighter, borderTop: `1px solid ${theme.palette.secondary.light}` }}>
          <Button variant="outlined" onClick={handleClose}>
            <FormattedMessage id="builder.close" />
          </Button>
          <Button color={'primary'} onClick={() => onChange(valueState)} variant="contained" size="medium">
            <FormattedMessage id="users.save" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrgPlanModalEdit;
