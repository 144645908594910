import { Box, Typography, TypographyProps } from '@mui/material';
import useFontSettings from 'modules/organisation-plan-preview/hooks/useFontSettings';
import { TypographyType } from 'modules/organisation-plan-templates/types';

interface IPreviewLabelProps extends TypographyProps {
  label?: string | null;
  type?: TypographyType;
  isHidden?: boolean;
  isSelected?: boolean;
  isBigger?: boolean;
  isBolder?: boolean;
  color?: string;
  isInline?: boolean;
}

export const PreviewLabel = ({
  color,
  label,
  type = TypographyType.body,
  isHidden = false,
  isSelected = true,
  isBigger = false,
  isBolder = false,
  isInline = false,
  ...props
}: IPreviewLabelProps) => {
  const { fontSettingsState, fontSettings } = useFontSettings();

  const fontSize = fontSettingsState[type]?.fontSize || fontSettings.find((f) => f.id === type)?.defaultValues.fontSize;
  const fontSizeBigger = fontSize ? fontSize + 5 : 24;
  const fontWeight = fontSettingsState[type]?.fontWeight || fontSettings.find((f) => f.id === type)?.defaultValues.fontWeight;
  const fontWeightBolder = fontWeight ? fontWeight + 200 : 600;

  const textDecoration = !isSelected ? 'line-through !important' : 'none';

  if (isHidden || !label) {
    return null;
  }

  if (type === TypographyType.numericValues) {
    return (
      <Box
        sx={{
          display: isInline ? 'inline' : 'block'
        }}
      >
        <Typography
          sx={{
            display: isInline ? 'inline' : 'block',
            color: color,
            width: 'fit-content',
            backgroundColor: '#f0f0f0',
            boxShadow: '0 0 0 1px #dadada',
            borderRadius: '6px',
            padding: '0px 4px',
            textDecoration: textDecoration
          }}
          textAlign="center"
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {label}
        </Typography>
      </Box>
    );
  }

  return (
    <Typography
      paddingBottom={props.paddingBottom}
      fontSize={isBigger ? fontSizeBigger : fontSize}
      fontWeight={isBolder ? fontWeightBolder : fontWeight}
      sx={{ textDecoration: textDecoration, color: color, display: isInline ? 'inline' : 'block' }}
    >
      {label}
    </Typography>
  );
};
