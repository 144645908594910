import { useMemo } from 'react';

export interface LayoutItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export function useSubgrids(layout: LayoutItem[]): LayoutItem[][] {
  return useMemo(() => {
    if (!Array.isArray(layout)) return [];

    // Sort items by y, then x
    const sortedItems = [...layout].sort((a, b) => {
      if (a.y === b.y) return a.x - b.x;
      return a.y - b.y;
    });

    const itemMap: Record<string, LayoutItem> = {};
    for (const item of sortedItems) {
      itemMap[`${item.y}:${item.x}`] = item;
    }

    const assigned = new Set<string>();
    const subgrids: LayoutItem[][] = [];

    const getItem = (y: number, x: number): LayoutItem | undefined => {
      return itemMap[`${y}:${x}`];
    };

    function getMaxY(): number {
      return sortedItems[sortedItems.length - 1]?.y ?? 0;
    }

    // Fallback logic for w=1 (except x=1 scenario) or w=2 subgrids
    function addColumnItemsAtY(
      y: number,
      columns: number[],
      expectedW: number,
      collection: LayoutItem[]
    ): { success: boolean; items: LayoutItem[] } {
      const rowItems: LayoutItem[] = [];
      for (const cx of columns) {
        const it = getItem(y, cx);
        if (!it || it.w !== expectedW || assigned.has(`${it.y}:${it.x}`)) {
          return { success: false, items: [] };
        }
        rowItems.push(it);
      }
      collection.push(...rowItems);
      return { success: true, items: rowItems };
    }

    function expandW1X1(
      _startX: number,
      startY: number,
      assigned: Set<string>,
      getItem: (y: number, x: number) => LayoutItem | undefined,
      subgridItems: LayoutItem[]
    ) {
      // Upwards
      let upY = startY - 1;
      while (upY >= 0) {
        const col1Item = getItem(upY, 1);
        if (col1Item) {
          // Found an item in x=1
          if (col1Item.w === 1 && !assigned.has(`${col1Item.y}:${col1Item.x}`)) {
            subgridItems.push(col1Item);
            upY--;
          } else {
            // w=2 or assigned breaks
            break;
          }
        } else {
          // No item at x=1
          const col0Item = getItem(upY, 0);
          if (col0Item && col0Item.w === 2) {
            // w=2 at x=0 breaks
            break;
          } else {
            // w=1 at x=0 or no item at x=0 -> ignore and continue going up
            upY--;
          }
        }
      }

      // Downwards
      let downY = startY + 1;
      while (downY <= getMaxY()) {
        const col1Item = getItem(downY, 1);
        if (col1Item) {
          if (col1Item.w === 1 && !assigned.has(`${col1Item.y}:${col1Item.x}`)) {
            subgridItems.push(col1Item);
            downY++;
          } else {
            // w=2 or assigned breaks downward
            break;
          }
        } else {
          // No item at x=1
          const col0Item = getItem(downY, 0);
          if (col0Item && col0Item.w === 2) {
            // w=2 at x=0 breaks
            break;
          } else {
            // Otherwise continue down
            downY++;
          }
        }
      }
    }

    function formSubgrid(startItem: LayoutItem): LayoutItem[] | null {
      const { x: startX, y: startY, w } = startItem;
      const key = `${startY}:${startX}`;

      // Mark start item invalid if assigned or does not meet w=1/w=2 criteria
      if (assigned.has(key)) {
        return null;
      }
      if (w !== 1 && w !== 2) {
        return null;
      }

      // Handle special case: w=1 and x=1 with the described logic
      if (w === 1 && startX === 1) {
        // Start subgrid
        if (assigned.has(`${startY}:${startX}`)) return null;
        if (startItem.w !== 1) return null;

        const subgridItems: LayoutItem[] = [startItem];

        // Apply special logic for expanding upwards/downwards
        expandW1X1(startX, startY, assigned, getItem, subgridItems);

        return subgridItems;
      }

      // Otherwise, fallback to old logic:
      // For w=1 and x!=1, or w=2:
      let columns: number[];
      if (w === 1) {
        columns = [startX];
      } else {
        const rightItem = getItem(startY, startX + 1);
        if (!rightItem || rightItem.w !== 2 || assigned.has(`${rightItem.y}:${rightItem.x}`)) {
          return null;
        }
        columns = [startX, startX + 1];
      }

      const subgridItems: LayoutItem[] = [];

      // Add starting row
      const startCheck = addColumnItemsAtY(startY, columns, w, subgridItems);
      if (!startCheck.success) {
        return null;
      }

      // Upwards
      let upY = startY - 1;
      if (w === 1) {
        while (upY >= 0) {
          const aboveItem = getItem(upY, startX);
          if (!aboveItem) {
            // No item in this column at upY, skip
            upY--;
            continue;
          } else {
            if (aboveItem.w === w && !assigned.has(`${aboveItem.y}:${aboveItem.x}`)) {
              subgridItems.push(aboveItem);
              upY--;
            } else {
              break;
            }
          }
        }
      } else {
        // w=2
        while (upY >= 0) {
          const result = addColumnItemsAtY(upY, columns, w, subgridItems);
          if (!result.success) {
            break;
          }
          upY--;
        }
      }

      // Downwards
      let downY = startY + 1;
      if (w === 1) {
        while (downY <= getMaxY()) {
          const belowItem = getItem(downY, startX);
          if (!belowItem) {
            // No item at this column, skip
            downY++;
            continue;
          } else {
            if (belowItem.w === w && !assigned.has(`${belowItem.y}:${belowItem.x}`)) {
              subgridItems.push(belowItem);
              downY++;
            } else {
              break;
            }
          }
        }
      } else {
        // w=2
        while (downY <= getMaxY()) {
          const result = addColumnItemsAtY(downY, columns, w, subgridItems);
          if (!result.success) {
            break;
          }
          downY++;
        }
      }

      return subgridItems;
    }

    // Main processing
    for (const item of sortedItems) {
      const key = `${item.y}:${item.x}`;
      if (!assigned.has(key)) {
        const subgrid = formSubgrid(item);
        if (subgrid && subgrid.length > 0) {
          for (const it of subgrid) {
            assigned.add(`${it.y}:${it.x}`);
          }
          subgrids.push(subgrid);
        } else {
          // If we couldn't form a subgrid, assign this item alone as a subgrid
          assigned.add(key);
          subgrids.push([item]);
        }
      }
    }

    return subgrids;
  }, [layout]);
}
