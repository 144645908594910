import { Box, Stack } from '@mui/material';
import { useGetOrganisationPlanWidgetsForPreview } from 'api/hooks/organisation-plan-preview/useGetOrganisationPlanPreviewWidgets';
import OrganisationPlanPreviewHeader from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewHeader';
import OrganisersAndGuestsSection from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewOrganisersAndGuestsSection';
import OrganisationPlanPreviewWidgetSection from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewWidgetSection';
import { getWidgetsOrgPlanPreviewLayout } from 'modules/organisation-plan-preview/utils/getWidgetsOrgPlanPreviewLayout';
import { GRID_LAYOUT_SIZE_ORGANISATION_PLAN } from 'modules/organisation-plan-templates/constants/organisationPlanTemplateBuilder';
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router';
import Loader from 'shared/components/Loader';
import PageTitle from 'shared/components/PageTitle';
import { useDispatch } from 'store';
import { setOrganisationPlanStructure } from 'store/organisation-plan-template/organisation-plan-template.slice';

const OrganisationPlanPreviewLayout = (): ReactElement => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { isLoading: organisationPlanWidgetsLoading, data: organisationPlanWidgetsData } = useGetOrganisationPlanWidgetsForPreview(
    token ? token : undefined
  );

  useEffect(() => {
    const sections = organisationPlanWidgetsData?.builder?.sections || [];
    const links = organisationPlanWidgetsData?.builder?.links || [];

    const widgets = getWidgetsOrgPlanPreviewLayout({ organisationPlanWidgetsData });

    dispatch(setOrganisationPlanStructure({ sections, links, usedWidgets: widgets }));
  }, [organisationPlanWidgetsData, dispatch]);

  return (
    <>
      <PageTitle title={organisationPlanWidgetsData?.name} />
      <Box>
        <OrganisationPlanPreviewHeader organisationPlanWidgetsData={organisationPlanWidgetsData} token={token} />
        {organisationPlanWidgetsLoading ? (
          <Loader />
        ) : (
          <Stack gap="10px" width={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.widthGrid} margin="0 auto" id="printable">
            <OrganisersAndGuestsSection organisationPlanInfo={organisationPlanWidgetsData} />
            <OrganisationPlanPreviewWidgetSection />
          </Stack>
        )}
      </Box>
    </>
  );
};

export default OrganisationPlanPreviewLayout;
