import { Box, Dialog as MuiDialog, DialogContent as MuiDialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxAction = styled(Box)(() => ({
  position: 'sticky',
  bottom: 0,
  zIndex: 99
}));

export const Dialog = styled(MuiDialog)(() => ({
  '& .MuiDialog-paper': { padding: 0, borderRadius: '8px' }
}));
export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: 'calc(83vh - 100px)',
  maxHeight: '600px',
  overflow: 'auto'
}));
