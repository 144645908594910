import { ISideBarWidget, ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';

const divider = '/assets/images/builder/builder-widget/divider.svg';
const text = '/assets/images/builder/builder-widget/text.svg';
const textarea = '/assets/images/builder/builder-widget/textarea.png';
const info = '/assets/images/organisation-plan-template/info_from_questionnaire_widget_icon.svg';

export const ORGANISATION_PLAN_BUILDER_SIDEBAR_WIDGETS_LIST: ISideBarWidget[] = [
  {
    imgUrl: text,
    label: 'Text',
    layout: { w: 2, h: 5, minH: 4, isResizable: true },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText,
      label: '',
      value: '',
      content: ''
    },
    formatId: 'builder.text'
  },
  {
    imgUrl: textarea,
    label: 'Notes',
    customImageSize: true,
    layout: { w: 2, h: 5, isResizable: true },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.notes,
      label: '',
      noteText: '',
      content: ''
    },
    formatId: 'organisation.notes'
  },
  {
    imgUrl: info,
    label: 'Info from Questionnaire',
    layout: { w: 2, h: 6 },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire
    },
    formatId: 'organisation.info-from-questionnaire'
  },
  {
    imgUrl: divider,
    label: 'Divider',
    layout: { w: 2, h: 2, minH: 1, isResizable: true, resizeHandles: ['s', 'e'] },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.divider
    },
    formatId: 'builder.divider'
  }
];
