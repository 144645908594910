import { AddCircle20Regular } from '@fluentui/react-icons';
import { Box, Button, Stack } from '@mui/material';
import OrgPlanSectionWrapper from 'modules/organisation-plan-templates/containers/OrgPlanSectionWrapper';
import SectionDndContainer from 'modules/organisation-plan-templates/containers/SectionDndContainer/index';
import { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { selectOrgPlanSectionsIds } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { addNewOrganisationPlanSection } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface IOrganisationPlanTemplateSectionsContainerProps {
  isPaddingSection: boolean;
}

const OrganisationPlanTemplateSectionsContainer = ({ isPaddingSection }: IOrganisationPlanTemplateSectionsContainerProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const sectionsIds = useSelector(selectOrgPlanSectionsIds) as string[];

  const addNewSection = useCallback(() => {
    dispatch(addNewOrganisationPlanSection({ defaultSectionName: intl.formatMessage({ id: 'organisation-plan.new-section-name' }) }));
  }, [dispatch, intl]);

  useEffect(() => {
    if (!sectionsIds.length) addNewSection();
  }, [addNewSection, sectionsIds.length]);

  return (
    <Stack gap="10px">
      <SectionDndContainer>
        {sectionsIds.map((sectionId, index) => (
          <OrgPlanSectionWrapper isPaddingSection={isPaddingSection} key={sectionId} sectionId={sectionId} sectionIndex={index} />
        ))}
      </SectionDndContainer>

      <Box width="100%" textAlign="center">
        <Button startIcon={<AddCircle20Regular />} variant="outlined" sx={{ borderRadius: '60px' }} onClick={addNewSection}>
          <FormattedMessage id="organisation-plan.new-section-btn" />
        </Button>
      </Box>
    </Stack>
  );
};

export default OrganisationPlanTemplateSectionsContainer;
