import { Image16Regular, ImageProhibited20Regular } from '@fluentui/react-icons';
import { Card, Tooltip } from '@mui/material';
import { IMAGE_FIT_TYPE } from 'modules/builder/types/imageWidgetsTypes';
import { IQuestionnaireImageItemData } from 'modules/questionnaires/types/questionnaries';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SingleActionButton } from 'shared/components/ReplacementText/Buttons/Button.styles';
import { getStorageFileUrl } from 'shared/utils/getStorageFileUrl';
import { ShadowBox } from './OrganisationPlanSingleImageChoiceWidget.styles';

interface IImageContainerProps {
  value: IQuestionnaireImageItemData[] | null;
  typeImage: IMAGE_FIT_TYPE;
  isImageHidden?: boolean;
  onImageHiddenChange?: (value: boolean) => void;
}

const ImageContainer = ({ value, typeImage, isImageHidden, onImageHiddenChange }: IImageContainerProps) => {
  const NoImage = '/assets/images/builder/noimage.png';
  const intl = useIntl();
  const [isImageHiddenState, setImageHidden] = useState<boolean>(!!isImageHidden);
  const hideImageHandler = () => {
    setImageHidden(!isImageHiddenState);
    onImageHiddenChange?.(!isImageHiddenState);
  };

  const imageSrc = value ? getStorageFileUrl(value[0].fileUrl, value[0].type) : NoImage;

  return (
    <Card sx={{ height: '190px', width: '100%', borderRadius: '10px', boxShadow: '0 0 0 1px #00000020', position: 'relative', zIndex: 1 }}>
      {isImageHiddenState && <ShadowBox />}
      {imageSrc ? (
        <img style={{ objectFit: typeImage, height: '190px', width: '100%', zIndex: 2 }} src={imageSrc} />
      ) : (
        <img style={{ objectFit: 'cover', height: '190px', width: '100%', zIndex: 2 }} src={NoImage} />
      )}

      <Tooltip
        arrow
        enterDelay={2000}
        placement="top"
        title={intl.formatMessage({ id: isImageHiddenState ? 'organisation.show-image' : 'organisation.hide-image' })}
      >
        <SingleActionButton isFixed={true} isActive={isImageHiddenState} onClick={hideImageHandler}>
          {isImageHiddenState ? <ImageProhibited20Regular /> : <Image16Regular />}
        </SingleActionButton>
      </Tooltip>
    </Card>
  );
};

export default ImageContainer;
