import { Stack } from '@mui/material';
import TemplateWidgetWrapper from 'modules/organisation-plan-templates/components/widgets/Shared/TemplateWidgetWrapper';
import {
  IOrganisationPlanChoiceOptionLabels,
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { MultipleImageChoiceWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { ReactElement, useState } from 'react';
import { Layout } from 'react-grid-layout';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { getPlainTextFromHTML } from 'shared/utils/getPlainTextFromHTML';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface IOrganisationPlanChoiceWidgetProps {
  element: MultipleChoiceOrganisationPlanWidgetType | SingleChoiceOrganisationPlanWidgetType;
  layoutItem: Layout;
}
const OrganisationPlanChoiceWidget = ({ element, layoutItem }: IOrganisationPlanChoiceWidgetProps): ReactElement => {
  const dispatch = useDispatch();
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);
  const {
    widgetId,
    sectionId,
    optionLabels,
    label,
    type,
    replacementLabel,
    replacementOptionsLabels,
    isMainLabelHidden,
    listingOption,
    isHighlighted,
    isBottomDivider
  } = element;

  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as MultipleChoiceOrganisationPlanWidgetType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as MultipleImageChoiceWithReplacementWidgetType['type'], isMainLabelHidden }
      })
    );
  };

  const changeOptionVariablesHandler = (
    option: IOrganisationPlanChoiceOptionLabels,
    replacementLabel?: string,
    isLabelHidden?: boolean
  ) => {
    const updatedReplacementOptionsLabels = replacementOptionsLabels?.map((optionLabel) => {
      if (optionLabel.id === option.id) {
        return {
          ...optionLabel,
          replacementLabel: replacementLabel || '',
          isLabelHidden: isLabelHidden !== undefined ? isLabelHidden : optionLabel.isLabelHidden
        };
      }
      return optionLabel;
    });

    dispatch(updateOrganisationPlanWidget({ widgetId, widget: { type, replacementOptionsLabels: updatedReplacementOptionsLabels } }));
  };

  const changeOptionVariablesHandlerTest = (option: IOrganisationPlanChoiceOptionLabels, isMainLableOpen?: boolean) => {
    const updatedReplacementOptionsLabels = replacementOptionsLabels?.map((optionLabel) => {
      if (optionLabel.id === option.id) {
        return {
          ...optionLabel,
          replacementLabel: isMainLableOpen ? '' : null,
          isLabelHidden: optionLabel.isLabelHidden
        };
      }
      return optionLabel;
    });

    dispatch(updateOrganisationPlanWidget({ widgetId, widget: { type, replacementOptionsLabels: updatedReplacementOptionsLabels } }));
  };

  return (
    <TemplateWidgetWrapper
      isHighlighted={isHighlighted}
      isBottomDivider={isBottomDivider}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      dependencies={[isHeadingOpen, replacementOptionsLabels]}
      hasPadding
    >
      <ReplacementTextHeading
        isQuestion
        onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
        value={replacementLabel}
        content={getPlainTextFromHTML('p', label)}
        isLabelHiddenValue={isMainLabelHidden}
        onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
        onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
        isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
      />
      <Stack spacing="5px" mt="5px">
        {optionLabels.map((labelObj, index) => (
          <ReplacementTextHeading
            key={index}
            index={index}
            listingOption={listingOption}
            onChange={(e) => changeOptionVariablesHandler(labelObj, e.target.value)}
            value={replacementOptionsLabels.find((i) => i.id === labelObj.id)?.replacementLabel}
            content={labelObj.label}
            isLabelHiddenValue={replacementOptionsLabels?.find((i) => i.id === labelObj.id)?.isLabelHidden}
            onLabelHiddenChange={() => {
              const currentIsLabelHidden = replacementOptionsLabels?.find((i) => i.id === labelObj.id)?.isLabelHidden;
              changeOptionVariablesHandler(labelObj, undefined, !currentIsLabelHidden);
            }}
            onReplacementLabelHiddenChange={() => changeOptionVariablesHandler(labelObj, '')}
            isHeadingReplacementOpen={(value) => changeOptionVariablesHandlerTest(labelObj, value)}
          />
        ))}
      </Stack>
    </TemplateWidgetWrapper>
  );
};

export default OrganisationPlanChoiceWidget;
