import { Save20Regular } from '@fluentui/react-icons';
import { ClickAwayListener, IconButton } from '@mui/material';
import { ChangeEvent, KeyboardEvent, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from './TextEdit.styles';

export interface TextEditProps {
  placeholder?: string;
  value: string;
  onChange?: (newValue: string) => void;
  onValueSave: () => void;
  label?: string;
  name?: string;
  onClickAway?: () => void;
  /**
   * @param {string} className -default property which required by styled-components
   * https://styled-components.com/docs/basics#styling-any-component
   * @default undefined
   */
  className?: string;
  disabled?: boolean;
  submitOnEnter?: (event: KeyboardEvent<HTMLDivElement>) => void;
}

/**
 * Savable TextEdit common component
 */
export const TextEdit = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onValueSave,
  onClickAway,
  className,
  disabled = false,
  submitOnEnter
}: TextEditProps): ReactElement => {
  const intl = useIntl();
  const handleClickAway = (): void => {
    if (onClickAway) {
      onClickAway();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Styles.TextEditStyled
        fullWidth
        id={label ? `text-edit-${label}` : undefined}
        name={name}
        placeholder={placeholder ? placeholder : intl.formatMessage({ id: 'users.enter-here' })}
        value={value}
        onChange={handleInputChange}
        onKeyDown={submitOnEnter}
        autoFocus
        InputProps={{
          endAdornment: (
            <IconButton disabled={disabled} color="primary" onClick={onValueSave}>
              <Save20Regular />
            </IconButton>
          )
        }}
        className={className}
      />
    </ClickAwayListener>
  );
};
