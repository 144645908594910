import axios from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { UserPreference } from './useGetUserPreferences';

interface IUpdateUserPreferences {
  id: number;
  value: string[];
}

/**
 * Updates a user preference.
 * @function
 * @param {Object} options - The options for updating the user preference.
 * @param {number} options.id - The ID of the preference to update.
 * @param {string[]} options.value - The new value of the preference.
 * @returns {Promise<UserPreference>} A promise that resolves with the updated user preference.
 */
const updateUserPreferences = ({ id, value }: IUpdateUserPreferences): Promise<UserPreference> => {
  return axios
    .put(`/user-preferences/${id}`, {
      id,
      value
    })
    .then((res) => res.data);
};

/**
 * Custom hook that updates a user preference.
 * @function
 * @param {Object} options - The options for the mutation.
 * @param {Object} options.options - The options to pass to the useMutation hook.
 * @param {Function} options.onSuccess - A function to execute after the mutation is successful.
 * @returns {Object} The result of the mutation, including the updated user preference.
 */
export function useUpdateUserPreferences<TError, TContext>({
  options = {},
  onSuccess
}: {
  options?: Omit<UseMutationOptions<UserPreference, TError, IUpdateUserPreferences, TContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(updateUserPreferences, {
    ...options,
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['user-preferences']);
    }
  });
}
