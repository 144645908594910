import { CalendarStar20Regular, Call20Regular, PersonSupport20Regular, Star20Regular } from '@fluentui/react-icons';
import { Stack, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import { PreviewLabel } from '../Shared/PreviewLabel';

interface IOrganisationPlanPreviewGuestsSectionOptionInfoProps {
  type: 'eventName' | 'eventDate' | 'contactPerson' | 'manager';
  content: string;
}

const OrganisationPlanPreviewGuestsSectionOptionInfo = ({ type, content }: IOrganisationPlanPreviewGuestsSectionOptionInfoProps) => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;
  const optionByType = {
    eventName: {
      icon: <Star20Regular color={mainColor} />
    },
    eventDate: {
      icon: <CalendarStar20Regular color={mainColor} />
    },
    contactPerson: {
      icon: <Call20Regular color={mainColor} />
    },
    manager: {
      icon: <PersonSupport20Regular color={mainColor} />
    }
  };

  return (
    <Stack direction="row" gap="10px" alignItems="center">
      {optionByType[type].icon}
      <PreviewLabel type={TypographyType.answerLabel} label={type !== 'eventDate' ? content : format(new Date(content), 'dd.MM.yyyy')} />
    </Stack>
  );
};

export default OrganisationPlanPreviewGuestsSectionOptionInfo;
