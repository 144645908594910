import OrganisationPlanTemplateLinkingTab from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab';
import OrganisationPlanTemplateQuestionnaireInfoSettings from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateQuestionnaireInfoSettings';
import WidgetsBar from 'modules/organisation-plan-templates/components/WidgetsBar';
import { useSelector } from 'store';
import {
  editWidgetSettings,
  organizationPlanTemplateBuilderActiveLinkingSettings,
  selectIsActiveInfoFromQuestionnaireSettings
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import * as Styles from './OrganisationPlanTemplateSidebar.styles';

interface IOrganisationPlanTemplateSidebarProps {
  onIsPaddingSection: (isPadding: boolean) => void;
}

const OrganisationPlanTemplateSidebar = ({ onIsPaddingSection }: IOrganisationPlanTemplateSidebarProps) => {
  const activeInfo = useSelector(selectIsActiveInfoFromQuestionnaireSettings);
  const activeLinkingSettings = useSelector(organizationPlanTemplateBuilderActiveLinkingSettings);
  const editedWidgetInfo = useSelector(editWidgetSettings);

  const renderSidebarSectionHandler = () => {
    if (activeInfo || editedWidgetInfo) return <OrganisationPlanTemplateQuestionnaireInfoSettings />;
    if (activeLinkingSettings) return <OrganisationPlanTemplateLinkingTab target={activeLinkingSettings} />;
    return <WidgetsBar onIsPaddingSection={onIsPaddingSection} />;
  };

  return <Styles.GridWrapper>{renderSidebarSectionHandler()}</Styles.GridWrapper>;
};

export default OrganisationPlanTemplateSidebar;
