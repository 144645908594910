import { DEFAULT_NUMBER_OF_LABELS } from 'modules/builder/constants/builderWidgets/singleChoiceWidget';
import { SingleChoiceWidgetType } from 'modules/builder/types/choiceWidgetsType';
import { v4 as uuidv4 } from 'uuid';

/**
 * Util function to get initial options for just added widget
 */
export const createInitLabels = (numOfLabel: number = DEFAULT_NUMBER_OF_LABELS): SingleChoiceWidgetType['optionLabels'] =>
  new Array(numOfLabel)
    .fill(null)
    .map((_) => ({ id: uuidv4(), label: '', numericallyValue: null, secondUnitValue: null, countOfGuests: null }));
