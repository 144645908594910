import { Dialog, DialogActions, DialogContent, DialogTitle, Theme, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { de, enUS } from 'date-fns/locale';
import { Form, FormikContextType, FormikProvider } from 'formik';
import * as React from 'react';
import { useIntl } from 'react-intl';
import PageTitle from 'shared/components/PageTitle';

interface IModalDialogProps {
  pageTitle?: string;
  headerContent: React.ReactNode;
  bodyContent: React.ReactNode;
  actionBarContent: React.ReactNode;
  formikValue: FormikContextType<any>;
  isDialogOpen: boolean;
  isFullscreen?: boolean;
  isLoading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const ModalDialog = ({
  pageTitle = '',
  headerContent,
  bodyContent,
  actionBarContent,
  formikValue,
  isDialogOpen,
  isFullscreen,
  onSubmit,
  onClose
}: IModalDialogProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isDialogFullscreen = isFullscreen || isMobile;
  const language = useIntl().locale;
  const sx = {
    '& .MuiDialog-paper': {
      padding: 0,
      borderRadius: isMobile ? '0px' : '10px'
    },

    '& .MuiDialogContent-root': {
      maxHeight: `calc(100dvh - 120px) !important`
    }
  };

  return (
    <>
      <PageTitle title={pageTitle} />
      <FormikProvider value={formikValue}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={language === 'de' ? de : enUS}>
          <Form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Dialog maxWidth="sm" fullScreen={isDialogFullscreen} open={isDialogOpen} onClose={onClose} sx={sx}>
              <DialogTitle id="header">{headerContent}</DialogTitle>
              <DialogContent dividers>{bodyContent}</DialogContent>
              <DialogActions id="actions">{actionBarContent}</DialogActions>
            </Dialog>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default ModalDialog;
