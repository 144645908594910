import { TEXT_SOME_ERROR } from 'modules/auth/constants';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import { openSnackbar } from 'store/snackbar';

export const textError = (error: any, answer?: string) => {
  let message = error?.message;
  if (!message && typeof error === 'string') message = error;
  if (!message) message = answer ? answer : TEXT_SOME_ERROR;
  return message;
};

export const snackbarAlert = (message: string, colorAlert: AlertMessagesColor, secondaryMessage?: string) => {
  const textSecondary = secondaryMessage ? { secondaryText: secondaryMessage } : {};
  return openSnackbar({
    open: true,
    message: message,
    ...textSecondary,
    variant: 'alert',
    alert: {
      color: colorAlert
    },
    close: true
  });
};
