import { Box } from '@mui/material';
import { checkShowWidgetForSection } from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplatePlaygroundSection/utils';
import { OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import {
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';
import { MultipleImageChoiceWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';
import { OrganisationPlanInputTextWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputTextWithReplacementType';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import { ReactElement, ReactNode } from 'react';
import { Layout } from 'react-grid-layout';
import { IOrganisationPlanPreviewItem } from 'shared/types/organisation-plan-preview';
import { useSelector } from 'store';
import { selectIsEvent, selectOrganizationPlanWidgetById } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import PreviewChoiceWidget from './PreviewChoiceWidget';
import PreviewDividerOrganisationPlan from './PreviewDividerOrganistaionPlan';
import PreviewImageChoiceWidget from './PreviewImageChoiceWidget';
import PreviewNoteWidget from './PreviewNoteWidget';
import PreviewNumberInputWidget from './PreviewNumberInputWidget';
import PreviewTableWidget from './PreviewTableWidget';
import PreviewTextInputWidget from './PreviewTextInputWidget';
import PreviewTextWithLabelInputWidget from './PreviewTextWithLabelInputWidget';
import PreviewTimePickerWidget from './PreviewTimePickerWidget';
import PreviewUploadWidget from './PreviewUploadWidget';

interface IPreviewWidgetProps {
  layoutItem: Layout;
  noteWidgets?: IOrganisationPlanPreviewItem[];
}

enum PreviewWidgetByType {
  inputOrganisationPlanText = 'inputOrganisationPlanText',
  notes = 'notes',
  inputText = 'inputText',
  inputInteger = 'inputInteger',
  time = 'time',
  divider = 'divider',
  singleChoice = 'singleChoice',
  infoFromQuestionnaire = 'infoFromQuestionnaire',
  multipleChoice = 'multipleChoice',
  singleImageChoice = 'singleImageChoice',
  multipleImageChoice = 'multipleImageChoice',
  table = 'table',
  uploadArea = 'uploadArea'
}

const PreviewWidget = ({ layoutItem }: IPreviewWidgetProps) => {
  const element = useSelector((state) => selectOrganizationPlanWidgetById(state, layoutItem.i));
  const isEvent = useSelector(selectIsEvent);
  const widgetByType: Record<string, ReactNode> = {
    [PreviewWidgetByType.inputOrganisationPlanText]: (
      <PreviewTextInputWidget layoutItem={layoutItem} element={element as OrganisationPlanTextWidgetType} />
    ),
    [PreviewWidgetByType.notes]: <PreviewNoteWidget layoutItem={layoutItem} element={element as OrganisationPlanNotesWidgetType} />,
    [PreviewWidgetByType.infoFromQuestionnaire]: <Box>{null}</Box>,
    [PreviewWidgetByType.divider]: (
      <PreviewDividerOrganisationPlan layoutItem={layoutItem} element={element as OrganisationPlanDividerWidgetType} />
    ),
    [PreviewWidgetByType.inputText]: (
      <PreviewTextWithLabelInputWidget layoutItem={layoutItem} element={element as OrganisationPlanInputTextWithReplacementType} />
    ),
    [PreviewWidgetByType.inputInteger]: (
      <PreviewNumberInputWidget layoutItem={layoutItem} element={element as OrganisationPlanInputNumberWithReplacementType} />
    ),
    [PreviewWidgetByType.time]: (
      <PreviewTimePickerWidget layoutItem={layoutItem} element={element as OrganisationPlanTimePickerWithReplacementType} />
    ),
    [PreviewWidgetByType.singleChoice]: (
      <PreviewChoiceWidget layoutItem={layoutItem} element={element as SingleChoiceOrganisationPlanWidgetType} />
    ),
    [PreviewWidgetByType.multipleChoice]: (
      <PreviewChoiceWidget layoutItem={layoutItem} element={element as MultipleChoiceOrganisationPlanWidgetType} />
    ),
    [PreviewWidgetByType.singleImageChoice]: (
      <PreviewImageChoiceWidget layoutItem={layoutItem} element={element as MultipleImageChoiceWithReplacementWidgetType} />
    ),
    [PreviewWidgetByType.multipleImageChoice]: (
      <PreviewImageChoiceWidget layoutItem={layoutItem} element={element as MultipleImageChoiceWithReplacementWidgetType} />
    ),
    [PreviewWidgetByType.table]: (
      <PreviewTableWidget layoutItem={layoutItem} element={element as OrganisationPlanTableWithReplacementWidgetType} />
    ),
    [PreviewWidgetByType.uploadArea]: (
      <PreviewUploadWidget layoutItem={layoutItem} element={element as OrganisationPlanUploadAreaWithReplacementType} />
    )
  };
  const isShowWidget = checkShowWidgetForSection(element as OrganisationPlanWidgetsType);
  if ((isEvent && !isShowWidget) || !(element && element.type)) {
    return null;
  }
  return element && element.type ? (widgetByType[element.type] as ReactElement) : null;
};

export default PreviewWidget;
