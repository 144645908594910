import { DocumentPdf20Regular, Home20Regular, Mail20Regular, Settings20Regular } from '@fluentui/react-icons';
import { alpha, Box, Button, Chip, CircularProgress, Divider, Grid, IconButton, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { EditorType, EEditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { downloadPdfByIdEditor } from 'modules/editor/utils';
import { generateTooltipByButtonSendEmail } from 'modules/invoices/utils/prepareData';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import PromptMessage from 'shared/components/PromptMessage';
import TooltipWrapper from 'shared/components/TooltipWrapper';
import { questionnaireTaxtype } from 'shared/data/questionnaires';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import { ITenantPayload } from 'shared/types/tenants';
import * as Styles from './EditorHeader.style';
import { boxState, boxTax } from './EditorHeader.style';

interface IEditorHeader {
  isLoading: boolean;
  tenant?: ITenantPayload;
}

interface IButtonMenu {
  id: number;
  action: () => void;
  icon: ReactNode;
  isDisabled: boolean;
  tooltipTitle: string;
}
interface IEditorHeader {
  type: EditorType;
  title: string;
  children: ReactNode;
  isLoading: boolean;
  onEdit: () => void;
  onSendEmail: () => void;
}

const EditorHeader = ({ type, title, children, isLoading, onEdit, onSendEmail }: IEditorHeader) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  const { idUser, disableForEventManager } = useGetDataByRole();
  const { values, handleChange, dirty } = useFormikContext<ValuesEditorType>();
  const borderColor = theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900]!, 0.08) : alpha(theme.palette.grey[900]!, 0.1);
  const isInvoice = type === EEditorType.Invoices;

  const handleConfirmGoHome = () => {
    navigate('/dashboard');
  };

  const handleHideWarning = () => {
    setIsOpenModalWarning(false);
  };

  const handleButtonGoHome = () => {
    setIsOpenModalWarning(true);
  };

  const handleGeneratePdf = () => {
    if (!values?.id) return;
    downloadPdfByIdEditor(values.id, type);
  };

  const buttonsMenu: IButtonMenu[] = [
    {
      id: 1,
      action: onEdit,
      icon: <Settings20Regular />,
      isDisabled: dirty,
      tooltipTitle: dirty
        ? 'invoices.invoice-editor-button-lock'
        : isInvoice
          ? 'invoices.edit-invoice-details'
          : 'requests.edit-offer-details'
    },
    {
      id: 2,
      action: handleGeneratePdf,
      icon: <DocumentPdf20Regular />,
      isDisabled: dirty,
      tooltipTitle: dirty ? 'invoices.invoice-editor-button-lock' : 'invoices.row-options-open-pdf'
    },
    {
      id: 3,
      action: onSendEmail,
      icon: <Mail20Regular />,
      isDisabled: dirty || disableForEventManager || !!values?.isSentEmail,
      tooltipTitle: generateTooltipByButtonSendEmail(disableForEventManager, !!values?.isSentEmail, dirty)
    }
  ];

  const isHideSaveButton = disableForEventManager && values?.data?.eventManager?.id !== idUser;

  return (
    <>
      <Box>
        <Grid container p="10px">
          <Grid item sm={12} md={6}>
            <Styles.StackSide direction="row" alignItems="center">
              <Button onClick={handleButtonGoHome} variant="outlined" color="secondary" startIcon={<Home20Regular />}>
                <FormattedMessage id="builder.go-home" />
              </Button>
              <Styles.BoldText>{title}</Styles.BoldText>
              <Chip size="small" label={values?.number} />
            </Styles.StackSide>
          </Grid>
          <Grid item sm={12} md={6}>
            <Stack direction="row" gap="10px" alignItems="center" justifyContent="flex-end">
              <Box sx={boxTax}>
                <Select
                  value={values.taxType ?? null}
                  name="taxType"
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'events.select-here' })}
                  inputProps={{ placeholder: intl.formatMessage({ id: 'events.select-here' }) }}
                >
                  {questionnaireTaxtype.map((item) => (
                    <MenuItem key={`select-taxtype-item-${item.formatId}`} value={item.value}>
                      <FormattedMessage id={item.formatId} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={boxState}>{children}</Box>
              {buttonsMenu.map((button) => (
                <>
                  <TooltipWrapper title={button?.tooltipTitle || ''}>
                    <Box>
                      <IconButton
                        sx={{ border: `1px solid ${borderColor}` }}
                        disabled={button.isDisabled}
                        onClick={button.action}
                        color="secondary"
                      >
                        {button.icon}
                      </IconButton>
                    </Box>
                  </TooltipWrapper>
                </>
              ))}
              {!isHideSaveButton && (
                <Button type="submit" variant="contained" disabled={isLoading} startIcon={isLoading && <CircularProgress size={'20px'} />}>
                  <FormattedMessage id="settings.save-changes" />
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <PromptMessage
          isDirtyForm={dirty}
          handleConfirm={handleConfirmGoHome}
          isOpenModalWarning={isOpenModalWarning}
          handleCloseModal={handleHideWarning}
        />
        <Divider className="p-bottom" />
      </Box>
    </>
  );
};

export default EditorHeader;
