import { useGetUserPreferences } from 'api/hooks/user/useGetUserPreferences';
import { TypographyType } from 'modules/organisation-plan-templates/types';
import React from 'react';

const useFontSettings = () => {
  const { data: userPreferences, isLoading } = useGetUserPreferences();

  const fontSettings = [
    { id: TypographyType.headline, intlId: 'organization-plan.headline', defaultValues: { fontSize: 24, fontWeight: 800 } },
    { id: TypographyType.body, intlId: 'organization-plan.body', defaultValues: { fontSize: 14, fontWeight: 400 } },
    { id: TypographyType.questionLabel, intlId: 'organization-plan.question-label', defaultValues: { fontSize: 14, fontWeight: 600 } },
    { id: TypographyType.answerLabel, intlId: 'organization-plan.answer-label', defaultValues: { fontSize: 14, fontWeight: 400 } },
    { id: TypographyType.numericValues, intlId: 'organization-plan.numeric-values', defaultValues: { fontSize: 14, fontWeight: 600 } }
  ];

  const [fontSettingsState, setFontSettingsState] = React.useState<{ [key: string]: { fontSize: number; fontWeight: number } }>({});

  React.useEffect(() => {
    if (userPreferences) {
      const initialSettings = fontSettings.reduce(
        (acc, fontSetting) => {
          const pref = userPreferences.find((pref) => pref.name === `organizationplan-fontsetting-${fontSetting.id}`);

          acc[fontSetting.id] = {
            fontSize: Number(pref?.value[0]),
            fontWeight: Number(pref?.value[1])
          };

          return acc;
        },
        {} as { [key: string]: { fontSize: number; fontWeight: number } }
      );
      setFontSettingsState(initialSettings);
    } else {
      const defaultSettings = fontSettings.reduce(
        (acc, fontSetting) => {
          acc[fontSetting.id] = fontSetting.defaultValues;
          return acc;
        },
        {} as { [key: string]: { fontSize: number; fontWeight: number } }
      );
      setFontSettingsState(defaultSettings);
    }
  }, [userPreferences]);

  return { fontSettings, fontSettingsState, setFontSettingsState, isLoading, userPreferences };
};

export default useFontSettings;
