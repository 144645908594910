import { Box } from '@mui/material';
import EditableLabel from 'modules/builder/components/EditableLabel';
import { IGuestAndRoomsCustomQuestion } from 'modules/builder/types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PageTitle from 'shared/components/PageTitle';
import { useDispatch, useSelector } from 'store';
import { selectHeaderOptions } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { setAllHeaderOptions, setHeaderOptionByField } from 'store/organisation-plan-template/organisation-plan-template.slice';
import OrganisationPlanTemplateCheckboxSection from './OrganisationPlanTemplateCheckboxSection';
import * as Styles from './OrganisationPlanTemplateOptionsSection.styles';

const OrganisationPlanTemplateOptionsSection = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const headerOptions = useSelector(selectHeaderOptions);
  const [checkBoxStatus, setCheckBoxStatus] = useState<'all' | 'few' | 'none'>('none');

  useEffect(() => {
    dispatch(
      setAllHeaderOptions({
        name: headerOptions?.name || intl.formatMessage({ id: 'events.organisation-plan' }),
        showEventName: headerOptions?.showEventName || false,
        showEventDate: headerOptions?.showEventDate || false,
        showContactPerson: headerOptions?.showContactPerson || false,
        showEventManager: headerOptions?.showEventManager || false,
        showNumberOfGuests: checkBoxStatus !== 'none',
        guestDisplayOptions: headerOptions?.guestDisplayOptions || []
      })
    );
  }, [
    dispatch,
    checkBoxStatus,
    headerOptions?.showEventName,
    headerOptions?.showEventDate,
    headerOptions?.showContactPerson,
    headerOptions?.showEventManager,
    headerOptions?.name,
    intl,
    headerOptions?.guestDisplayOptions
  ]);

  useEffect(() => {
    const checkedOptionsCount = headerOptions?.guestDisplayOptions?.filter((option: IGuestAndRoomsCustomQuestion) => option.isCheck);
    if (!checkedOptionsCount?.length) {
      setCheckBoxStatus('none');
    } else if (checkedOptionsCount?.length === headerOptions?.guestDisplayOptions?.length) {
      setCheckBoxStatus('all');
    } else {
      setCheckBoxStatus('few');
    }
  }, [headerOptions?.guestDisplayOptions, headerOptions?.guestDisplayOptions?.length]);

  return (
    <Box>
      <PageTitle title={headerOptions?.name} />
      <Styles.BoxWrapper>
        <Styles.BoxOptionsContainer>
          <Box width="80%" display="flex" justifyContent="center" alignContent="center">
            <EditableLabel
              value={headerOptions?.name}
              name="organisation-plan-title"
              onChange={(value) => dispatch(setHeaderOptionByField({ field: 'name', value: value }))}
            />
          </Box>
          <OrganisationPlanTemplateCheckboxSection checkBoxStatus={checkBoxStatus} />
        </Styles.BoxOptionsContainer>
      </Styles.BoxWrapper>
    </Box>
  );
};

export default OrganisationPlanTemplateOptionsSection;
