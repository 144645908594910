import { Calendar24Regular, Info16Regular } from '@fluentui/react-icons';
import { FormHelperText, Grid, InputLabel, Stack, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useCurrentDateFormat } from 'shared/hooks/regionalSettings';
import { IOfferDialogForm } from 'shared/types/offer';

interface IOfferModalDateFieldsProps {
  hasEvent: boolean;
  hasRequest: boolean;
  eventDate?: Date;
}

const OfferModalDateFields = ({ hasEvent, hasRequest, eventDate }: IOfferModalDateFieldsProps) => {
  const dateFormat = useCurrentDateFormat();
  const { values, errors, touched, setFieldValue } = useFormikContext<IOfferDialogForm>();

  const handleChangeOfferDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('offerDate', value);
  };

  const handleChangeRequestedDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('requestedEventDate', value);
  };
  const parsedRequestedEventDate = eventDate
    ? eventDate
    : values.requestedEventDate
      ? new Date(values.requestedEventDate.toString())
      : null;
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel>
            <FormattedMessage id="offer.modal-field-offer-date" />
          </InputLabel>
          <DatePicker
            value={values.offerDate ? new Date(values.offerDate.toString()) : null}
            format={dateFormat?.format}
            onChange={handleChangeOfferDate}
            slots={{ openPickerIcon: Calendar24Regular }}
          />
          {Boolean(touched['offerDate'] && errors['offerDate']) && (
            <FormHelperText error={Boolean(touched['offerDate'] && errors['offerDate'])}>{errors['offerDate']}</FormHelperText>
          )}
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" gap="10px">
            <InputLabel htmlFor="Questionnaire">
              <FormattedMessage id={hasEvent ? 'events.event-date' : 'offer.modal-field-requested-event-date'} />
            </InputLabel>
            {hasEvent && (
              <Tooltip
                arrow
                title={<FormattedMessage id={hasRequest ? 'offers.modal-event-request-date-info' : 'offers.modal-event-date-info'} />}
                placement="top"
              >
                <Info16Regular opacity="0.5" />
              </Tooltip>
            )}
          </Stack>
          <DatePicker
            disabled={hasEvent}
            value={parsedRequestedEventDate}
            format={dateFormat?.format}
            onChange={handleChangeRequestedDate}
            slots={{ openPickerIcon: Calendar24Regular }}
          />
          {Boolean(touched['requestedEventDate'] && errors['requestedEventDate']) && (
            <FormHelperText error={Boolean(touched['requestedEventDate'] && errors['requestedEventDate'])}>
              {errors['requestedEventDate']}
            </FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default OfferModalDateFields;
